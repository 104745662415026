import React from "react";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";

import { Button } from "../../components/Button/Button";
import { Container } from "../../components/Container/Container";
import {
  HeadingLgWithSideLines,
  HeadingLg,
} from "../../components/Heading/Heading";

export default () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");
  const locale = urlParams.get("locale");

  return (
    <Container lg style={{ paddingTop: "7rem" }}>
      <HeadingLgWithSideLines>
        <HeadingLg>
          <Translate content="config_completed.TITLE" />
        </HeadingLg>
      </HeadingLgWithSideLines>
      <p
        style={{
          maxWidth: "456px",
          color: "#4e4e4e",
          fontSize: "1.8rem",
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <Translate content="config_completed.DESC" />
      </p>
      <div style={{ textAlign: "center", marginTop: "6rem" }}>
        <Button as="a" href={`/?mid=${mid}&locale=${locale}`}>
          <Translate content="config_completed.BTN" />
        </Button>
      </div>
    </Container>
  );
};
