import React, { Fragment } from "react";
import Translate from "react-translate-component";

import { Container } from "../../../components/Container/Container";
import { SelectCard } from "../../../components/SelectCard/SelectCard";
import {
  HeadingLg,
  HeadingLgWithSideLines,
} from "../../../components/Heading/Heading";
import { Grid, GridItem } from "../../../components/Grid/Grid";
import { FormLabel } from "../../../components/Form/FormLabel";
import SelectContainer from "../../../components/Form/SelectContainer";
import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";

export default ({
  onSelect,
  selectedCard,
  activeSelectDropdown,
  fillDropdownOpen,
  onDropdownValueSelect,
  selectDropdownData,
  positionName,
  fillAssets,
  myRef,
}) => {
  const avaiableStockModelFills = useSelector(
    (state) => state.stockModels.stockModelFills
  );

  return (
    <Container lg style={{ display: "flex" }}>
      <Container md style={{ paddingTop: "10rem" }} ref={myRef}>
        <HeadingLgWithSideLines>
          <HeadingLg>
            <Translate content="filling.TITLE" />
          </HeadingLg>
        </HeadingLgWithSideLines>

        <Grid columns3>
          {avaiableStockModelFills &&
            avaiableStockModelFills.map((stockModelFill) => {
              switch (stockModelFill.type) {
                case "filling.type.classic":
                  return (
                    <GridItem>
                      {selectedCard === "filling.type.classic" ? (
                        <Fragment>
                          <SelectCard
                            lg
                            active
                            onSelect={onSelect}
                            type="filling.type.classic"
                            image="images/roof-fills/roof-fill--transparent-polycarbonate.jpg"
                            // desc="10 mm čirý polykarbonát"
                            lightboxId="filling.type.classic"
                            lightboxImages={fillAssets.classic.galleryImgs}
                          >
                            <Translate content="filling.POLYCARBONATE" />
                          </SelectCard>
                        </Fragment>
                      ) : (
                        <SelectCard
                          lg
                          onSelect={onSelect}
                          type="filling.type.classic"
                          image="images/roof-fills/roof-fill--transparent-polycarbonate.jpg"
                          // desc="10 mm čirý polykarbonát"
                          lightboxId="filling.type.classic"
                          lightboxImages={fillAssets.classic.galleryImgs}
                        >
                          <Translate content="filling.POLYCARBONATE" />
                        </SelectCard>
                      )}
                    </GridItem>
                  );
                case "filling.type.smoke_polycarbonate":
                  return (
                    <GridItem>
                      {selectedCard === "filling.type.smoke_polycarbonate" ? (
                        <Fragment>
                          <SelectCard
                            lg
                            active
                            onSelect={onSelect}
                            type="filling.type.smoke_polycarbonate"
                            image="images/roof-fills/roof-fill--smoked-polycarbonate.jpg"
                            // desc="Zatmavená dutinka"
                            lightboxId="filling.type.smoke_polycarbonate"
                            lightboxImages={
                              fillAssets.smokePolycarbonate.galleryImgs
                            }
                          >
                            <Translate content="filling.SMOKED_POLYCARBONATE" />
                          </SelectCard>
                        </Fragment>
                      ) : (
                        <SelectCard
                          lg
                          onSelect={onSelect}
                          type="filling.type.smoke_polycarbonate"
                          image="images/roof-fills/roof-fill--smoked-polycarbonate.jpg"
                          // desc="Zatmavená dutinka"
                          lightboxId="filling.type.smoke_polycarbonate"
                          lightboxImages={
                            fillAssets.smokePolycarbonate.galleryImgs
                          }
                        >
                          <Translate content="filling.SMOKED_POLYCARBONATE" />
                        </SelectCard>
                      )}
                    </GridItem>
                  );
                case "filling.type.compact_polycarbonate":
                  return (
                    <GridItem>
                      {selectedCard === "filling.type.compact_polycarbonate" ? (
                        <SelectCard
                          lg
                          active
                          onSelect={onSelect}
                          type="filling.type.compact_polycarbonate"
                          image="images/roof-fills/roof-fill--clear-boards.jpg"
                          // desc="4 mm"
                          lightboxId="filling.type.compact_polycarbonate"
                          lightboxImages={
                            fillAssets.compactPolycarbonate.galleryImgs
                          }
                        >
                          <Translate content="filling.CLEAR_POLYCARBONATE" />
                        </SelectCard>
                      ) : (
                        <SelectCard
                          lg
                          onSelect={onSelect}
                          type="filling.type.compact_polycarbonate"
                          image="images/roof-fills/roof-fill--clear-boards.jpg"
                          // desc="4 mm"
                          lightboxId="filling.type.compact_polycarbonate"
                          lightboxImages={
                            fillAssets.compactPolycarbonate.galleryImgs
                          }
                        >
                          <Translate content="filling.CLEAR_POLYCARBONATE" />
                        </SelectCard>
                      )}
                    </GridItem>
                  );
              }
            })}
        </Grid>
      </Container>
    </Container>
  );
};
