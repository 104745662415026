import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Translate from "react-translate-component";

import { Container } from "../../../components/Container/Container";
import { HeadingMd, HeadingSm } from "../../../components/Heading/Heading";
import { SelectCard } from "../../../components/SelectCard/SelectCard";
import { InputText } from "../../../components/Form/InputText";
import { Radio } from "../../../components/Form/Radio";
import { Warning } from "../../../components/Warning/Warning";
import { Grid, GridItem } from "../../../components/Grid/Grid";
import { SelectSegments } from "../../../components/SelectSegments/SelectSegments";

const Doors = ({
  onSelectBigDoors,
  selectedCardBigDoors,
  frontBigTiltSize,
  setHeightBigDoors,
  radioChangeHandlerBigDoors,
  radioValueBigDoors,

  onSelectLowDoors,
  selectedCardLowDoors,
  frontSmallTiltSize,
  setHeightLowDoors,
  radioChangeHandlerLowDoors,
  radioValueLowDoors,

  onSelectSideDoors,
  selectedCardSideDoors,
  segmentData,
  segmentType2Data,
  segmentRadioChangeHandler,
  segmentRadioValue,
  segmentRadioReset,

  doorsAssets,

  isVega,

  state,
}) => {
  const avaiableDoors = useSelector((state) => state.doors.avaiableDoors);
  const maxOutsideHheight = useSelector(
    (state) => state.calculationResults.results.roofing.max_outside_height
  );
  const minInsideHheight = useSelector(
    (state) => state.calculationResults.results.roofing.min_inside_height
  );

  const segmentsNumber = useSelector(
    (state) => state.calculationResults.results.roofing.segment_quantity
  );

  const minHeightBigDoors = 250;
  const maxHeightBigDoors = Math.round(maxOutsideHheight / 3);
  const minHeightLowDoors = 250;
  const maxHeightLowDoors = Math.round(minInsideHheight / 3);

  let minFlapHeight;

  const isRoofingOfType = (type) => {
    return state?.roofing?.type?.includes(type);
  };

  // I am not sure if there's even roofing that uses the 25 cm variant
  if (
    isRoofingOfType("trend") ||
    isRoofingOfType("compact") ||
    isRoofingOfType("imperial") ||
    isRoofingOfType("apollo")
  ) {
    minFlapHeight = "30";
  } else {
    minFlapHeight = "25";
  }

  return (
    <Container lg>
      {avaiableDoors.map((door) => {
        return door.type.id === "doors.type.side" ? (
          <Fragment>
            <HeadingSm
              style={{
                marginTop: "12rem",
                marginBottom: "3.7rem",
                textAlign: "center",
              }}
            >
              <Translate content="doors.side_doors.TITLE" />
            </HeadingSm>
            <Grid columns3>
              {door.variants.map((variant) => {
                return variant.id === "doors.variant.none" ? (
                  <GridItem>
                    {selectedCardSideDoors === "doors.variant.none" ? (
                      <SelectCard
                        lg
                        image="images/doors/without-doors.jpg"
                        onSelect={onSelectSideDoors}
                        type="doors.variant.none"
                        lightboxId="sideDoors.variant.none"
                        lightboxImages={doorsAssets.withoutDoors.galleryImgs}
                        active
                      >
                        <Translate content="doors.side_doors.NO_DOORS" />
                      </SelectCard>
                    ) : (
                      <SelectCard
                        lg
                        image="images/doors/without-doors.jpg"
                        onSelect={onSelectSideDoors}
                        type="doors.variant.none"
                        lightboxId="sideDoors.variant.none"
                        lightboxImages={doorsAssets.withoutDoors.galleryImgs}
                      >
                        <Translate content="doors.side_doors.NO_DOORS" />
                      </SelectCard>
                    )}
                  </GridItem>
                ) : variant.id === "doors.variant.side_shunting" ? (
                  <GridItem>
                    {selectedCardSideDoors === "doors.variant.side_shunting" ? (
                      <SelectCard
                        lg
                        image="images/doors/doors.variant.side_shunting.jpg"
                        onSelect={onSelectSideDoors}
                        type="doors.variant.side_shunting"
                        active
                        lightboxId="doors.variant.side_shunting"
                        lightboxImages={doorsAssets.sideShunting.galleryImgs}
                      >
                        <Translate content="doors.side_doors.SHUNTING_DOOR" />
                      </SelectCard>
                    ) : (
                      <SelectCard
                        lg
                        image="images/doors/doors.variant.side_shunting.jpg"
                        onSelect={onSelectSideDoors}
                        type="doors.variant.side_shunting"
                        lightboxId="doors.variant.side_shunting"
                        lightboxImages={doorsAssets.sideShunting.galleryImgs}
                      >
                        <Translate content="doors.side_doors.SHUNTING_DOOR" />
                      </SelectCard>
                    )}
                  </GridItem>
                ) : variant.id === "doors.variant.up_sliding" ? (
                  <GridItem>
                    {selectedCardSideDoors === "doors.variant.up_sliding" ? (
                      <SelectCard
                        lg
                        image="images/doors/doors.variant.up_sliding.jpg"
                        onSelect={onSelectSideDoors}
                        type="doors.variant.up_sliding"
                        lightboxId="doors.variant.up_sliding"
                        lightboxImages={doorsAssets.upSliding.galleryImgs}
                        active
                      >
                        <Translate content="doors.side_doors.SLIDING_DOOR" />
                      </SelectCard>
                    ) : (
                      <SelectCard
                        lg
                        image="images/doors/doors.variant.up_sliding.jpg"
                        onSelect={onSelectSideDoors}
                        type="doors.variant.up_sliding"
                        lightboxId="doors.variant.up_sliding"
                        lightboxImages={doorsAssets.upSliding.galleryImgs}
                      >
                        <Translate content="doors.side_doors.SLIDING_DOOR" />
                      </SelectCard>
                    )}
                  </GridItem>
                ) : (
                  "dvere nejsou dostupne"
                );
              })}
            </Grid>

            {selectedCardSideDoors === "doors.variant.side_shunting" && (
              <Fragment>
                <HeadingMd style={{ textAlign: "center" }}>
                  <Translate content="doors.side_doors.SHUNTING_DOOR_LABEL" />
                </HeadingMd>

                <SelectSegments
                  sideShunting
                  segmentsNumber={segmentsNumber}
                  segmentsData={segmentType2Data}
                  radioChangeHandler={segmentRadioChangeHandler}
                  radioValue={segmentRadioValue}
                  segmentRadioReset={segmentRadioReset}
                />
                <Grid columns3>
                  <GridItem />
                  <GridItem>
                    <Warning>
                      <Translate content="doors.side_doors.SHUNTING_DESC" />
                    </Warning>
                  </GridItem>
                  <GridItem />
                </Grid>
              </Fragment>
            )}

            {selectedCardSideDoors === "doors.variant.up_sliding" && (
              <Fragment>
                <HeadingMd style={{ textAlign: "center" }}>
                  <Translate content="doors.side_doors.SHUNTING_DOOR_LABEL" />
                </HeadingMd>

                <SelectSegments
                  segmentsNumber={segmentsNumber}
                  segmentsData={segmentData}
                  radioChangeHandler={segmentRadioChangeHandler}
                  radioValue={segmentRadioValue}
                  segmentRadioReset={segmentRadioReset}
                />
                <Grid columns3>
                  <GridItem />
                  {/* <GridItem>
                    <Warning>
                      Dvere mohou byt umisteny jen do nejvetsiho segmentu.
                      Vyberte stranu a pozici kde budou misteny.
                    </Warning>
                  </GridItem> */}
                  <GridItem />
                </Grid>
              </Fragment>
            )}
          </Fragment>
        ) : door.type.id === "doors.type.front_big" ? (
          <div>
            <HeadingSm
              style={{
                marginTop: "2rem",
                marginBottom: "3.7rem",
                textAlign: "center",
              }}
            >
              <Translate content="doors.big_brow.TITLE" />
            </HeadingSm>
            <div style={{ marginBottom: "5rem", textAlign: "center" }}>
              <img src={`images/doors/${door.type.id}.png`} alt="" />
            </div>

            <Grid columns3>
              {door.variants.map((variant) => {
                return variant.id === "doors.variant.none" ? (
                  <GridItem>
                    {selectedCardBigDoors === "doors.variant.none" ? (
                      <SelectCard
                        lg
                        image="images/doors/without-doors.jpg"
                        onSelect={onSelectBigDoors}
                        type="doors.variant.none"
                        lightboxId="bigDoors.variant.none"
                        lightboxImages={doorsAssets.withoutDoors.galleryImgs}
                        active
                      >
                        <Translate content="doors.big_brow.NO_DOORS" />
                      </SelectCard>
                    ) : (
                      <SelectCard
                        lg
                        image="images/doors/without-doors.jpg"
                        onSelect={onSelectBigDoors}
                        type="doors.variant.none"
                        lightboxId="bigDoors.variant.none"
                        lightboxImages={doorsAssets.withoutDoors.galleryImgs}
                      >
                        <Translate content="doors.big_brow.NO_DOORS" />
                      </SelectCard>
                    )}
                  </GridItem>
                ) : variant.id === "doors.variant.tilting_front" ? (
                  <GridItem>
                    {selectedCardBigDoors === "doors.variant.tilting_front" ? (
                      <Fragment>
                        <SelectCard
                          lg
                          image="images/doors/flap.jpg"
                          onSelect={onSelectBigDoors}
                          type="doors.variant.tilting_front"
                          lightboxId="doors.variant.tilting_front"
                          lightboxImages={doorsAssets.tiltingFront.galleryImgs}
                          active
                        >
                          <Translate content="doors.big_brow.FLAP" />
                        </SelectCard>
                        {isVega ? null : (
                          <>
                            <HeadingMd>
                              <Translate content="doors.big_brow.FLAP_LABEL" />
                            </HeadingMd>

                            <InputText
                              type="number"
                              value={
                                frontBigTiltSize ? frontBigTiltSize / 10 : ""
                              }
                              min={minHeightBigDoors}
                              max={maxHeightBigDoors}
                              invalid={
                                (frontBigTiltSize &&
                                  frontBigTiltSize < minHeightBigDoors) ||
                                (frontBigTiltSize &&
                                  frontBigTiltSize > maxHeightBigDoors) ||
                                frontBigTiltSize === 0
                              }
                              onChange={(e) =>
                                setHeightBigDoors(e.target.value)
                              }
                            />
                            <div style={{ marginTop: "1rem" }}>
                              <Warning>
                                {/* <Translate content="doors.big_brow.FLAP_DESC" />  */}
                                Min {minFlapHeight} cm - max{" "}
                                {maxHeightBigDoors / 10} cm
                              </Warning>
                            </div>
                          </>
                        )}
                      </Fragment>
                    ) : (
                      <SelectCard
                        lg
                        image="images/doors/flap.jpg"
                        onSelect={onSelectBigDoors}
                        type="doors.variant.tilting_front"
                        lightboxId="doors.variant.tilting_front"
                        lightboxImages={doorsAssets.tiltingFront.galleryImgs}
                      >
                        <Translate content="doors.big_brow.FLAP" />
                      </SelectCard>
                    )}
                  </GridItem>
                ) : variant.id === "doors.variant.with_threshold" ? (
                  <GridItem>
                    {selectedCardBigDoors ===
                      "doors.variant.without_threshold" ||
                    selectedCardBigDoors === "doors.variant.with_threshold" ? (
                      <Fragment>
                        <SelectCard
                          lg
                          image={
                            radioValueBigDoors ===
                            "doors.variant.with_threshold"
                              ? "images/doors/with-threshold.jpg"
                              : "images/doors/without-threshold.jpg"
                          }
                          onSelect={onSelectBigDoors}
                          type="doors.variant.without_threshold"
                          lightboxId={
                            radioValueBigDoors ===
                            "doors.variant.with_threshold"
                              ? "big_doors.variant.with_threshold"
                              : "big_doors.variant.without_threshold"
                          }
                          lightboxImages={
                            radioValueBigDoors ===
                            "doors.variant.with_threshold"
                              ? doorsAssets.withThreshold.galleryImgs
                              : doorsAssets.withoutThreshold.galleryImgs
                          }
                          active
                        >
                          <Translate content="doors.big_brow.DOORS" />
                        </SelectCard>
                        <Radio
                          changed={radioChangeHandlerBigDoors}
                          id="1"
                          isSelected={
                            radioValueBigDoors ===
                            "doors.variant.without_threshold"
                          }
                          value="doors.variant.without_threshold"
                        >
                          <Translate content="doors.big_brow.WITHOUT_THRESHOLD" />
                        </Radio>
                        <div style={{ marginTop: "1rem" }}>
                          <Radio
                            changed={radioChangeHandlerBigDoors}
                            id="2"
                            isSelected={
                              radioValueBigDoors ===
                              "doors.variant.with_threshold"
                            }
                            value="doors.variant.with_threshold"
                          >
                            <Translate content="doors.big_brow.WITH_THRESHOLD" />
                          </Radio>
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                          <Warning>
                            <Translate content="doors.big_brow.THRESHOLD_DESC" />
                          </Warning>
                        </div>
                      </Fragment>
                    ) : (
                      <SelectCard
                        lg
                        image={
                          radioValueBigDoors === "doors.variant.with_threshold"
                            ? "images/doors/with-threshold.jpg"
                            : "images/doors/without-threshold.jpg"
                        }
                        onSelect={onSelectBigDoors}
                        type="doors.variant.without_threshold"
                        lightboxId={
                          radioValueBigDoors === "doors.variant.with_threshold"
                            ? "big_doors.variant.with_threshold"
                            : "big_doors.variant.without_threshold"
                        }
                        lightboxImages={
                          radioValueBigDoors === "doors.variant.with_threshold"
                            ? doorsAssets.withThreshold.galleryImgs
                            : doorsAssets.withoutThreshold.galleryImgs
                        }
                      >
                        <Translate content="doors.big_brow.DOORS" />
                      </SelectCard>
                    )}
                  </GridItem>
                ) : (
                  ""
                );
              })}
            </Grid>
          </div>
        ) : door.type.id === "doors.type.front_small" ? (
          <div>
            <HeadingSm
              style={{
                marginTop: "12rem",
                marginBottom: "3.7rem",
                textAlign: "center",
              }}
            >
              <Translate content="doors.small_brow.TITLE" />
            </HeadingSm>
            <div style={{ marginBottom: "5rem", textAlign: "center" }}>
              <img src={`images/doors/${door.type.id}.png`} alt="" />
            </div>

            <Grid columns3>
              {door.variants.map((variant) => {
                return variant.id === "doors.variant.none" ? (
                  <GridItem>
                    {selectedCardLowDoors === "doors.variant.none" ? (
                      <SelectCard
                        lg
                        image="images/doors/without-doors.jpg"
                        onSelect={onSelectLowDoors}
                        type="doors.variant.none"
                        lightboxId="smallDoors.variant.none"
                        lightboxImages={doorsAssets.withoutDoors.galleryImgs}
                        active
                      >
                        <Translate content="doors.small_brow.NO_DOORS" />
                      </SelectCard>
                    ) : (
                      <SelectCard
                        lg
                        image="images/doors/without-doors.jpg"
                        onSelect={onSelectLowDoors}
                        type="doors.variant.none"
                        lightboxId="smallDoors.variant.none"
                        lightboxImages={doorsAssets.withoutDoors.galleryImgs}
                      >
                        <Translate content="doors.small_brow.NO_DOORS" />
                      </SelectCard>
                    )}
                  </GridItem>
                ) : variant.id === "doors.variant.tilting_front" ? (
                  <GridItem>
                    {selectedCardLowDoors === "doors.variant.tilting_front" ? (
                      <Fragment>
                        <SelectCard
                          lg
                          image="images/doors/flap.jpg"
                          onSelect={onSelectLowDoors}
                          type="doors.variant.tilting_front"
                          lightboxId="doors.variant.tilting_frontLow"
                          lightboxImages={doorsAssets.tiltingFront.galleryImgs}
                          active
                        >
                          <Translate content="doors.small_brow.FLAP" />
                        </SelectCard>
                        <HeadingMd>
                          <Translate content="doors.big_brow.FLAP_LABEL" />
                        </HeadingMd>
                        <InputText
                          type="number"
                          value={
                            frontSmallTiltSize ? frontSmallTiltSize / 10 : ""
                          }
                          min={minHeightLowDoors}
                          max={maxHeightLowDoors}
                          invalid={
                            (frontSmallTiltSize &&
                              frontSmallTiltSize < minHeightLowDoors) ||
                            (frontSmallTiltSize &&
                              frontSmallTiltSize > maxHeightLowDoors) ||
                            frontSmallTiltSize === 0
                          }
                          onChange={(e) => setHeightLowDoors(e.target.value)}
                        />
                        <div style={{ marginTop: "1rem" }}>
                          <Warning>
                            {/* <Translate content="doors.big_brow.FLAP_DESC" /> */}
                            Min 25 cm - max {maxHeightLowDoors / 10} cm
                          </Warning>
                        </div>
                      </Fragment>
                    ) : (
                      <SelectCard
                        lg
                        image="images/doors/flap.jpg"
                        onSelect={onSelectLowDoors}
                        type="doors.variant.tilting_front"
                        lightboxId="doors.variant.tilting_frontLow"
                        lightboxImages={doorsAssets.tiltingFront.galleryImgs}
                      >
                        <Translate content="doors.small_brow.FLAP" />
                      </SelectCard>
                    )}

                    <Warning>
                      <Translate content="doors.small_brow.FLAP_DESC" />
                    </Warning>
                  </GridItem>
                ) : variant.id === "doors.variant.with_threshold" ? (
                  <GridItem>
                    {selectedCardLowDoors ===
                      "doors.variant.without_threshold" ||
                    selectedCardLowDoors === "doors.variant.with_threshold" ? (
                      <Fragment>
                        <SelectCard
                          lg
                          image={
                            radioValueLowDoors ===
                            "doors.variant.with_threshold"
                              ? "images/doors/with-threshold.jpg"
                              : "images/doors/without-threshold.jpg"
                          }
                          onSelect={onSelectLowDoors}
                          type="doors.variant.without_threshold"
                          lightboxId={
                            radioValueLowDoors ===
                            "doors.variant.with_threshold"
                              ? "small_doors.variant.with_threshold"
                              : "small_doors.variant.without_threshold"
                          }
                          lightboxImages={
                            radioValueLowDoors ===
                            "doors.variant.with_threshold"
                              ? doorsAssets.withThreshold.galleryImgs
                              : doorsAssets.withoutThreshold.galleryImgs
                          }
                          active
                        >
                          <Translate content="doors.small_brow.DOORS" />
                        </SelectCard>
                        <Radio
                          changed={radioChangeHandlerLowDoors}
                          id="3"
                          isSelected={
                            radioValueLowDoors ===
                            "doors.variant.without_threshold"
                          }
                          value="doors.variant.without_threshold"
                        >
                          <Translate content="doors.small_brow.WITHOUT_THRESHOLD" />
                        </Radio>
                        <div style={{ marginTop: "1rem" }}>
                          <Radio
                            changed={radioChangeHandlerLowDoors}
                            id="4"
                            isSelected={
                              radioValueLowDoors ===
                              "doors.variant.with_threshold"
                            }
                            value="doors.variant.with_threshold"
                          >
                            <Translate content="doors.small_brow.WITH_THRESHOLD" />
                          </Radio>
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                          <Warning>
                            <Translate content="doors.small_brow.THRESHOLD_DESC" />
                          </Warning>
                        </div>
                      </Fragment>
                    ) : (
                      <SelectCard
                        lg
                        image={
                          radioValueLowDoors === "doors.variant.with_threshold"
                            ? "images/doors/with-threshold.jpg"
                            : "images/doors/without-threshold.jpg"
                        }
                        onSelect={onSelectLowDoors}
                        type="doors.variant.without_threshold"
                        lightboxId={
                          radioValueLowDoors === "doors.variant.with_threshold"
                            ? "small_doors.variant.with_threshold"
                            : "small_doors.variant.without_threshold"
                        }
                        lightboxImages={
                          radioValueLowDoors === "doors.variant.with_threshold"
                            ? doorsAssets.withThreshold.galleryImgs
                            : doorsAssets.withoutThreshold.galleryImgs
                        }
                      >
                        <Translate content="doors.small_brow.DOORS" />
                      </SelectCard>
                    )}
                  </GridItem>
                ) : (
                  ""
                );
              })}
            </Grid>
          </div>
        ) : (
          "Dveře nejsou dostupné"
        );
      })}
    </Container>
  );
};

export default Doors;
