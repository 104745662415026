import styled from "styled-components";

export const Warning = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.7;
  color: #666;

  &:before {
    position: relative;
    display: block;
    flex: none;
    align-self: flex-start;
    width: 1.3rem;
    height: 1.3rem;
    margin-top: 0.6rem;
    margin-right: 0.8rem;
    content: "";
    background-color: #f5a623;
    background-image: url("images/icons/exclamation-mark.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-color: #f5a623;
    border-radius: 50%;
  }
`;
