import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { DimensionsActions } from "../../../actions/dimensionsActions";
import { RoofingActions } from "../../../actions/roofingActions";
import { StockModelsActions } from "../../../actions/stockModelsActions";
import { DimentionsSelectors } from "../../../selectors/dimentionsSelectors";
import Dimensions from "./Dimensions";

class DimensionsContainer extends Component {
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return <Dimensions {...this.props} />;
  }
}

const mapState = (state) => ({
  width: DimentionsSelectors.getWidth(state),
  length: DimentionsSelectors.getLength(state),
  dimensionsValidity: DimentionsSelectors.getDimensionsValidity(state),
  roofingVisibility: state.roofing.visibility,
});

const mapDispatch = (dispatch) => ({
  setWidth: (width) => dispatch(DimensionsActions.setWidth(width)),
  setLength: (length) => dispatch(DimensionsActions.setLength(length)),
  showTypes: (visibility) => dispatch(RoofingActions.showType(visibility)),
  storeRoofings: (roofings) => dispatch(RoofingActions.storeRoofings(roofings)),
});

export default withRouter(connect(mapState, mapDispatch)(DimensionsContainer));
