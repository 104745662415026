import React from "react";
import styled from "styled-components";
import { InputText } from "./InputText";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${InputText} {
    min-width: 0;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const Prefix = styled.div`
  height: 4rem;
  padding: 1.1rem 1.6rem;
  font-size: 1.4rem;
  color: #4e4e4e;
  white-space: nowrap;
  border: 1px solid #ababab;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

export function InputTextWithPrefix(props) {
  return (
    <Wrapper>
      <Prefix>{props.prefix}</Prefix>
      <InputText
        onChange={(e) => {
          props.setExtension(e.target.value);
        }}
      />
    </Wrapper>
  );
}
