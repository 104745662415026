import counterpart from "counterpart";
import {
  STORE__STOCK_MODEL,
  STORE__STOCK_MODEL_DOORS,
  SELECT__STOCK_MODEL_DOORS,
  SET_STOCK_DOOR_SEGMENTS,
  SET_VISIBLE_STOCK_MODEL_FILL,
  STORE__STOCK_MODEL_FILLS,
  SELECT_STOCK_MODEL_FILL,
  SELECT_STOCK_MODEL_FILL_DROPDOWN_VALUE,
  STORE_STOCK_MODEL_FILL_POSITION_NAME,
  SET_VISIBLE_STOCK_MODEL_SURFACE,
  STORE__STOCK_MODEL_SURFACES,
  SET_VISIBLE_STOCK_MODEL_TRACK,
  SELECT__STOCK_MODEL_SURFACE,
  SET__STOCK_MODEL_TRACK_EXTENSION,
  SELECT_STOCK_MODEL_TRACK_HAS_COVERS,
  SELECT_STOCK_MODEL_TRACK_HAS_LOCKS,
  STORE_STOCK_MODEL_TRACK_LOCKS_NAME,
  SET_VISIBLE_STOCK_MODEL_SUMMARIZATION,
  SET_VISIBLE_STOCK_MODEL_FORM,
  STORE_STOCK_MODEL_RESULTS,
} from "./types";

export const StockModelsActions = {
  storeStockModel: (stockModel) => ({
    type: STORE__STOCK_MODEL,
    stockModel,
  }),
  storeStockModelDoors: (stockModelDoors) => ({
    type: STORE__STOCK_MODEL_DOORS,
    stockModelDoors,
  }),
  storeStockModelFills: (stockModelFills) => ({
    type: STORE__STOCK_MODEL_FILLS,
    stockModelFills,
  }),
  storeStockModelSurfaces: (stockModelSurfaces) => ({
    type: STORE__STOCK_MODEL_SURFACES,
    stockModelSurfaces,
  }),
  selectStockDoors: (stockModelDoorsType) => ({
    type: SELECT__STOCK_MODEL_DOORS,
    stockModelDoorsType,
  }),
  setStockModelFillVisible: () => ({
    type: SET_VISIBLE_STOCK_MODEL_FILL,
  }),
  setStockModelSurfaceVisible: () => ({
    type: SET_VISIBLE_STOCK_MODEL_SURFACE,
  }),
  setStockModelTrackVisible: () => ({
    type: SET_VISIBLE_STOCK_MODEL_TRACK,
  }),
  setStockModelSummarizationVisible: () => ({
    type: SET_VISIBLE_STOCK_MODEL_SUMMARIZATION,
  }),
  setStockModelFormVisible: () => ({
    type: SET_VISIBLE_STOCK_MODEL_FORM,
  }),
  selectStockModelFill: (stockModelFillType) => ({
    type: SELECT_STOCK_MODEL_FILL,
    stockModelFillType,
  }),
  selectDropdownValue: (selectValue) => ({
    type: SELECT_STOCK_MODEL_FILL_DROPDOWN_VALUE,
    selectValue,
  }),
  storeDropdownValueName: (selectValueName) => ({
    type: STORE_STOCK_MODEL_FILL_POSITION_NAME,
    selectValueName,
  }),
  selectStockModelSurface: (stockModelSurfaceType) => ({
    type: SELECT__STOCK_MODEL_SURFACE,
    stockModelSurfaceType,
  }),
  setSegments: (segments) => ({ type: SET_STOCK_DOOR_SEGMENTS, segments }),
  setExtension: (extension) => ({
    type: SET__STOCK_MODEL_TRACK_EXTENSION,
    extension: extension * 10,
  }),
  selectCoverValue: (selectValueName) => ({
    type: SELECT_STOCK_MODEL_TRACK_HAS_COVERS,
    selectValueName:
      selectValueName === counterpart("track.HAS_COVERS") ? true : false,
  }),
  selectLocksValue: (selectValueName) => ({
    type: SELECT_STOCK_MODEL_TRACK_HAS_LOCKS,
    selectValueName,
  }),
  storeLocksValueName: (selectValueName) => ({
    type: STORE_STOCK_MODEL_TRACK_LOCKS_NAME,
    selectValueName,
  }),
  storeStockModelResults: (storeResults) => ({
    type: STORE_STOCK_MODEL_RESULTS,
    storeResults,
  }),
};
