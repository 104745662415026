import React from "react";
import { connect } from "react-redux";

import { SurfaceActions } from "../../../actions/surfaceActions";
import { CalculationResultsActions } from "../../../actions/calculationResultsAction";
import SurfaceStockModel from "./SurfaceStockModel";
import { StockModelsActions } from "../../../actions/stockModelsActions";

const SurfaceStockModelContainer = ({
  selectStockModelSurface,
  setStockModelTrackVisible,
  myRef,
  executeScroll,
  stockModelSurfaceType,
}) => {
  const surfaceAssets = {
    elox: {
      galleryImgs: [{ src: "images/gallery/surface/elox.jpg" }],
    },

    anthracite: {
      galleryImgs: [{ src: "images/gallery/surface/anthracite.jpg" }],
    },

    bronze: {
      galleryImgs: [{ src: "images/gallery/surface/bronze.jpg" }],
    },
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");

  const onSelect = async (e) => {
    selectStockModelSurface(e.currentTarget.dataset.cardName);
    setStockModelTrackVisible();

    executeScroll();
  };

  return (
    <SurfaceStockModel
      onSelect={onSelect}
      selectedCardName={stockModelSurfaceType}
      surfaceAssets={surfaceAssets}
      myRef={myRef}
    />
  );
};

const mapState = (state) => ({
  stockModelSurfaceType: state.stockModels.stockModelSurfaceType,
  state: state,
});

const mapDispatch = (dispatch) => ({
  selectStockModelSurface: (selectCardName) =>
    dispatch(StockModelsActions.selectStockModelSurface(selectCardName)),
  setStockModelTrackVisible: () =>
    dispatch(StockModelsActions.setStockModelTrackVisible()),
});

export default connect(mapState, mapDispatch)(SurfaceStockModelContainer);
