export default {
  dimensions: "Rozměry",
  type: "Typ",
  filling: "Výplň",
  surface: "Barva",
  track: "Kolejiště",
  doors: "Specifikace prvků (upravit na specifikace dveří?)",
  summarization: "Sumarizace",
  form: "Odeslání poptávky",
  login: "Přihlásit se",
  mid_alert: "Konfigurátor nelze spustit, chybí nastavení partnera",
  config_completed: {
    TITLE: "Děkujeme!",
    DESC: "Odeslání proběhlo úspěšně. Na váš email Vám zasíláme sumarizaci objednávky s dalšími informacemi. Děkujeme a přejeme hezký den.",
    BTN: "Začít novou konfiguraci",
  },
  dimensions: {
    TITLE: "Rozměry zastřešení",
    DISTANCE:
      "Minimální vzdálenost kolejí od okraje bazénu je 15 cm na každé straně bazénu.",
    EDGE_HEIGHT: "Výška lemu bazénu nad terén je max 10 cm.",
    IMG: "dimensions-img--cs",
    LENGTH: "Délka zastřešení",
    WIDTH: "Šířka zastřešení",
    MAX_WIDTH: "Max. šířka bazénu",
    MAX_LENGTH: "Max. délka bazénu",
    SUBMIT: "Pokračovat",
  },
  type: {
    TITLE: "Vyberte typ zastřešení",
    AVAIABLE_DIMENSIONS: "Nejbližší možný rozměr o něco vyšší než zadaná data",
    LEFT_SIDE: "Varianta vpravo",
    RIGHT_SIDE: "Varianta vlevo",
    TREND: "Trend",
    TREND_INCREASED: "Trend zvýšený",
    TREND_MAXIMUM: "Trend vysoký",
    COMPACT_LOW: "Compact nízký",
    COMPACT_HIGH: "Compact vysoký",
    ASTRA: "Astra",
    IMPERIAL_LOW: "Imperial nízký",
    IMPERIAL_MEDIUM: "Imperial střední",
    IMPERIAL_HIGH: "Imperial vysoký",
    APOLLO_OBLIQUE: "Apollo Lux",
    APOLLO_STRAIGHT: "Apollo ostré",
    VEGA_330: "Vega 330",
    VEGA_380: "Vega 380",
    VEGA_430: "Vega 430",
    VEGA_450: "Vega 450",
  },
  filling: {
    TITLE: "Výplň",
    POLYCARBONATE: "10 mm čirý polykarbonát",
    SMOKED_POLYCARBONATE: "10 mm kouřový polykarbonát",
    CLEAR_POLYCARBONATE: "Kompaktní polykarbonát 4 mm",
    SPECIFY: "Specifikujte",
    SELECT: "Vyberte",
    WHOLE: "Celý povrch",
    BROWS: "Pouze čela",
    SMALL_BROW: "Malé čelo",
    BIG_BROW: "Velké čelo",
    SEGMENTS: "Pouze segmenty",
  },
  surface: {
    TITLE: "Barva",
    ELOX: "Elox",
    ANTHRACITE: "Antracit (RAL 7016)",

    BRONZE: "Bronz",
  },
  track: {
    TITLE: "Kolejiště",
    TYPE: "roofing.track.TYPE",
    TYPE_DOUBLE_SIDED: "Oboustranné",
    TYPE_SINGLE_SIDED: "Jednostranné",
    EXTENSION_LABEL: "Prodloužení kolejí",
    TOTAL_EXTENSTION: "Celkové prodloužení",
    EXTENSION_DESC:
      "Součástí ceny je 210 cm prodloužení kolejiště za bazén. V případě, že požadujete delší prodloužení, zadejte hodnotu.",
    COVERS_LABEL: "Krytky",
    COVERS_IMG: "covers-img--cs",
    WITHOUT_COVERS: "Bez krytky",
    WITH_COVERS: "S krytkou",
    HAS_COVERS: "Ano",
    NO_COVERS: "Ne",
    COVERS_DESC:
      "Krytky slouží pro komfortní přecházení kolejí a zakryjí šrouby.",
    LOCKS_LABEL: "Uzamykání segmentů",
    LOCKS_IMG: "locks-img--cs",
    NO_LOCKS: "Ne",
    LOCKS_LEFT: "Ano, vlevo",
    LOCKS_RIGHT: "Ano, vpravo",
    LOCKS_DESC:
      "Jednotlivé segmenty lze uzamknout klíčem a zamezit tak manipulaci. Vyberte stranu, kde budou zámky umístěny.",
  },
  doors: {
    TITLE: "Typy dveří",
    big_brow: {
      TITLE: "Dveře ve velkém čele",
      NO_DOORS: "Bez dveří",
      FLAP: "Klapka",
      FLAP_LABEL: "Zadejte požadovanou výšku klapky",
      FLAP_DESC: "Min 25 cm - max 1/3 výšky velkého čela",
      DOORS: "Dveře  ",
      WITHOUT_THRESHOLD: "Bez prahu",
      WITH_THRESHOLD: "S prahem",
      THRESHOLD_DESC:
        "Posuvny prah lze vytahnout nahoru a při odsunu zastřešení tak prekonat napriklad žebřík.",
    },
    small_brow: {
      TITLE: "Dveře v malém čele",
      NO_DOORS: "Bez dveří",
      FLAP: "Klapka",
      FLAP_DESC:
        "Klapku lze zvednout nahoru a při odsunu zastřešení tak prekonat překážku (napriklad žebřík).",
      DOORS: "Dveře  ",
      WITHOUT_THRESHOLD: "Bez prahu",
      WITH_THRESHOLD: "S prahem",
      THRESHOLD_DESC:
        "Posuvny prah lze vytahnout nahoru a při odsunu zastřešení tak prekonat napriklad žebřík.",
    },
    side_doors: {
      TITLE: "Boční dveře",
      NO_DOORS: "Bez dveří",
      SHUNTING_DOOR: "Odsuvné dveře ",
      SHUNTING_DOOR_LABEL: "Vyberte stranu a pozici",
      SHUNTING_DESC:
        "Dvere mohou byt umisteny jen do nejvetsiho segmentu. Vyberte stranu a pozici kde budou misteny.",
      SLIDING_DOOR: "Výsuvné dveře",
    },
  },
  CALCULATE: "Kalkulace",
  summarization: {
    TITLE: "Sumarizace",
    DIMENSIONS: "Rozměry a typ",
    INNER_WIDTH: "Vnitřní šířka zastřešení",
    INNER_LENGTH: "Vnitřní délka zastřešení",
    OUTER_LENGTH: "Vnější délka zastřešení",
    OUTER_WIDTH: "Vnější šířka zastřešení",
    SEGMENTS: "Počet segmentů",
    MIN_INNER_HEIGHT: "Minimální vnitřní výška",
    MAX_OUTER_HEIGHT: "Maximální vnější výška",
    FILLING_SURFACE: "Výplň a barva",
    FILLING: "Výplň  ",
    SURFACE: "Barva",
    DOORS_TYPE: "Typy dveří",
    DOORS_BIG: "Dveře ve velkém čele",
    DOORS_BIG_SLIDING_THRESHOLD: "Velké čelo - výsuvný práh",
    DOORS_SMALL: "Dveře v malém čele",
    DOORS_SMALL_SLIDING_THRESHOLD: "Malé čelo - výsuvný práh",
    DOORS_SIDE: "Boční dveře",
    TRACK: "Kolejiště",
    TRACK_TYPE: "Typ kolejiště",
    TRACK_WIDTH: "Šířka kolejiště",
    TRACK_LENGTH: "Délka kolejiště",
    LOCKS: "Zámky segmentů",
    TRACK_EXTENSION: "Prodloužení kolejí",
    TRACK_COVERS: "Krytky",
    PRICE: "Cena",
    CONFIG: "Konfigurace",
    STOCK_MODEL: "Skladový model",
  },
  form: {
    TITLE: "Odeslání poptávky",
    DESC: "Poptávka je nezávazná. Na jejím základě Vás budeme kontaktovat.",
    EMAIL: "Email",
    TEL: "Telefon",
    FIRST_NAME: "Jméno",
    SURNAME: "Příjmení",
    ADDRESS_DESC:
      "Pro nacenění dopravy prosím zadejte svoji přesnou adresu. Pole jsou nepovinná.",
    STREET: "Ulice",
    HOUSE_NUMBER: "ČP",
    CITY: "Město",
    POSTAL_CODE: "PSČ",
    COUNTRY: "Země",
    SELECT_COUNTRY: "Vyberte Zemi",
    TERMS: "Souhlasím s podmínkami",
    SUBMIT: "Odeslat",
    REQUIRED: "Povinné",
    GDPR: "GDPR ochrana osobních údajů",
  },
  stock: {
    STOCK_MODEL: "Skladový model",
    YOUR_CUSTOM_MODEL: "Váš model na míru",
    PRICE_FROM: "Od",
    STOCK_PROMO:
      "Vámi zadaným parametrům odpovídá i tento skladový model. Je za zvýhodnenou cenu a doba dodání je zkrácena o výrobní proces.",
    STOCK_SHOW: "Zobrazit",
    CONFIGURE_MSG:
      "Skladový model obsahuje některé volitelné parametry. Prosím nakonfigurujte.",
    big_brow: {
      WITHOUT_THRESHOLD: "Bez prahu",
      DOORS: "Dveře  ",
    },
    side_doors: {
      SLIDING_DOOR: "Výsuvné dveře",
    },
    FINISH: "Dokončit",
    BACK_TO_CUSTOM_MODEL: "Zpět na model na míru",
    ORDER_STOCK_MODEL: "Objednat skladový model",
  },
};
