import {
  SELECT_FILL_CARD,
  SELECT_FILL_DROPDOWN_VALUE,
  STORE_FILL_POSITION_NAME
} from "./types";

export const FillActions = {
  selectCard: selectName => ({
    type: SELECT_FILL_CARD,
    selectName
  }),
  selectDropdownValue: selectValue => ({
    type: SELECT_FILL_DROPDOWN_VALUE,
    selectValue
  }),
  storeDropdownValueName: selectValueName => ({
    type: STORE_FILL_POSITION_NAME,
    selectValueName
  })
};
