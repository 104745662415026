import { SET_WIDTH, SET_LENGTH } from "../actions/types";

const initialState = {
  width: "",
  length: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WIDTH:
      return {
        ...state,
        width: action.width
      };

    case SET_LENGTH: {
      return {
        ...state,
        length: action.length
      };
    }

    default:
      return state;
  }
};
