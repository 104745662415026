import React, { Fragment } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

import { IconBtn } from "../IconBtn/IconBtn";

function Lightbox({
  toggleLightbox,
  activeLightboxName,
  lightboxId,
  currentLightbox,
  lightboxImages
}) {
  return (
    <Fragment>
      <IconBtn onClick={toggleLightbox} id={lightboxId}>
        <svg>
          <use xlinkHref="#icon-camera"></use>
        </svg>
      </IconBtn>
      <ModalGateway>
        {activeLightboxName === lightboxId ? (
          <Modal onClose={() => currentLightbox("")}>
            <Carousel views={lightboxImages} />
          </Modal>
        ) : null}
      </ModalGateway>
    </Fragment>
  );
}

export default Lightbox;
