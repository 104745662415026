import React from "react";
import styled, { css } from "styled-components";
import Translate from "react-translate-component";

const SelectDropdownBtn = styled.button`
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.4rem;
  color: #4e4e4e;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover {
    color: #fff;
    background-color: #4a90e2;
  }
`;

const SelectDropdownLi = styled.li``;

const SelectDropdownUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const SelectDropdown = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: -1px;
  z-index: 3;
  display: none;
  width: inherit;
  background-color: #fff;
  border: 1px solid #ababab;
  border-top: none;
  border-radius: 6px;
`;

const SelectBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-left: 1px solid #ababab;
  outline: none;

  svg {
    width: 1.7rem;
    height: 0.8rem;
    fill: #ababab;
  }
`;

const SelectValue = styled.div`
  flex: 1;
  padding: 1rem 1.5rem;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 4rem;
  padding: 0.3rem 0;
  font-size: 14px;
  color: #4e4e4e;
  background-color: #fff;
  border: 1px solid #ababab;
  border-radius: 6px;

  ${(props) =>
    props.invalid &&
    css`
      color: #d0021b;
      border-color: #d0021b;

      ${SelectBtn} {
        border-color: #d0021b;

        svg {
          fill: #d0021b;
        }
      }
    `}

  ${(props) =>
    props.open &&
    css`
      ${SelectDropdown} {
        display: block;
      }
    `}

  &:hover {
    border-color: #4a90e2;
  }

  &:focus {
    border: 2px solid #4a90e2;
    outline: none;
  }
`;

export function Select({
  invalid,
  open,
  DropdownValueName,
  openDropdown,
  closeDropdown,
  onDropdownValueSelect,
  selectDropdownData,
}) {
  return (
    <SelectWrapper invalid={invalid} open={open}>
      <SelectValue>
        {DropdownValueName || <Translate content="filling.SELECT" />}
      </SelectValue>

      <SelectBtn
        onClick={openDropdown}
        onBlur={closeDropdown}
        data-id={selectDropdownData.id}
      >
        <svg>
          <use xlinkHref="#icon-arrow-bottom"></use>
        </svg>
      </SelectBtn>

      <SelectDropdown>
        <SelectDropdownUl>
          {selectDropdownData.options.map((option, index) => {
            return (
              <SelectDropdownLi key={index}>
                <SelectDropdownBtn
                  data-option-name={option.name}
                  data-option-id={option.id}
                  onMouseDown={onDropdownValueSelect}
                >
                  {option.name}
                </SelectDropdownBtn>
              </SelectDropdownLi>
            );
          })}
        </SelectDropdownUl>
      </SelectDropdown>
    </SelectWrapper>
  );
}
