import React from "react";
import { connect } from "react-redux";

import { SurfaceActions } from "../../../actions/surfaceActions";
import { CalculationResultsActions } from "../../../actions/calculationResultsAction";
import Surface from "./Surface";

const SurfaceContainer = ({
  selectCard,
  state,
  storeResults,
  myRef,
  executeScroll,
  selected: { type },
}) => {
  const surfaceAssets = {
    elox: {
      galleryImgs: [{ src: "images/gallery/surface/elox.jpg" }],
    },

    anthracite: {
      galleryImgs: [{ src: "images/gallery/surface/anthracite.jpg" }],
    },

    bronze: {
      galleryImgs: [{ src: "images/gallery/surface/bronze.jpg" }],
    },
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");

  const onSelect = async (e) => {
    selectCard(e.currentTarget.dataset.cardName);

    const filterValidValueProps = (object) => {
      let result = {};
      for (let prop in object) {
        // if prop value is not init value, send value to server
        if (object[prop] !== "") {
          result[prop] = object[prop];
        }
      }
      return result;
    };

    const filterValidValuePropsArray = (object) => {
      let result = [];
      let index = 0;
      for (let prop in object) {
        if (object[prop] !== "") {
          // check if object property value is object
          if (typeof object[prop] === "object" && object[prop] !== null) {
            for (let subProp in object[prop]) {
              result[index] = result[index] || {};
              if (isNaN(subProp) && object[prop][subProp]) {
                if (
                  Array.isArray(object[prop][subProp]) &&
                  object[prop][subProp].length > 0
                ) {
                  result[index][subProp] = object[prop][subProp];
                } else if (!Array.isArray(object[prop][subProp])) {
                  result[index][subProp] = object[prop][subProp];
                }
              }
            }
            ++index;
          } else result[prop] = object[prop];
        }
      }
      return result;
    };

    // data to post to server
    const postData = {
      dimension: {
        ...filterValidValueProps(state.dimension),
      },
      roofing: {
        ...filterValidValueProps(state.roofing),
      },
      filling: {
        ...filterValidValueProps(state.filling),
      },
      surface: {
        type: e.currentTarget.dataset.cardName,
      },
    };

    // object is not empty
    function isNotEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return true;
      }
      return false;
    }

    if (
      isNotEmpty(filterValidValueProps(state.track)) &&
      isNotEmpty(filterValidValueProps(state.surface))
    ) {
      postData.track = filterValidValueProps(state.track);
    }

    if (isNotEmpty(filterValidValuePropsArray(state.doors))) {
      const filteredObjects = filterValidValuePropsArray(state.doors);

      postData.doors = filteredObjects.filter((doorObject) => {
        return isNotEmpty(doorObject);
      });
    }

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/configuration/update?mid=${mid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    );
    const data = await res.json();
    storeResults(data);
    executeScroll();
  };

  return (
    <Surface
      onSelect={onSelect}
      selectedCardName={type}
      surfaceAssets={surfaceAssets}
      myRef={myRef}
    />
  );
};

const mapState = (state) => ({
  selected: state.surface,
  state: state,
});

const mapDispatch = (dispatch) => ({
  selectCard: (selectCardName) =>
    dispatch(SurfaceActions.selectSurfaceCard(selectCardName)),
  storeResults: (results) =>
    dispatch(CalculationResultsActions.storeResults(results)),
});

export default connect(mapState, mapDispatch)(SurfaceContainer);
