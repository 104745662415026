import {
  SET_TRACK_EXTENSION,
  SELECT_TRACK_HAS_COVERS,
  SELECT_TRACK_HAS_LOCKS,
  STORE_TRACK_LOCKS_NAME,
} from "../actions/types";

const initialState = {
  extension: 0,
  covers: true,
  locks: false,
  locks_location: "locks.none",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TRACK_EXTENSION:
      return {
        ...state,
        extension: action.extension,
      };
    case SELECT_TRACK_HAS_COVERS:
      return {
        ...state,
        covers: action.selectValueName,
      };
    case SELECT_TRACK_HAS_LOCKS:
      return {
        ...state,
        locks: action.selectValueName === "locks.none" ? false : true,
        locks_location: action.selectValueName,
      };
    case STORE_TRACK_LOCKS_NAME:
      return {
        ...state,
        locksName: action.selectValueName,
      };
    default:
      return state;
  }
};
