import React from "react";
import styled from "styled-components";

const RadioLabel = styled.div`
  margin-left: 1rem;
  font-size: 1.8rem;
  font-weight: 400;

  color: ${(props) => (props.as == "a" ? "#4a90e2" : "#4e4e4e")};
  text-decoration: ${(props) => props.href && "underline"};

  &:empty {
    display: none;
  }
`;

const RadioIndicator = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  background-color: #fff;
  border: 0.1rem solid #c1c1c1;
  border-radius: 50%;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
    height: 1rem;
    content: "";
    background-color: #4a90e2;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
  }
`;

const RadioWrapper = styled.label`
  display: flex;

  input[type="radio"] {
    display: none;

    &:checked ~ ${RadioIndicator} {
      border-color: #4a90e2;

      &:before {
        content: "";
        opacity: 1;
      }
    }
  }
`;

export function Radio(props) {
  return (
    <RadioWrapper htmlFor={props.id}>
      <input
        id={props.id}
        onChange={props.changed}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      ></input>
      <RadioIndicator />
      <RadioLabel as={props.as} href={props.href}>
        {props.children}
      </RadioLabel>
    </RadioWrapper>
  );
}
