import React from "react";
import { connect } from "react-redux";
import counterpart from "counterpart";

import FillStockModel from "./FillStockModel";
import { StockModelsActions } from "../../../actions/stockModelsActions";

const FillContainer = ({
  selectStockModelFill,
  stockModel,
  stockDoorVariant,
  selectDropdownValue,
  storeDropdownValueName,
  setStockModelSurfaceVisible,
  storeStockModelSurfaces,
  positionName,
  myRef,
  executeScroll,
  stockModelFillType,
  activeSelectDropdown,
}) => {
  const fillAssets = {
    classic: {
      galleryImgs: [{ src: "images/gallery/fill/classic.jpg" }],
    },

    smokePolycarbonate: {
      galleryImgs: [{ src: "images/gallery/fill/smoke-polycarbonate.jpg" }],
    },

    compactPolycarbonate: {
      galleryImgs: [{ src: "images/gallery/fill/compact-polycarbonate.jpg" }],
    },
  };

  const selectDropdownData = [
    {
      id: "Klasicka-dutinka",
      options: [
        {
          id: "filling.position.whole",
          name: counterpart("filling.WHOLE"),
        },
      ],
    },
    {
      id: "Kourovy-polykarbonat",
      options: [
        {
          id: "filling.position.whole",
          name: counterpart("filling.WHOLE"),
        },
      ],
    },
    {
      id: "Kompaktni-polykarbonat",
      options: [
        {
          id: "filling.position.whole",
          name: counterpart("filling.WHOLE"),
        },
      ],
    },
  ];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");

  const onSelect = async (e) => {
    const cardValue = e.currentTarget.dataset.cardName;
    selectStockModelFill(cardValue);
    selectDropdownValue("filling.position.whole");
    storeDropdownValueName(counterpart("filling.WHOLE"));

    setStockModelSurfaceVisible();

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/stock-models/${
        stockModel.id
      }/surfaces/?doorVariant=${stockDoorVariant}&locale=en`
    );
    const data = await res.json();
    storeStockModelSurfaces(data);
    executeScroll();
  };

  const onDropdownValueSelect = async (e) => {
    selectDropdownValue(e.currentTarget.dataset.optionId);
    storeDropdownValueName(e.currentTarget.dataset.optionName);
    executeScroll();
  };

  return (
    <FillStockModel
      selectDropdownData={selectDropdownData}
      onSelect={onSelect}
      selectedCard={stockModelFillType}
      activeSelectDropdown={activeSelectDropdown}
      onDropdownValueSelect={onDropdownValueSelect}
      positionName={positionName}
      fillAssets={fillAssets}
      myRef={myRef}
    />
  );
};

const mapState = (state) => ({
  state: state,
  stockModel: state.stockModels.stockModel,
  positionName: state.stockModels.stockModelDropdownPositionName,
  stockDoorVariant: state.stockModels.stockModelDoorsType,
  stockModelFillType: state.stockModels.stockModelFillType,
  activeSelectDropdown: state.select.activeDropdownName,
});

const mapDispatch = (dispatch) => ({
  selectStockModelFill: (selectCardName) =>
    dispatch(StockModelsActions.selectStockModelFill(selectCardName)),
  setStockModelSurfaceVisible: () =>
    dispatch(StockModelsActions.setStockModelSurfaceVisible()),
  selectDropdownValue: (dropdownValue) =>
    dispatch(StockModelsActions.selectDropdownValue(dropdownValue)),
  storeDropdownValueName: (dropdownValueName) =>
    dispatch(StockModelsActions.storeDropdownValueName(dropdownValueName)),
  storeStockModelSurfaces: (stockModelSurfaces) =>
    dispatch(StockModelsActions.storeStockModelSurfaces(stockModelSurfaces)),
});

export default connect(mapState, mapDispatch)(FillContainer);
