import {
  SELECT_LOW_DOOR_CARD,
  SELECT_HIGH_DOOR_CARD,
  SELECT_SIDE_DOOR_CARD,
  STORE_DOORS,
  SET_FRONT_BIG_TILT_SIZE,
  SET_FRONT_SMALL_TILT_SIZE,
  SET_HIGH_DOOR_THRESHOLD,
  SET_DOOR_SEGMENTS
} from "./types";

export const DoorsActions = {
  selectLowDoorCard: cardName => ({
    type: SELECT_LOW_DOOR_CARD,
    cardName
  }),
  storeDoors: avaiableDoors => ({
    type: STORE_DOORS,
    avaiableDoors
  }),
  selectHighDoorCard: cardName => ({
    type: SELECT_HIGH_DOOR_CARD,
    cardName
  }),
  selectSideDoorCard: cardName => ({
    type: SELECT_SIDE_DOOR_CARD,
    cardName
  }),
  setFrontBigTiltSize: height => ({
    type: SET_FRONT_BIG_TILT_SIZE,
    height: height * 10
  }),
  setFrontSmallTiltSize: height => ({
    type: SET_FRONT_SMALL_TILT_SIZE,
    height: height * 10
  }),
  setthreshold: threshold => ({
    type: SET_HIGH_DOOR_THRESHOLD,
    threshold
  }),
  setSegments: segments => ({ type: SET_DOOR_SEGMENTS, segments })
};
