import React from "react";
import { connect } from "react-redux";
import counterpart from "counterpart";

import { TrackActions } from "../../../actions/trackActions";
import { CalculationResultsActions } from "../../../actions/calculationResultsAction";
import Track from "./Track";

const TrackContainer = ({
  selectCoverValue,
  selectLocksValue,
  storeLocksValueName,
  activeSelectDropdown,
  covers,
  locks,
  locksName,
  state,
  storeResults,
  setExtension,
  extensionValue,
  myRef,
}) => {
  const selectDropdownData = [
    {
      id: "krytky",
      options: [
        {
          name: counterpart("track.HAS_COVERS"),
        },
        {
          name: counterpart("track.NO_COVERS"),
        },
      ],
    },

    {
      id: "uzamykani-segmentu",
      options: [
        {
          id: "locks.none",
          name: counterpart("track.NO_LOCKS"),
        },
        {
          id: "locks.location.left",
          name: counterpart("track.LOCKS_LEFT"),
        },
        {
          id: "locks.location.right",
          name: counterpart("track.LOCKS_RIGHT"),
        },
      ],
    },
  ];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");

  const coverValueSelect = async (e) => {
    selectCoverValue(e.currentTarget.dataset.optionName);

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/configuration/update?mid=${mid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dimension: { ...state.dimension },
          roofing: { ...state.roofing },
          filling: { ...state.filling },
          surface: { ...state.surface },
          track: {
            ...state.track,
            covers: e.currentTarget.dataset.optionName === "ano" ? true : false,
          },
        }),
      }
    );
    const data = await res.json();
    storeResults(data);
  };

  const locksValueSelect = async (e) => {
    selectLocksValue(e.currentTarget.dataset.optionId);
    storeLocksValueName(e.currentTarget.dataset.optionName);

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/configuration/update?mid=${mid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dimension: { ...state.dimension },
          roofing: { ...state.roofing },
          filling: { ...state.filling },
          surface: { ...state.surface },
          track: {
            ...state.track,
            locks:
              e.currentTarget.dataset.optionId === "locks.none" ? false : true,
            locks_location: e.currentTarget.dataset.optionId,
          },
        }),
      }
    );
    const data = await res.json();
    storeResults(data);
  };

  return (
    <Track
      selectDropdownData={selectDropdownData}
      activeSelectDropdown={activeSelectDropdown}
      coverValueSelect={coverValueSelect}
      locksValueSelect={locksValueSelect}
      covers={covers}
      locks={locks}
      locksName={locksName}
      setExtension={setExtension}
      extensionValue={extensionValue}
      myRef={myRef}
    />
  );
};

const mapState = (state) => ({
  extensionValue: state.track.extension,
  covers: state.track.covers,
  locks: state.track.locks,
  activeSelectDropdown: state.select.activeDropdownName,
  locksName: state.track.locksName,
  state: state,
});

const mapDispatch = (dispatch) => ({
  setExtension: (extension) => dispatch(TrackActions.setExtension(extension)),
  selectCoverValue: (dropdownValue) =>
    dispatch(TrackActions.selectCoverValue(dropdownValue)),
  selectLocksValue: (dropdownValue) =>
    dispatch(TrackActions.selectLocksValue(dropdownValue)),
  storeLocksValueName: (dropdownValueName) =>
    dispatch(TrackActions.storeLocksValueName(dropdownValueName)),
  storeResults: (results) =>
    dispatch(CalculationResultsActions.storeResults(results)),
});

export default connect(mapState, mapDispatch)(TrackContainer);
