import React from "react";
import styled from "styled-components";
import Translate from "react-translate-component";
import { LangDropdown } from "../LangDropdown/LangDropdown";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  padding: 0 1.5rem;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 140rem;
  height: 5rem;
  margin: auto;
  font-size: 1.4rem;
`;

const LogoLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
`;

const Login = styled.a`
  font-size: 1.4rem;
  color: #000;
  text-decoration: none;
`;

export function Header() {
  return (
    <HeaderWrapper>
      <HeaderContent>
        <LogoLabelWrapper>
          <Logo>
            <img src={require("../../assets/images/app-logo.svg")} />
          </Logo>
        </LogoLabelWrapper>

        <div style={{ display: "flex", alignItems: "center" }}>
          <LangDropdown />

          <Login href="/mc/admin">
            <Translate content="login" />
          </Login>
        </div>
      </HeaderContent>
    </HeaderWrapper>
  );
}
