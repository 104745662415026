import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../components/Button/Button';

export default () => (
  <div>
    <h1>404</h1>
    <p>Sorry, that page doesn't exist.</p>
    <Button as={Link} to='/'>Go to homepage</Button>
  </div>
)