import React from "react";
import Translate from "react-translate-component";
import counterpart from "counterpart";

import { Container } from "../../../components/Container/Container";
import {
  HeadingLg,
  HeadingLgWithSideLines,
  HeadingMd,
} from "../../../components/Heading/Heading";
import { Warning } from "../../../components/Warning/Warning";
import { InputTextWithPrefix } from "../../../components/Form/InputTextWithPrefix";
import { Grid, GridItem } from "../../../components/Grid/Grid";
import SelectContainer from "../../../components/Form/SelectContainer";
import LightboxContainer from "../../../components/Lightbox/LightboxContainer";

const Track = ({
  setExtension,
  selectDropdownData,
  activeSelectDropdown,
  covers,
  locks,
  coverValueSelect,
  locksValueSelect,
  locksName,
  extensionValue,
  myRef,
}) => (
  <Container lg style={{ display: "flex" }}>
    <Container md style={{ paddingTop: "10rem" }} ref={myRef}>
      <HeadingLgWithSideLines style={{ marginBottom: "8rem" }}>
        <HeadingLg>
          <Translate content="track.TITLE" />
        </HeadingLg>
      </HeadingLgWithSideLines>

      <Grid columns3>
        <GridItem
          style={{
            display: "inline-flex",
            flexDirection: "column",
          }}
        >
          <img
            src="images/rails/rails-extension.png"
            alt=""
            style={{
              alignSelf: "center",
            }}
          />
          <div style={{ display: "flex", marginTop: "2.1rem" }}>
            <HeadingMd style={{ whiteSpace: "nowrap" }}>
              <Translate content="track.EXTENSION_LABEL" />
            </HeadingMd>
            <LightboxContainer
              lightboxId="track.extension"
              lightboxImages={[{ src: "images/gallery/track/extension.jpg" }]}
            />
          </div>
          <InputTextWithPrefix setExtension={setExtension} prefix="210 +" />
          <div
            style={{
              marginTop: "1.5rem",
              color: "#969696",
              fontSize: "1.4rem",
            }}
          >
            <Translate content="track.TOTAL_EXTENSTION" />:{" "}
            <span style={{ color: "#4e4e4e" }}>
              {extensionValue / 10 + 210 || "0"}
            </span>{" "}
            cm
          </div>
          <div style={{ marginTop: "1.5rem" }}>
            <Warning>
              <Translate content="track.EXTENSION_DESC" />
            </Warning>
          </div>
        </GridItem>
        <GridItem>
          <img
            src={`images/rails/${counterpart("track.COVERS_IMG")}.png`}
            alt=""
          />
          <div style={{ display: "flex", marginTop: "2.8rem" }}>
            <HeadingMd>
              <Translate content="track.COVERS_LABEL" />
            </HeadingMd>
            <LightboxContainer
              lightboxId="track.covers"
              lightboxImages={[{ src: "images/gallery/track/covers.jpg" }]}
            />
          </div>
          {activeSelectDropdown === selectDropdownData[0].id ? (
            <SelectContainer
              selectDropdownData={selectDropdownData[0]}
              open
              onDropdownValueSelect={coverValueSelect}
              DropdownValueName={
                covers === true
                  ? counterpart("track.HAS_COVERS")
                  : covers === ""
                  ? false
                  : counterpart("track.NO_COVERS")
              }
            />
          ) : (
            <SelectContainer
              selectDropdownData={selectDropdownData[0]}
              onDropdownValueSelect={coverValueSelect}
              DropdownValueName={
                covers === true
                  ? counterpart("track.HAS_COVERS")
                  : covers === ""
                  ? false
                  : counterpart("track.NO_COVERS")
              }
            />
          )}
          <div style={{ marginTop: "1.8rem" }}>
            <Warning>
              <Translate content="track.COVERS_DESC" />
            </Warning>
          </div>
        </GridItem>
        <GridItem>
          <img
            src={`images/rails/${counterpart("track.LOCKS_IMG")}.png`}
            alt=""
          />
          <div style={{ display: "flex", marginTop: "5.6rem" }}>
            <HeadingMd>
              <Translate content="track.LOCKS_LABEL" />
            </HeadingMd>
            <LightboxContainer
              lightboxId="track.locks"
              lightboxImages={[
                { src: "images/gallery/track/locks1.jpg" },
                { src: "images/gallery/track/locks2.jpg" },
              ]}
            />
          </div>
          {activeSelectDropdown === selectDropdownData[1].id ? (
            <SelectContainer
              selectDropdownData={selectDropdownData[1]}
              open
              onDropdownValueSelect={locksValueSelect}
              DropdownValueName={locksName}
            />
          ) : (
            <SelectContainer
              selectDropdownData={selectDropdownData[1]}
              onDropdownValueSelect={locksValueSelect}
              DropdownValueName={locksName}
            />
          )}
          <div style={{ marginTop: "1.8rem" }}>
            <Warning>
              <Translate content="track.LOCKS_DESC" />
            </Warning>
          </div>
        </GridItem>
      </Grid>
    </Container>
  </Container>
);

export default Track;
