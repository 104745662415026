import React from "react";
import { connect } from "react-redux";
import counterpart from "counterpart";

import { FillActions } from "../../../actions/fillActions";
import { CalculationResultsActions } from "../../../actions/calculationResultsAction";
import Fill from "./Fill";

const FillContainer = ({
  state,
  selectCard,
  selectDropdownValue,
  storeDropdownValueName,
  storeResults,
  myRef,
  executeScroll,
  fill: { type, positionName },
  activeSelectDropdown,
}) => {
  const fillAssets = {
    classic: {
      galleryImgs: [{ src: "images/gallery/fill/classic.jpg" }],
    },

    smokePolycarbonate: {
      galleryImgs: [{ src: "images/gallery/fill/smoke-polycarbonate.jpg" }],
    },

    compactPolycarbonate: {
      galleryImgs: [{ src: "images/gallery/fill/compact-polycarbonate.jpg" }],
    },
  };

  const isVega = state?.roofing?.type?.includes("vega");

  const selectDropdownData = [
    {
      id: "Klasicka-dutinka",
      options: [
        {
          id: "filling.position.whole",
          name: counterpart("filling.WHOLE"),
        },
      ],
    },
    {
      id: "Kourovy-polykarbonat",
      options: [
        {
          id: "filling.position.whole",
          name: counterpart("filling.WHOLE"),
        },
      ],
    },
    {
      id: "Kompaktni-polykarbonat",
      options: isVega
        ? [
            {
              id: "filling.position.whole",
              name: counterpart("filling.WHOLE"),
            },
          ]
        : [
            {
              id: "filling.position.whole",
              name: counterpart("filling.WHOLE"),
            },
            {
              id: "filling.position.front_all",
              name: counterpart("filling.BROWS"),
            },
            {
              id: "filling.position.front_small",
              name: counterpart("filling.SMALL_BROW"),
            },
            {
              id: "filling.position.front_big",
              name: counterpart("filling.BIG_BROW"),
            },
            {
              id: "filling.position.segments",
              name: counterpart("filling.SEGMENTS"),
            },
          ],
    },
  ];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");

  const onSelect = async (e) => {
    const cardValue = e.currentTarget.dataset.cardName;
    selectCard(cardValue);

    if (
      isVega ||
      cardValue === "filling.type.classic" ||
      cardValue === "filling.type.smoke_polycarbonate"
    ) {
      selectDropdownValue("filling.position.whole");
      storeDropdownValueName(counterpart("filling.WHOLE"));

      const filterValidValueProps = (object) => {
        let result = {};
        for (let prop in object) {
          // if prop value is not init value, send value to server
          if (object[prop] !== "") {
            result[prop] = object[prop];
          }
        }
        return result;
      };

      const filterValidValuePropsArray = (object) => {
        let result = [];
        let index = 0;
        for (let prop in object) {
          if (object[prop] !== "") {
            // check if object property value is object
            if (typeof object[prop] === "object" && object[prop] !== null) {
              for (let subProp in object[prop]) {
                result[index] = result[index] || {};
                if (isNaN(subProp) && object[prop][subProp]) {
                  if (
                    Array.isArray(object[prop][subProp]) &&
                    object[prop][subProp].length > 0
                  ) {
                    result[index][subProp] = object[prop][subProp];
                  } else if (!Array.isArray(object[prop][subProp])) {
                    result[index][subProp] = object[prop][subProp];
                  }
                }
              }
              ++index;
            } else result[prop] = object[prop];
          }
        }
        return result;
      };

      // data to post to server
      const postData = {
        dimension: {
          ...filterValidValueProps(state.dimension),
        },
        roofing: {
          ...filterValidValueProps(state.roofing),
        },
        filling: {
          ...filterValidValueProps(state.filling),
          type: cardValue,
          position: "filling.position.whole",
        },
      };

      // object is not empty
      function isNotEmpty(obj) {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) return true;
        }
        return false;
      }

      // if surface state is not empty object -> post
      // surface data to server
      if (isNotEmpty(filterValidValueProps(state.surface))) {
        postData.surface = filterValidValueProps(state.surface);
      }

      if (
        isNotEmpty(filterValidValueProps(state.track)) &&
        isNotEmpty(filterValidValueProps(state.surface))
      ) {
        postData.track = filterValidValueProps(state.track);
      }

      if (isNotEmpty(filterValidValuePropsArray(state.doors))) {
        const filteredObjects = filterValidValuePropsArray(state.doors);

        postData.doors = filteredObjects.filter((doorObject) => {
          return isNotEmpty(doorObject);
        });
      }

      const res = await fetch(
        `${
          window.location.hostname === "localhost"
            ? "http://mountfield.test.pink-future.cz"
            : ""
        }/mc/api/configuration/update?mid=${mid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );
      const data = await res.json();
      storeResults(data);
      executeScroll();
    }
  };

  const onDropdownValueSelect = async (e) => {
    selectDropdownValue(e.currentTarget.dataset.optionId);
    storeDropdownValueName(e.currentTarget.dataset.optionName);

    const filterValidValueProps = (object) => {
      let result = {};
      for (let prop in object) {
        // if prop value is not init value, send value to server
        if (object[prop] !== "") {
          result[prop] = object[prop];
        }
      }
      return result;
    };

    const filterValidValuePropsArray = (object) => {
      let result = [];
      let index = 0;
      for (let prop in object) {
        if (object[prop] !== "") {
          // check if object property value is object
          if (typeof object[prop] === "object" && object[prop] !== null) {
            for (let subProp in object[prop]) {
              result[index] = result[index] || {};
              if (isNaN(subProp) && object[prop][subProp]) {
                if (
                  Array.isArray(object[prop][subProp]) &&
                  object[prop][subProp].length > 0
                ) {
                  result[index][subProp] = object[prop][subProp];
                } else if (!Array.isArray(object[prop][subProp])) {
                  result[index][subProp] = object[prop][subProp];
                }
              }
            }
            ++index;
          } else result[prop] = object[prop];
        }
      }
      return result;
    };

    // data to post to server
    const postData = {
      dimension: {
        ...filterValidValueProps(state.dimension),
      },
      roofing: {
        ...filterValidValueProps(state.roofing),
      },
      filling: {
        ...filterValidValueProps(state.filling),
        position: e.currentTarget.dataset.optionId,
      },
    };

    // object is not empty
    function isNotEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return true;
      }
      return false;
    }

    // if surface state is not empty object -> post
    // surface data to server
    if (isNotEmpty(filterValidValueProps(state.surface))) {
      postData.surface = filterValidValueProps(state.surface);
    }

    if (
      isNotEmpty(filterValidValueProps(state.track)) &&
      isNotEmpty(filterValidValueProps(state.surface))
    ) {
      postData.track = filterValidValueProps(state.track);
    }

    if (isNotEmpty(filterValidValuePropsArray(state.doors))) {
      const filteredObjects = filterValidValuePropsArray(state.doors);

      postData.doors = filteredObjects.filter((doorObject) => {
        return isNotEmpty(doorObject);
      });
    }

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/configuration/update?mid=${mid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    );
    const data = await res.json();
    storeResults(data);
    executeScroll();
  };

  return (
    <Fill
      selectDropdownData={selectDropdownData}
      onSelect={onSelect}
      selectedCard={type}
      activeSelectDropdown={activeSelectDropdown}
      onDropdownValueSelect={onDropdownValueSelect}
      positionName={positionName}
      fillAssets={fillAssets}
      myRef={myRef}
      isVega={isVega}
    />
  );
};

const mapState = (state) => ({
  state: state,
  fill: state.filling,
  activeSelectDropdown: state.select.activeDropdownName,
});

const mapDispatch = (dispatch) => ({
  selectCard: (cardName) => dispatch(FillActions.selectCard(cardName)),
  selectDropdownValue: (dropdownValue) =>
    dispatch(FillActions.selectDropdownValue(dropdownValue)),
  storeDropdownValueName: (dropdownValueName) =>
    dispatch(FillActions.storeDropdownValueName(dropdownValueName)),
  storeResults: (results) =>
    dispatch(CalculationResultsActions.storeResults(results)),
});

export default connect(mapState, mapDispatch)(FillContainer);
