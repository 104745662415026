export default {
  dimensions: "Dimensions",
  type: "Type",
  filling: "Filler",
  surface: "Color ",
  track: "Tracks",
  doors: "Door specification",
  summarization: "Summarization",
  form: "Sending a request",
  login: "Login",
  mid_alert: "Configurator cannot be started, partner settings are missing",
  config_completed: {
    TITLE: "Thank you!",
    DESC: "Submission was successful. We are sending you a summary of the order with further information to your e-mail. Thank you and have a nice day.",
    BTN: "Start a new configuration",
  },
  dimensions: {
    TITLE: "Dimensions of roofing",
    DISTANCE:
      "Minimum distance of tracks from the edge of the pool is 15 cm from each side of the pool.",
    EDGE_HEIGHT: "The pool edge height above terrain is max 10 cm.",
    IMG: "dimensions-img--en",
    LENGTH: "Length of roofing",
    WIDTH: "Width of roofing",
    MAX_WIDTH: "Max. width of the pool",
    MAX_LENGTH: "Max. length of the pool",
    SUBMIT: "Continue",
  },
  type: {
    TITLE: "Select the roofing type",
    AVAIABLE_DIMENSIONS:
      "Closest possible dimension slightly higher than the entered data",
    LEFT_SIDE: "Left variant",
    RIGHT_SIDE: "Right variant",
    TREND: "Trend",
    TREND_INCREASED: "Trend increased",
    TREND_MAXIMUM: "Trend maximum",
    COMPACT_LOW: "Compact low",
    COMPACT_HIGH: "Compact high",
    ASTRA: "Astra",
    IMPERIAL_LOW: "Imperial low",
    IMPERIAL_MEDIUM: "Imperial medium",
    IMPERIAL_HIGH: "Imperial high",
    APOLLO_OBLIQUE: "Apollo Oblique",
    APOLLO_STRAIGHT: "Apollo Straight",
    VEGA_330: "Vega 330",
    VEGA_380: "Vega 380",
    VEGA_430: "Vega 430",
    VEGA_450: "Vega 450",
  },
  filling: {
    TITLE: "Filler",
    POLYCARBONATE: "10 mm polycarbonate ",
    SMOKED_POLYCARBONATE: "10 mm smoked polycarbonate",
    CLEAR_POLYCARBONATE: "4 mm clear polycarbonate ",
    SPECIFY: "Specify",
    SELECT: "Select",
    WHOLE: "The whole surface",
    BROWS: "Only brows",
    SMALL_BROW: "Small brow",
    BIG_BROW: "Big brow",
    SEGMENTS: "Only segments",
  },
  surface: {
    TITLE: "Color",
    ELOX: "Elox",
    ANTHRACITE: "Anthracite (RAL 7016)",
    BRONZE: "Bronze",
  },
  track: {
    TITLE: "Tracks",
    TYPE: "roofing.track.TYPE",
    TYPE_DOUBLE_SIDED: "Double-sided",
    TYPE_SINGLE_SIDED: "Single-sided",
    EXTENSION_LABEL: "Extension of rails",
    TOTAL_EXTENSTION: "Total extension",
    EXTENSION_DESC:
      "The price includes 210 cm tracks extention behind the pool. In case you request longer extention, enter value.",
    COVERS_LABEL: "Covers",
    COVERS_IMG: "covers-img--en",
    WITHOUT_COVERS: "Without covers",
    WITH_COVERS: "With covers",
    HAS_COVERS: "Yes",
    NO_COVERS: "No",
    COVERS_DESC:
      "Covers serve to cover screws and provide comfortable walking over tracks.",
    LOCKS_LABEL: "Segment´s locks",
    LOCKS_IMG: "locks-img--en",
    NO_LOCKS: "No",
    LOCKS_LEFT: "Yes, on the left side",
    LOCKS_RIGHT: "Yes, on the right side",
    LOCKS_DESC:
      "Separate segments are lockable, this prevents segments from moving. Choose the side where the lock will be placed.",
  },
  doors: {
    TITLE: "Door types",
    big_brow: {
      TITLE: "Doors in big brow",
      NO_DOORS: "No doors",
      FLAP: "Flap",
      FLAP_LABEL: "Enter desired height",
      FLAP_DESC: "Minimum 25 cm - maximum 1/3 of brow height",
      DOORS: "Doors",
      WITHOUT_THRESHOLD: "Without threshold",
      WITH_THRESHOLD: "With threshold",
      THRESHOLD_DESC:
        "Sliding threshold, possible to be pulled up, this allows to overcome higher object while closing. (a ladder for example)",
    },
    small_brow: {
      TITLE: "Doors in small brow",
      NO_DOORS: "No doors",
      FLAP: "Flap",
      FLAP_DESC:
        "The flap is possible to be pulled up, this allows to overcome higher object while closing (a ladder for example).",
      DOORS: "Doors",
      WITHOUT_THRESHOLD: "Without threshold",
      WITH_THRESHOLD: "With threshold",
      THRESHOLD_DESC:
        "Sliding threshold possible to be pulled up, this allows to overcome higher object while closing. (a ladder for example)",
    },
    side_doors: {
      TITLE: "Side doors",
      NO_DOORS: "No door",
      SHUNTING_DOOR: "Shunting door (opening to the side)",
      SHUNTING_DOOR_LABEL: "Specify the side and position",
      SHUNTING_DESC:
        "Doors can only be placed into the biggest segment. Choose the side the door will be placed.",
      SLIDING_DOOR: "Sliding door (opening upwards)",
    },
  },
  CALCULATE: "Calculation",
  summarization: {
    TITLE: "Summary",
    DIMENSIONS: "Dimensions and type",
    INNER_WIDTH: "Internal roof width",
    INNER_LENGTH: "Internal roof lenght",
    OUTER_LENGTH: "External roof lenght",
    OUTER_WIDTH: "External roof width",
    SEGMENTS: "Number of segments",
    MIN_INNER_HEIGHT: "Minimum internal height",
    MAX_OUTER_HEIGHT: "Maximum external height",
    FILLING_SURFACE: "Filler and color",
    FILLING: "Filler",
    SURFACE: "Color",
    DOORS_TYPE: "Door types",
    DOORS_BIG: "Doors in the big brow",
    DOORS_BIG_SLIDING_THRESHOLD: "Big brow - sliding threshold",
    DOORS_SMALL: "Doors in the small brow",
    DOORS_SMALL_SLIDING_THRESHOLD: "Small brow - sliding threshold",
    DOORS_SIDE: "Side doors",
    TRACK: "Tracks",
    TRACK_TYPE: "Track type",
    TRACK_WIDTH: "Track width",
    TRACK_LENGTH: "Track length",
    LOCKS: "Segment locks",
    TRACK_EXTENSION: "Extension of rails",
    TRACK_COVERS: "Covers",
    PRICE: "Price",
    CONFIG: "Configuration",
    STOCK_MODEL: "Stock model",
  },
  form: {
    TITLE: "Send inquiry",
    DESC: "The inquiry is non-binding. We will contact you on its basis.",
    EMAIL: "E-Mail",
    TEL: "Telephone number ",
    FIRST_NAME: "Name",
    SURNAME: "Surname",
    ADDRESS_DESC:
      "To receive transfer cost please enter your exact address. (optional)",
    STREET: "Street",
    HOUSE_NUMBER: "house number",
    CITY: "City",
    POSTAL_CODE: "Postal code",
    COUNTRY: "Country",
    SELECT_COUNTRY: "Select Country",
    TERMS: "I agree with the terms",
    SUBMIT: "Send",
    REQUIRED: "Required",
    GDPR: "GDPR protection of personal data",
  },
  stock: {
    STOCK_MODEL: "Stock model",
    YOUR_CUSTOM_MODEL: "Your custom model",
    PRICE_FROM: "From",
    STOCK_PROMO:
      "This stock model also corresponds to the parameters you entered. It is at a discounted price and delivery time is shortened by the production process.",
    STOCK_SHOW: "Display",
    CONFIGURE_MSG:
      "The stock model contains some optional parameters. Please configure.",
    big_brow: {
      WITHOUT_THRESHOLD: "Without threshold",
      DOORS: "Doors",
    },
    side_doors: {
      SLIDING_DOOR: "Sliding door (opening upwards)",
    },
    FINISH: "Finish",
    BACK_TO_CUSTOM_MODEL: "Back to the custom model",
    ORDER_STOCK_MODEL: "Order a stock model",
  },
};
