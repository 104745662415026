export const SET_WIDTH = "DIMENSIONS:SET_WIDTH";
export const SET_LENGTH = "DIMENSIONS:SET_LENGTH";

export const TOGGLE_ROOFING_SECTION = "ROOFING_SECTION:TOGGLE";
export const SELECT_ROOFING_CARD = "ROOFING_CARD:SELECT";
export const STORE_ROOFINGS = "ROOFINGS:STORE";
export const SELECT_ROOFING_VARIANT = "ROOFING:VARIANT";

export const SELECT_FILL_CARD = "FILL_CARD:SELECT";
export const SELECT_FILL_DROPDOWN_VALUE = "FILL_DROPDOWN:SELECT_VALUE";
export const STORE_FILL_POSITION_NAME = "FILL_DROPDOWN:STORE_VALUE_NAME";

export const SELECT_SURFACE_CARD = "SURFACE_CARD:SELECT";

export const SET_TRACK_EXTENSION = "TRACK:SET_EXTENSION";
export const SELECT_TRACK_HAS_COVERS = "TRACK_HAS_COVERS:SELECT_VALUE";
export const SELECT_TRACK_HAS_LOCKS = "TRACK_HAS_LOCKS:SELECT_VALUE";
export const STORE_TRACK_LOCKS_NAME = "TRACK_DROPDOWN:STORE_VALUE_NAME";

export const TOGGLE_DROPDOWN = "DROPDOWN:TOGGLE";

export const SHOW_LIGHTBOX = "LIGHTBOX:SHOW";

export const SELECT_SIDE_DOOR_CARD = "SIDE_DOOR_CARD:SELECT";
export const STORE_DOORS = "DOORS:STORE";
export const SELECT_LOW_DOOR_CARD = "LOW_DOOR_CARD:SELECT";
export const SET_FRONT_SMALL_TILT_SIZE = "LOW_DOOR:SET_TILT_SIZE";
export const SELECT_HIGH_DOOR_CARD = "HIGH_DOOR_CARD:SELECT";
export const SET_FRONT_BIG_TILT_SIZE = "HIGH_DOOR:SET_TILT_SIZE";
export const SET_HIGH_DOOR_THRESHOLD = "HIGH_DOOR:SET_threshold";
export const SET_DOOR_SEGMENTS = "SIDE_DOOR:SET_SEGMENTS";

export const STORE__STOCK_MODEL = "STOCK_MODEL:STORE";
export const STORE__STOCK_MODEL_DOORS = "STOCK_MODEL_DOORS:STORE";
export const STORE__STOCK_MODEL_FILLS = "STOCK_MODEL_FILLS:STORE";
export const STORE__STOCK_MODEL_SURFACES = "STOCK_MODEL_SURFACES:STORE";
export const SELECT__STOCK_MODEL_DOORS = "STOCK_MODEL_DOORS:SELECT";
export const SELECT_STOCK_MODEL_FILL = "STOCK_MODEL_FILL:SELECT";
export const SET_STOCK_DOOR_SEGMENTS = "STOCK_DOOR_SEGMENTS:SET";
export const SELECT__STOCK_MODEL_SURFACE = "STOCK_MODEL_SURFACE:SELECT";
export const SET_VISIBLE_STOCK_MODEL_FILL = "STOCK_MODEL_FILL:SET_VISIBLE";
export const SELECT_STOCK_MODEL_FILL_DROPDOWN_VALUE =
  "STOCK_MODEL_FILL_DROPDOWN_VALUE:SELECT";
export const STORE_STOCK_MODEL_FILL_POSITION_NAME =
  "STOCK_MODEL_FILL_DROPDOWN:STORE_VALUE_NAME";
export const SET_VISIBLE_STOCK_MODEL_SURFACE =
  "STOCK_MODEL_SURFACE:SET_VISIBLE";
export const SET_VISIBLE_STOCK_MODEL_TRACK = "STOCK_MODEL_TRACK:SET_VISIBLE";
export const SET_VISIBLE_STOCK_MODEL_SUMMARIZATION =
  "STOCK_MODEL_SUMMARIZATION:SET_VISIBLE";
export const SET_VISIBLE_STOCK_MODEL_FORM = "STOCK_MODEL_FORM:SET_VISIBLE";
export const SET__STOCK_MODEL_TRACK_EXTENSION =
  "STOCK_MODEL_TRACK_EXTENSION:SET";
export const SELECT_STOCK_MODEL_TRACK_HAS_COVERS =
  "STOCK_MODEL_TRACK_HAS_COVERS:SELECT_VALUE";
export const SELECT_STOCK_MODEL_TRACK_HAS_LOCKS =
  "STOCK_MODEL_TRACK_HAS_LOCKS:SELECT_VALUE";
export const STORE_STOCK_MODEL_TRACK_LOCKS_NAME =
  "STOCK_MODEL_TRACK_DROPDOWN:STORE_VALUE_NAME";
export const STORE_STOCK_MODEL_RESULTS = "STOCK_MODEL_RESULTS:STORE";

export const STORE_CALCULATION_RESULTS = "CALCULATION_RESULTS:STORE";

export const SET_CALCULATION_STATE = "CALCULATION_STATE:SET";
