import React, { useState } from "react";
import styled from "styled-components";

const DropdownBtn = styled.button`
  padding: 1rem 2rem;
  font-size: 1.4rem;
  border: none;
  background: transparent;
  outline: none;
  text-transform: uppercase;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  display: ${(props) => (props.open ? "block" : "none")};
  padding: 0;
  margin: 0;
  background-color: #efecec;
  list-style: none;

  a {
    display: block;
    padding: 0 2rem;
    font-size: 1.4rem;
    line-height: 1.8;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
  }
`;

const LangDropdownStyled = styled.div`
  position: relative;
  padding-right: 2rem;
`;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const mid = urlParams.get("mid");
const locale = urlParams.get("locale");

export function LangDropdown() {
  const [open, setOpen] = useState(false);

  return (
    <LangDropdownStyled>
      <DropdownBtn
        onClick={() => {
          setOpen(!open);
        }}
      >
        {locale}{" "}
        <span style={{ color: "rgba(0, 0, 0, 0.7)", fontSize: "1rem" }}>▼</span>
      </DropdownBtn>
      <DropdownList open={open}>
        <li>
          <a href={`/?mid=${mid}&locale=cs`}>cs</a>
        </li>
        <li>
          <a href={`/?mid=${mid}&locale=en`}>en</a>
        </li>
        <li>
          <a href={`/?mid=${mid}&locale=de`}>de</a>
        </li>
      </DropdownList>
    </LangDropdownStyled>
  );
}
