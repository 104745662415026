import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import Translate from "react-translate-component";

const SidebarPriceLabel = styled.div`
  padding: 0.2rem 0.7rem;
  margin-top: 5rem;
  font-size: 1.4rem;
  color: #fff;
  background-color: #000;
`;

const SidebarPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SidebarPriceUnit = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  color: #000;
`;

const SidebarPrice = styled.div`
  margin-left: 1.3rem;
  font-size: 4rem;
  font-weight: 700;
  color: #000;
`;

const NavigationAnchor = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const NavigationLiDot = styled.div`
  flex: none;
  width: 0.9rem;
  height: 0.9rem;
  margin-left: 0.3rem;
  background-color: ${(props) => (props.full ? "#a2a2a2" : "#fff")};
  border: 1px solid #a2a2a2;
  border-radius: 50%;
`;

const NavigationLiText = styled.div`
  padding-left: 2.1rem;
  font-size: 1.6rem;
  color: #a2a2a2;
`;

const NavigationLi = styled.li`
  position: relative;
  z-index: 2;

  &:not(:last-child) {
    padding-bottom: 2rem;
  }

  ${(props) =>
    props.active &&
    css`
      ${NavigationLiDot} {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0;
        background-color: #000;
        border-color: #000;
      }

      ${NavigationLiText} {
        font-weight: 700;
        color: #000;
      }
    `}

  ${(props) =>
    props.invalid &&
    css`
      ${NavigationLiDot} {
        position: relative;
        width: 1.3rem;
        height: 1.3rem;
        margin-left: 0.1rem;
        background-color: #d0021b;
        background-image: url("images/icons/exclamation-mark.svg");
        background-repeat: no-repeat;
        background-position: center;
        border-color: #d0021b;
      }

      ${NavigationLiText} {
        font-weight: 700;
        color: #d0021b;
      }
    `}
`;

const NavigationList = styled.ul`
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;

  &:before {
    position: absolute;
    top: 2rem;
    bottom: 1rem;
    left: 0.7rem;
    content: "";
    border-left: 1px solid rgb(162, 162, 162, 0.47);
  }
`;

const Navigation = styled.nav``;

const Sidebar = styled.div`
  position: sticky;
  top: 12.9rem;
  flex: none;
  align-self: flex-start;
  width: 17.2rem;
  margin-top: 12.9rem;
`;

export function NavigationSidebar({
  dimensions,
  roofing,
  fill,
  surface,
  track,
  doors,
}) {
  const price = useSelector((state) => state.calculationResults.results.price);

  return (
    <Sidebar>
      <Navigation>
        <NavigationList>
          <NavigationLi active={!dimensions}>
            <NavigationAnchor>
              <NavigationLiDot full={dimensions} />
              <NavigationLiText>
                <Translate content="dimensions.TITLE" />
              </NavigationLiText>
            </NavigationAnchor>
          </NavigationLi>

          <NavigationLi active={!roofing && dimensions}>
            <NavigationAnchor>
              <NavigationLiDot full={roofing} />
              <NavigationLiText>
                <Translate content="type.TITLE" />
              </NavigationLiText>
            </NavigationAnchor>
          </NavigationLi>

          <NavigationLi active={!fill && roofing && dimensions}>
            <NavigationAnchor>
              <NavigationLiDot full={fill} />
              <NavigationLiText>
                <Translate content="filling.TITLE" />
              </NavigationLiText>
            </NavigationAnchor>
          </NavigationLi>

          <NavigationLi active={!surface && fill && roofing && dimensions}>
            <NavigationAnchor>
              <NavigationLiDot full={surface} />
              <NavigationLiText>
                <Translate content="surface.TITLE" />
              </NavigationLiText>
            </NavigationAnchor>
          </NavigationLi>

          <NavigationLi
            active={!track && surface && fill && roofing && dimensions}
          >
            <NavigationAnchor>
              <NavigationLiDot full={track} />
              <NavigationLiText>
                <Translate content="track.TITLE" />
              </NavigationLiText>
            </NavigationAnchor>
          </NavigationLi>

          <NavigationLi
            active={!doors && track && surface && fill && roofing && dimensions}
          >
            <NavigationAnchor>
              <NavigationLiDot full={doors} />
              <NavigationLiText>
                <Translate content="doors.TITLE" />
              </NavigationLiText>
            </NavigationAnchor>
          </NavigationLi>

          <NavigationLi>
            <NavigationAnchor>
              <NavigationLiDot full={doors} />
              <NavigationLiText>
                <Translate content="summarization.TITLE" />
              </NavigationLiText>
            </NavigationAnchor>
          </NavigationLi>

          <NavigationLi
            active={doors && track && surface && fill && roofing && dimensions}
          >
            <NavigationAnchor>
              <NavigationLiDot />
              <NavigationLiText>
                <Translate content="form.TITLE" />
              </NavigationLiText>
            </NavigationAnchor>
          </NavigationLi>
        </NavigationList>
      </Navigation>

      {price ? (
        <div>
          <SidebarPriceLabel>
            <Translate content="summarization.PRICE" />
          </SidebarPriceLabel>
          <SidebarPriceWrapper>
            <SidebarPriceUnit>€</SidebarPriceUnit>
            <SidebarPrice>{price.toFixed(1) || "0"}</SidebarPrice>
          </SidebarPriceWrapper>
        </div>
      ) : (
        ""
      )}
    </Sidebar>
  );
}
