import React from "react";
import { Link } from "react-router-dom";

import { Container } from "../../components/Container/Container";
import {
  HeadingLgWithSideLines,
  HeadingLg,
} from "../../components/Heading/Heading";
import { LinkText } from "../../components/LinkText/LinkText";

export default () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");
  const locale = urlParams.get("locale");

  return (
    <Container md style={{ paddingTop: "7rem" }}>
      <LinkText as={Link} to={`/?mid=${mid}&locale=${locale}`}>
        back to config
      </LinkText>

      <HeadingLgWithSideLines>
        <HeadingLg>Obchodní podmínky</HeadingLg>
      </HeadingLgWithSideLines>
      <p
        style={{
          color: "#4e4e4e",
          fontSize: "1.8rem",
          marginTop: "5.6rem",
        }}
      >
        Lorem ipsum (zkráceně lipsum) je označení pro standardní pseudolatinský
        text užívaný v grafickém designu a navrhování jako demonstrativní
        výplňový text při vytváření pracovních ukázek grafických návrhů (např.
        internetových stránek, rozvržení časopisů či všech druhů reklamních
        materiálů). Lipsum tak pracovně znázorňuje text v ukázkových maketách
        (tzv. mock-up) předtím, než bude do hotového návrhu vložen smysluplný
        obsah.
      </p>
      <p
        style={{
          color: "#4e4e4e",
          fontSize: "1.8rem",
        }}
      >
        Pokud by se pro stejný účel použil smysluplný text, bylo by těžké
        hodnotit pouze vzhled, aniž by se pozorovatel nechal svést ke čtení
        obsahu. Pokud by byl naopak použit nesmyslný, ale pravidelný text (např.
        opakování „asdf asdf asdf…“), oko by při posuzování vzhledu bylo
        vyrušováno pravidelnou strukturou textu, která se od běžného textu liší.
        Text lorem ipsum na první pohled připomíná běžný text, slova jsou různě
        dlouhá, frekvence písmen je podobná běžné řeči, interpunkce vypadá
        přirozeně atd.
      </p>
      <p
        style={{
          color: "#4e4e4e",
          fontSize: "1.8rem",
        }}
      >
        Lorem ipsum (zkráceně lipsum) je označení pro standardní pseudolatinský
        text užívaný v grafickém designu a navrhování jako demonstrativní
        výplňový text při vytváření pracovních ukázek grafických návrhů (např.
        internetových stránek, rozvržení časopisů či všech druhů reklamních
        materiálů). Lipsum tak pracovně znázorňuje text v ukázkových maketách
        (tzv. mock-up) předtím, než bude do hotového návrhu vložen smysluplný
        obsah. Pokud by se pro stejný účel použil smysluplný text, bylo by těžké
        hodnotit pouze vzhled, aniž by se pozorovatel nechal svést ke čtení
        obsahu.
      </p>
      <p
        style={{
          color: "#4e4e4e",
          fontSize: "1.8rem",
        }}
      >
        Pokud by byl naopak použit nesmyslný, ale pravidelný text (např.
        opakování „asdf asdf asdf…“), oko by při posuzování vzhledu bylo
        vyrušováno pravidelnou strukturou textu, která se od běžného textu liší.
        Text lorem ipsum na první pohled připomíná běžný text, slova jsou různě
        dlouhá, frekvence písmen je podobná běžné řeči, interpunkce vypadá
        přirozeně atd.Lorem ipsum (zkráceně lipsum) je označení pro standardní
        pseudolatinský text užívaný v grafickém designu a navrhování jako
        demonstrativní výplňový text při vytváření pracovních ukázek grafických
        návrhů (např. internetových stránek, rozvržení časopisů či všech druhů
        reklamních materiálů). Lipsum tak pracovně znázorňuje text v ukázkových
        maketách (tzv. mock-up) předtím, než bude do hotového návrhu vložen
        smysluplný obsah.
      </p>
    </Container>
  );
};
