import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    font-family: "Open Sans", sans-serif;
    font-size: 1.6rem; /* default font size */
  }

  main {
    padding-bottom: 20rem;
  }

  .col-2 .configuration-wrapper {
    display: flex;
  }

  .col-2 .configuration-left {
    flex: 1;
  }

  .col-2 .configuration-right {
    flex: 1;
    margin-left: 6rem;
  }



  .configuration-heading {
    padding-top: 2rem;
    padding-bottom: 0.8rem;
    font-size: 1.6rem;
    font-weight: 700;
    color: #4e4e4e;
    text-transform: uppercase;
    letter-spacing: 0.91px;
    border-bottom: 1px solid #000;

    .disabled & {
      color: rgba(78,78,78,0.5);
    }

  }

  .configuration-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .configuration-li {
    display: flex;
    padding: 10px 0;
    font-size: 14px;
    color: #4e4e4e;
    border-bottom: 1px solid rgba(151, 151, 151, 0.35);

    .disabled & {
      color: rgba(78,78,78,0.5);
    }
  }

  .configuration-li__key {
    width: 50%;
  }

  .configuration-li__value {
    width: 50%;
    padding-left: 20px;
  }


  ${"" /*  FORM STYLES */}

  .c-form__input {
    width: 100%;
    height: 4rem;
    padding: 1rem 1.5rem;
    font-size: 14px;
    color: #4e4e4e;
    background-color: #fff;
    border: 1px solid #ababab;
    border-radius: 6px;

    &:hover {
      border-color: #4a90e2;
    }

    &:focus {
      border: 2px solid #4a90e2;
      outline: none;
    }
  }

  .invalid {
    padding-top: 0.5rem;
    font-size: 1.4rem;
    color: #d0021b;
  }
`;
