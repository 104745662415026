import { STORE_CALCULATION_RESULTS } from "../actions/types";

const initialState = {
  results: {
    price: 0,
    dimension: {
      width: 0,
      length: 0,
      height: 0,
    },
    roofing: {
      type: "roofing.type.trend_standard",
      inside_width: 0,
      outside_width: 0,
      inside_length: 0,
      segment_quantity: 0,
      min_inside_height: 0,
      max_outside_height: 0,
    },
    filling: {
      type: "filling.type.classic",
      position: "filling.position.whole",
      price: 0.0,
      configurable: false,
    },
    surface: {
      type: "color.type.elox",
    },
    track: {
      width: 0,
      length: 0,
      locks: false,
      locks_location: null,
      locks_price: 0.0,
      extension: 0,
      covers: false,
      covers_price: 0.0,
      bi_directional: true,
    },
    doors: [
      {
        type: "doors.type.front_big",
        variant: "doors.variant.none",
        position: null,
        segment: null,
        tilt_size: 0,
      },
      {
        type: "doors.type.front_small",
        variant: "doors.variant.none",
        position: null,
        segment: null,
        tilt_size: 0,
      },
      {
        type: "doors.type.side",
        variant: "doors.variant.none",
        position: null,
        segment: null,
        tilt_size: 0,
      },
    ],
    contact: {
      email: null,
      phone: null,
      first_name: null,
      last_name: null,
      street: null,
      house_number: null,
      city: null,
      zip_code: null,
      country: null,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_CALCULATION_RESULTS:
      return {
        ...state,
        results: action.storeResults,
      };

    default:
      return state;
  }
};
