import React from "react";

import counterpart from "counterpart";

import { Header } from "./components/Header/Header";
import LightboxContainer from "./components/Lightbox/LightboxContainer";

import en from "./translations/en_GB";
import cs from "./translations/cs_CZ";
import de from "./translations/de_DE";

counterpart.registerTranslations("en", en);
counterpart.registerTranslations("cs", cs);
counterpart.registerTranslations("de", de);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const locale = urlParams.get("locale");

counterpart.setLocale(locale);

export default ({ children }) => {
  return (
    <div>
      <LightboxContainer />
      <Header />
      <main>{children}</main>
    </div>
  );
};
