import { combineReducers } from "redux";

import dimensionsReducer from "./dimensionsReducer";
import roofingReducer from "./roofingReducer";
import fillReducer from "./fillReducer";
import surfaceReducer from "./surfaceReducer";
import trackReducer from "./trackReducer";
import selectReducer from "./selectReducer";
import doorsReducer from "./doorsReducer";
import lightboxReducer from "./lightboxReducer";
import stockModelsReducer from "./stockModelsReducer";
import calculationResultsReducer from "./calculationResultsReducer";
import calculationReducer from "./calculationReducer";

const appReducer = combineReducers({
  dimension: dimensionsReducer,
  roofing: roofingReducer,
  filling: fillReducer,
  surface: surfaceReducer,
  track: trackReducer,
  select: selectReducer,
  doors: doorsReducer,
  lightbox: lightboxReducer,
  stockModels: stockModelsReducer,
  calculationResults: calculationResultsReducer,
  calculation: calculationReducer,
});

const rootReducer = (state, action) => {
  if (
    action.type === "DIMENSIONS:SET_WIDTH" ||
    action.type === "DIMENSIONS:SET_LENGTH"
  ) {
    state = {
      dimension: { ...state.dimension },
    };
  } else if (action.type === "ROOFING_CARD:SELECT") {
    state = {
      dimension: { ...state.dimension },
      roofing: { ...state.roofing },
    };
  } else if (action.type === "SIDE_DOOR_CARD:SELECT") {
    state = {
      dimension: { ...state.dimension },
      roofing: { ...state.roofing },
      filling: { ...state.filling },
      surface: { ...state.surface },
      track: { ...state.track },
      doors: {
        ...state.doors,
        ["doors.type.front_big"]: { ...state.doors["doors.type.front_big"] },
        ["doors.type.front_small"]: {
          ...state.doors["doors.type.front_small"],
        },
        ["doors.type.side"]: {
          type: "doors.type.side",
          segments: [],
        },
      },
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
