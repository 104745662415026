import React from "react";
import styled from "styled-components";
import Translate from "react-translate-component";
import { Link } from "react-router-dom";

const StockModelWrapper = styled.div`
  display: flex;
  max-width: 78rem;
  padding: 2.8rem 2rem;
  margin: auto;
  margin-top: 10rem;
  border: 2px solid #f5a623;
  border-radius: 6px;
  box-shadow: -2px 0 9px rgba(0, 0, 0, 0.34);
`;

const StockModelLeft = styled.div``;

const StockModelRight = styled.div`
  padding-left: 2.5rem;
`;

const StockModelImg = styled.div`
  width: 21rem;

  img {
    width: 100%;
    height: auto;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
`;

const StockModelTitle = styled.div`
  padding-top: 1rem;
  margin-bottom: 1.6rem;
  font-family: "Open Sans";
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3rem;
  color: #000;
`;

const StockModelPrice = styled.span`
  font-family: "Open Sans";
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.5;
  color: #4e4e4e;
`;

const StockModelDesc = styled.div`
  margin-top: 1rem;
`;

const StockModelBtn = styled.div`
  display: inline-block;
  margin-top: 1.9rem;
  border-radius: 6px;
  background-color: #4a90e2;

  &:hover {
    background-color: #326096;
  }

  a {
    display: inline-block;
    padding: 0.4rem 3.7rem;
    font-family: "Open Sans";
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.8px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }
`;

const StockModel = ({ stockModel }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");
  const locale = urlParams.get("locale");

  return (
    <StockModelWrapper>
      <StockModelLeft>
        <StockModelImg>
          <img src={`images/doors/doors.type.front_big.png`} />
        </StockModelImg>
      </StockModelLeft>
      <StockModelRight>
        <StockModelTitle>
          <Translate content="stock.STOCK_MODEL" />{" "}
          {stockModel ? (
            stockModel.name
          ) : (
            <Translate content="stock.STOCK_MODEL" />
          )}
        </StockModelTitle>
        <div>
          <Translate content="stock.PRICE_FROM" />{" "}
          <StockModelPrice>
            {stockModel ? stockModel.price_from.toFixed(1) : "0"} €
          </StockModelPrice>
        </div>
        <StockModelDesc>
          <Translate content="stock.STOCK_PROMO" />
        </StockModelDesc>
        <StockModelBtn>
          <Link to={`/stockModelsConfig?mid=${mid}&locale=${locale}`}>
            <Translate content="stock.STOCK_SHOW" />
          </Link>
        </StockModelBtn>
      </StockModelRight>
    </StockModelWrapper>
  );
};

export default StockModel;
