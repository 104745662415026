import { SELECT_SURFACE_CARD } from "../actions/types";

const initialState = {
  type: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_SURFACE_CARD:
      return {
        ...state,
        type: action.cardName
      };

    default:
      return state;
  }
};
