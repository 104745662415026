import styled, { css } from "styled-components";

export const GridItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  ${(props) =>
    props.columns2 &&
    css`
      ${GridItem} {
        width: calc(100% / 2);

        &:only-child {
          width: auto;
        }
      }
    `}
  ${(props) =>
    props.columns3 &&
    css`
      ${GridItem} {
        width: 22.5rem;

        &:only-child {
          width: auto;
        }

        &:not(:last-child) {
          margin-right: 3.7rem;
        }
      }
    `}
  ${(props) =>
    props.columns4 &&
    css`
      ${GridItem} {
        width: 18.2rem;

        &:only-child {
          width: auto;
        }

        &:not(:nth-child(4n)):not(:last-child) {
          margin-right: 3rem;
        }
      }
    `}
`;
