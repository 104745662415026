import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { CountryDropdown } from "react-country-region-selector";
import * as Yup from "yup";

import { Container } from "../../../components/Container/Container";
import { Button } from "../../../components/Button/Button";
import {
  HeadingLg,
  HeadingLgWithSideLines,
} from "../../../components/Heading/Heading";
import { FormLabel } from "../../../components/Form/FormLabel";
import {
  FormLayoutRow,
  FormLayoutWidth100,
  FormLayoutWidth75,
  FormLayoutWidth50,
  FormLayoutWidth25,
} from "../../../components/Form/FormLayout";
import { Warning } from "../../../components/Warning/Warning";
import { Footer } from "../../../components/Footer/Footer";

const UserForm = ({ configurationSubmitData, isStockModel, myRef }) => {
  const state = useSelector((state) => state);
  const configResults = state?.calculationResults?.results;
  const [configurationSent, setconfigurationSent] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");
  const locale = urlParams.get("locale");

  if (configurationSent) {
    return <Redirect to={`/submitsucces?mid=${mid}&locale=${locale}`} />;
  } else {
    return (
      <Fragment>
        <Container lg style={{ display: "flex" }} ref={myRef}>
          <Container md style={{ paddingTop: "10rem" }}>
            <HeadingLgWithSideLines>
              <HeadingLg>
                <Translate content="form.TITLE" />
              </HeadingLg>
            </HeadingLgWithSideLines>

            <Container sm>
              <Formik
                initialValues={{
                  email: "",
                  phone: "",
                  first_name: "",
                  last_name: "",
                  street: "",
                  house_number: "",
                  city: "",
                  zip_code: "",
                  country: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .required(
                      <div className="invalid">
                        <Translate content="form.REQUIRED" />
                      </div>
                    ),
                  phone: Yup.string().matches(
                    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    "Phone number is not valid"
                  ),
                  first_name: Yup.string(),
                  last_name: Yup.string(),
                  street: Yup.string(),
                  house_number: Yup.string(),
                  city: Yup.string().required(
                    <div className="invalid">
                      <Translate content="form.REQUIRED" />
                    </div>
                  ),
                  zip_code: Yup.string(),
                })}
                onSubmit={async (contactValues) => {
                  if (isStockModel) {
                    fetch(
                      `${
                        window.location.hostname === "localhost"
                          ? "http://mountfield.test.pink-future.cz"
                          : ""
                      }/mc/api/stock-models/${
                        state.stockModels.stockModel.id
                      }/configuration-send?mid=${mid}&locale=${locale}
                    `,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          ...configurationSubmitData,
                          contact: contactValues,
                        }),
                      }
                    );
                  } else {
                    fetch(
                      `${
                        window.location.hostname === "localhost"
                          ? "http://mountfield.test.pink-future.cz"
                          : ""
                      }/mc/api/configuration/send?mid=${mid}&locale=${locale}
                    `,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          ...configurationSubmitData,
                          contact: contactValues,
                        }),
                      }
                    );
                  }
                  setconfigurationSent(true);
                }}
              >
                {({ values, handleChange, handleBlur }) => (
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "5.2rem 0",
                      }}
                    >
                      <Warning>
                        <Translate content="form.DESC" />
                      </Warning>
                    </div>
                    <FormLayoutRow>
                      <FormLayoutWidth50>
                        <FormLabel htmlFor="email">
                          <Translate content="form.EMAIL" />
                        </FormLabel>
                        <Field
                          className="c-form__input"
                          name="email"
                          type="email"
                        />
                        <ErrorMessage name="email" />
                      </FormLayoutWidth50>
                      <FormLayoutWidth50>
                        <FormLabel htmlFor="phone">
                          <Translate content="form.TEL" />
                        </FormLabel>
                        <Field
                          className="c-form__input"
                          name="phone"
                          type="phone"
                        />
                        <ErrorMessage name="phone" />
                      </FormLayoutWidth50>
                    </FormLayoutRow>
                    <FormLayoutRow>
                      <FormLayoutWidth50>
                        <FormLabel htmlFor="first_name">
                          <Translate content="form.FIRST_NAME" />
                        </FormLabel>
                        <Field
                          className="c-form__input"
                          name="first_name"
                          type="text"
                        />
                        <ErrorMessage name="first_name" />
                      </FormLayoutWidth50>
                      <FormLayoutWidth50>
                        <FormLabel htmlFor="last_name">
                          <Translate content="form.SURNAME" />
                        </FormLabel>
                        <Field
                          className="c-form__input"
                          name="last_name"
                          type="text"
                        />
                        <ErrorMessage name="last_name" />
                      </FormLayoutWidth50>
                    </FormLayoutRow>
                    <FormLayoutRow>
                      <FormLayoutWidth75>
                        <FormLabel htmlFor="street">
                          <Translate content="form.STREET" />
                        </FormLabel>
                        <Field
                          className="c-form__input"
                          name="street"
                          type="text"
                        />
                        <ErrorMessage name="street" />
                      </FormLayoutWidth75>
                      <FormLayoutWidth25>
                        <FormLabel htmlFor="house_number">
                          <Translate content="form.HOUSE_NUMBER" />
                        </FormLabel>
                        <Field
                          className="c-form__input"
                          name="house_number"
                          type="text"
                        />
                        <ErrorMessage name="house_number" />
                      </FormLayoutWidth25>
                    </FormLayoutRow>
                    <FormLayoutRow>
                      <FormLayoutWidth75>
                        <FormLabel htmlFor="city">
                          <Translate content="form.CITY" />
                        </FormLabel>
                        <Field
                          className="c-form__input"
                          name="city"
                          type="text"
                        />
                        <ErrorMessage name="city" />
                      </FormLayoutWidth75>
                      <FormLayoutWidth25>
                        <FormLabel htmlFor="zip_code">
                          <Translate content="form.POSTAL_CODE" />
                        </FormLabel>
                        <Field
                          className="c-form__input"
                          name="zip_code"
                          type="text"
                        />
                        <ErrorMessage name="zip_code" />
                      </FormLayoutWidth25>
                    </FormLayoutRow>
                    <FormLayoutRow>
                      <FormLayoutWidth50>
                        <FormLabel htmlFor="country">
                          <Translate content="form.COUNTRY" />
                        </FormLabel>
                        <CountryDropdown
                          className="c-form__input"
                          name="country"
                          defaultOptionLabel={counterpart(
                            "form.SELECT_COUNTRY"
                          )}
                          value={values.country}
                          onChange={(_, e) => handleChange(e)}
                          onBlur={handleBlur}
                        />
                      </FormLayoutWidth50>
                    </FormLayoutRow>
                    {configResults?.show_transport_info ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "5.2rem 0 3.2rem",
                        }}
                      >
                        <Warning>
                          <Translate content="form.ADDRESS_DESC" />
                        </Warning>
                      </div>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "3.1rem 0 3.2rem",
                      }}
                    >
                      <Button type="submit">
                        <Translate content="form.SUBMIT" />
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Container>
          </Container>
        </Container>
        <Footer configResults={configResults} />
      </Fragment>
    );
  }
};

export default UserForm;
