import {
  STORE__STOCK_MODEL,
  STORE__STOCK_MODEL_DOORS,
  STORE__STOCK_MODEL_FILLS,
  STORE__STOCK_MODEL_SURFACES,
  SELECT__STOCK_MODEL_DOORS,
  SET_STOCK_DOOR_SEGMENTS,
  SET_DOOR_SEGMENTS,
  SET_VISIBLE_STOCK_MODEL_FILL,
  SELECT_STOCK_MODEL_FILL,
  SELECT_STOCK_MODEL_FILL_DROPDOWN_VALUE,
  STORE_STOCK_MODEL_FILL_POSITION_NAME,
  SET_VISIBLE_STOCK_MODEL_SURFACE,
  SET_VISIBLE_STOCK_MODEL_TRACK,
  SELECT__STOCK_MODEL_SURFACE,
  SET__STOCK_MODEL_TRACK_EXTENSION,
  SELECT_STOCK_MODEL_TRACK_HAS_COVERS,
  SELECT_STOCK_MODEL_TRACK_HAS_LOCKS,
  STORE_STOCK_MODEL_TRACK_LOCKS_NAME,
  SET_VISIBLE_STOCK_MODEL_SUMMARIZATION,
  SET_VISIBLE_STOCK_MODEL_FORM,
  STORE_STOCK_MODEL_RESULTS,
} from "../actions/types";

const initialState = {
  stockModel: null,
  stockModelDoors: null,
  stockModelFills: null,
  stockModelSurfaces: null,
  stockModelDoorsType: "",
  segments: [],
  stockModelFillType: "",
  stockModelSurfaceType: "",
  stockModelFillVisibility: false,
  stockModelSurfaceVisibility: false,
  stockModelTrackVisibility: false,
  stockModelSummarizationVisibility: false,
  stockModelFormVisibility: false,
  track: {
    extension: 0,
    covers: true,
    locks: false,
    locksLocation: "locks.none",
  },
  stockModelResults: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE__STOCK_MODEL:
      return {
        ...state,
        stockModel: action.stockModel,
      };

    case STORE__STOCK_MODEL_DOORS:
      return {
        ...state,
        stockModelDoors: action.stockModelDoors,
      };

    case STORE__STOCK_MODEL_FILLS:
      return {
        ...state,
        stockModelFills: action.stockModelFills,
      };

    case STORE__STOCK_MODEL_SURFACES:
      return {
        ...state,
        stockModelSurfaces: action.stockModelSurfaces,
      };

    case SELECT__STOCK_MODEL_DOORS:
      return {
        ...state,
        stockModelDoorsType: action.stockModelDoorsType,
      };

    // case SET_DOOR_SEGMENTS:
    //   return {
    //     ...state,
    //     segments: action.segments,
    //   };

    case SET_STOCK_DOOR_SEGMENTS:
      return {
        ...state,
        segments: action.segments,
      };

    case SET_VISIBLE_STOCK_MODEL_FILL:
      return {
        ...state,
        stockModelFillVisibility: true,
      };

    case SET_VISIBLE_STOCK_MODEL_SURFACE:
      return {
        ...state,
        stockModelSurfaceVisibility: true,
      };

    case SET_VISIBLE_STOCK_MODEL_TRACK:
      return {
        ...state,
        stockModelTrackVisibility: true,
      };

    case SET_VISIBLE_STOCK_MODEL_SUMMARIZATION:
      return {
        ...state,
        stockModelSummarizationVisibility: true,
      };

    case SET_VISIBLE_STOCK_MODEL_FORM:
      return {
        ...state,
        stockModelFormVisibility: true,
      };

    case SELECT_STOCK_MODEL_FILL:
      return {
        ...state,
        stockModelFillType: action.stockModelFillType,
      };

    case SELECT_STOCK_MODEL_FILL_DROPDOWN_VALUE:
      return {
        ...state,
        stockModelDropdownPosition: action.selectValue,
      };

    case STORE_STOCK_MODEL_FILL_POSITION_NAME:
      return {
        ...state,
        stockModelDropdownPositionName: action.selectValueName,
      };

    case SELECT__STOCK_MODEL_SURFACE:
      return {
        ...state,
        stockModelSurfaceType: action.stockModelSurfaceType,
      };

    case SET__STOCK_MODEL_TRACK_EXTENSION:
      return {
        ...state,
        track: {
          ...state.track,
          extension: action.extension,
        },
      };
    case SELECT_STOCK_MODEL_TRACK_HAS_COVERS:
      return {
        ...state,
        track: {
          ...state.track,
          covers: action.selectValueName,
        },
      };
    case SELECT_STOCK_MODEL_TRACK_HAS_LOCKS:
      return {
        ...state,
        track: {
          ...state.track,
          locks: action.selectValueName === "locks.none" ? false : true,
          locks_location: action.selectValueName,
        },
      };
    case STORE_STOCK_MODEL_TRACK_LOCKS_NAME:
      return {
        ...state,
        track: {
          ...state.track,
          locksName: action.selectValueName,
        },
      };

    case STORE_STOCK_MODEL_RESULTS:
      return {
        ...state,
        stockModelResults: action.storeResults,
      };

    default:
      return state;
  }
};
