import counterpart from "counterpart";
import {
  SET_TRACK_EXTENSION,
  SELECT_TRACK_HAS_COVERS,
  SELECT_TRACK_HAS_LOCKS,
  STORE_TRACK_LOCKS_NAME
} from "./types";

export const TrackActions = {
  setExtension: extension => ({
    type: SET_TRACK_EXTENSION,
    extension: extension * 10
  }),
  selectCoverValue: selectValueName => ({
    type: SELECT_TRACK_HAS_COVERS,
    selectValueName:
      selectValueName === counterpart("track.HAS_COVERS") ? true : false
  }),
  selectLocksValue: selectValueName => ({
    type: SELECT_TRACK_HAS_LOCKS,
    selectValueName
  }),
  storeLocksValueName: selectValueName => ({
    type: STORE_TRACK_LOCKS_NAME,
    selectValueName
  })
};
