import {
  SELECT_LOW_DOOR_CARD,
  SELECT_HIGH_DOOR_CARD,
  SELECT_SIDE_DOOR_CARD,
  STORE_DOORS,
  SET_FRONT_BIG_TILT_SIZE,
  SET_FRONT_SMALL_TILT_SIZE,
  SET_DOOR_SEGMENTS,
} from "../actions/types";

const initialState = {
  "doors.type.front_big": {
    type: "doors.type.front_big",
    variant: "doors.variant.none",
    position: null,
    segment: null,
    tilt_size: "",
  },
  "doors.type.front_small": {
    type: "doors.type.front_small",
    variant: "doors.variant.none",
    segment: null,
    tilt_size: "",
  },
  "doors.type.side": {
    type: "doors.type.side",
    variant: "doors.variant.none",
    position: null,
    segment: null,
    tilt_size: "",
  },
  avaiableDoors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_LOW_DOOR_CARD:
      return {
        ...state,
        "doors.type.front_small": {
          ...state["doors.type.front_small"],
          type: "doors.type.front_small",
          variant: action.cardName,
        },
      };

    case SELECT_HIGH_DOOR_CARD:
      return {
        ...state,
        "doors.type.front_big": {
          ...state["doors.type.front_big"],
          type: "doors.type.front_big",
          variant: action.cardName,
        },
      };

    case SELECT_SIDE_DOOR_CARD:
      return {
        ...state,
        "doors.type.side": {
          ...state["doors.type.side"],
          type: "doors.type.side",
          variant: action.cardName,
        },
      };

    case STORE_DOORS:
      return {
        ...state,
        avaiableDoors: action.avaiableDoors,
      };

    case SET_FRONT_BIG_TILT_SIZE:
      return {
        ...state,
        "doors.type.front_big": {
          ...state["doors.type.front_big"],
          tilt_size: action.height,
        },
      };

    case SET_FRONT_SMALL_TILT_SIZE:
      return {
        ...state,
        "doors.type.front_small": {
          ...state["doors.type.front_small"],
          tilt_size: action.height,
        },
      };

    case SET_DOOR_SEGMENTS:
      return {
        ...state,
        "doors.type.side": {
          ...state["doors.type.side"],
          segments: action.segments,
        },
      };

    default:
      return state;
  }
};
