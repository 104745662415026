import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Translate from "react-translate-component";

import { Container } from "../../../components/Container/Container";
import {
  HeadingLg,
  HeadingLgWithSideLines,
} from "../../../components/Heading/Heading";
import { SelectCard } from "../../../components/SelectCard/SelectCard";
import { Radio } from "../../../components/Form/Radio";
import { Grid, GridItem } from "../../../components/Grid/Grid";
import { HighlightText } from "../../../components/HighlightText/HighlightText";
import { Warning } from "../../../components/Warning/Warning";

export default ({
  onSelect,
  selectedType,
  avaibleRoofings,
  roofingTypeAssets,
  radioChangeHandlerRoofingVariant,
  radioValueRoofingVariant,
  myRef,
}) => {
  const dimensionWidth = useSelector((state) => state.dimension.width);
  const dimensionLength = useSelector((state) => state.dimension.length);

  return (
    <Fragment>
      <Container md style={{ display: "flex" }}>
        <Grid md style={{ paddingTop: "10rem" }} ref={myRef}>
          <HeadingLgWithSideLines>
            <HeadingLg>
              <Translate content="type.TITLE" />
            </HeadingLg>
          </HeadingLgWithSideLines>

          <Grid columns4>
            {avaibleRoofings.map((currentRoofing, index) => {
              // temporary hides all vega types and imperial high from selection
              if (
                !currentRoofing.type.includes("vega") &&
                !currentRoofing.type.includes("imperial_high")
              ) {
                return selectedType === currentRoofing.type &&
                  currentRoofing.valid ? (
                  <GridItem>
                    <SelectCard
                      onSelect={onSelect}
                      type={currentRoofing.type}
                      desc={
                        currentRoofing.inside_width !== dimensionWidth ||
                        currentRoofing.inside_length !== dimensionLength ? (
                          <Fragment>
                            <HighlightText>
                              {currentRoofing.inside_width / 10}{" "}
                            </HighlightText>{" "}
                            x{" "}
                            <HighlightText>
                              {currentRoofing.inside_length / 10}
                            </HighlightText>{" "}
                            cm <Warning />
                          </Fragment>
                        ) : (
                          <Fragment>
                            {currentRoofing.inside_width / 10} x{" "}
                            {currentRoofing.inside_length / 10} cm
                          </Fragment>
                        )
                      }
                      key={index}
                      active
                      image={
                        currentRoofing.type in roofingTypeAssets
                          ? roofingTypeAssets[currentRoofing.type].viewImg
                          : "images/roof-types/roof-type--astra.jpg"
                      }
                      lightboxId={currentRoofing.type}
                      lightboxImages={
                        roofingTypeAssets[currentRoofing.type]
                          ? "galleryImgs" in
                            roofingTypeAssets[currentRoofing.type]
                            ? roofingTypeAssets[currentRoofing.type].galleryImgs
                            : ""
                          : ""
                      }
                    >
                      {currentRoofing.type in roofingTypeAssets
                        ? roofingTypeAssets[currentRoofing.type].typeName
                        : ""}
                    </SelectCard>
                    {currentRoofing.has_variants && (
                      <Fragment>
                        <Radio
                          changed={radioChangeHandlerRoofingVariant}
                          id="3"
                          isSelected={
                            radioValueRoofingVariant === "roofing.variant.left"
                          }
                          value="roofing.variant.left"
                        >
                          <Translate content="type.LEFT_SIDE" />
                        </Radio>
                        <div style={{ marginTop: "1rem" }}>
                          <Radio
                            changed={radioChangeHandlerRoofingVariant}
                            id="4"
                            isSelected={
                              radioValueRoofingVariant ===
                              "roofing.variant.right"
                            }
                            value="roofing.variant.right"
                          >
                            <Translate content="type.RIGHT_SIDE" />
                          </Radio>
                        </div>
                      </Fragment>
                    )}
                  </GridItem>
                ) : (
                  currentRoofing.valid && (
                    <GridItem>
                      <SelectCard
                        onSelect={onSelect}
                        type={currentRoofing.type}
                        key={index}
                        desc={
                          currentRoofing.inside_width !== dimensionWidth ||
                          currentRoofing.inside_length !== dimensionLength ? (
                            <Fragment>
                              <HighlightText>
                                {currentRoofing.inside_width / 10}{" "}
                              </HighlightText>{" "}
                              x{" "}
                              <HighlightText>
                                {currentRoofing.inside_length / 10}
                              </HighlightText>{" "}
                              cm <Warning />
                            </Fragment>
                          ) : (
                            <Fragment>
                              {currentRoofing.inside_width / 10} x{" "}
                              {currentRoofing.inside_length / 10} cm{" "}
                            </Fragment>
                          )
                        }
                        image={
                          currentRoofing.type in roofingTypeAssets
                            ? roofingTypeAssets[currentRoofing.type].viewImg
                            : "images/roof-types/roof-type--astra.jpg"
                        }
                        lightboxId={currentRoofing.type}
                        lightboxImages={
                          roofingTypeAssets[currentRoofing.type]
                            ? "galleryImgs" in
                              roofingTypeAssets[currentRoofing.type]
                              ? roofingTypeAssets[currentRoofing.type]
                                  .galleryImgs
                              : ""
                            : ""
                        }
                      >
                        {currentRoofing.type in roofingTypeAssets
                          ? roofingTypeAssets[currentRoofing.type].typeName
                          : ""}
                      </SelectCard>
                    </GridItem>
                  )
                );
              } else {
                return null;
              }
            })}
          </Grid>
        </Grid>
      </Container>
      <Warning
        style={{ display: "flex", justifyContent: "center", marginTop: "7rem" }}
      >
        <Translate content="type.AVAIABLE_DIMENSIONS" />
      </Warning>
    </Fragment>
  );
};
