import React from "react";
import styled, { css } from "styled-components";
import LightboxContainer from "../Lightbox/LightboxContainer";

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18.2rem;
  padding: 2px;
  cursor: pointer;
  border: 1px solid #ababab;
  border-radius: 6px;

  img {
    width: 100%;
    height: auto;
  }
`;

const Title = styled.div`
  margin-top: 2.3rem;
  font-size: 1.8rem;
  color: #4e4e4e;
  text-align: center;
`;

const Desc = styled.div`
  margin-top: 0.6rem;
  font-size: 1.4rem;
  color: #969696;
  text-align: center;
`;

const IconBtnWrapper = styled.div`
  margin-top: 0.9rem;
  text-align: center;
`;

const CardWrapper = styled.div`
  width: 100%;
  max-width: 18.2rem;
  margin: 1.5rem 0;

  ${(props) =>
    props.lg &&
    css`
      max-width: 22.5rem;

      ${ImgWrapper} {
        height: 22.5rem;
        padding: 1rem;

        img {
          border-radius: 6px;
        }
      }

      ${Title} {
        margin-top: 2.9rem;
      }

      ${IconBtnWrapper} {
        margin-top: 1.8rem;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      ${ImgWrapper} {
        img {
          opacity: 0.3;
        }
      }
    `}

  ${(props) =>
    props.active &&
    css`
      ${ImgWrapper} {
        border: 3px solid #4a90e2;
        box-shadow: 0 3px 9px 4px rgba(0, 0, 0, 0.12);
      }

      ${Title} {
        font-weight: 600;
        color: #4a90e2;
      }

      ${Desc} {
        color: #4a90e2;
      }
    `}

  &:hover {
    ${ImgWrapper} {
      border-color: #4a90e2;
      box-shadow: 0 3px 9px 4px rgba(0, 0, 0, 0.12);
    }

    ${Title},
    ${Desc} {
      color: #4a90e2;
    }
  }
`;

export function SelectCard({
  children,
  onSelect,
  type,
  disabled,
  active,
  lg,
  image,
  desc,
  lightboxId,
  lightboxImages,
}) {
  return (
    <CardWrapper disabled={disabled} active={active} lg={lg}>
      <ImgWrapper onClick={onSelect} data-card-name={type}>
        <img src={image} />
      </ImgWrapper>
      <Title>{children}</Title>
      <Desc>{desc}</Desc>
      <IconBtnWrapper>
        <LightboxContainer
          lightboxId={lightboxId}
          lightboxImages={lightboxImages}
        />
      </IconBtnWrapper>
    </CardWrapper>
  );
}
