import React from "react";
import { connect } from "react-redux";

import { LightboxActions } from "../../actions/lightboxActions";
import Lightbox from "./Lightbox";

const LightboxContainer = ({
  activeLightboxName,
  lightboxId,
  currentLightbox,
  lightboxImages
}) => {
  const toggleLightbox = e => {
    currentLightbox(e.currentTarget.id);
  };

  return (
    <Lightbox
      toggleLightbox={toggleLightbox}
      activeLightboxName={activeLightboxName}
      lightboxId={lightboxId}
      currentLightbox={currentLightbox}
      lightboxImages={lightboxImages}
    />
  );
};

const mapState = state => ({
  activeLightboxName: state.lightbox.activeLightboxName
});

const mapDispatch = dispatch => ({
  currentLightbox: lightboxId =>
    dispatch(LightboxActions.openLightbox(lightboxId))
});

export default connect(mapState, mapDispatch)(LightboxContainer);
