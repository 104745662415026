import React from "react";
import counterpart from "counterpart";
import Translate from "react-translate-component";

import { Container } from "../../../components/Container/Container";
import { HeadingLg } from "../../../components/Heading/Heading";
import { InputText } from "../../../components/Form/InputText";
import { FormLabel } from "../../../components/Form/FormLabel";
import { Warning } from "../../../components/Warning/Warning";
import { Button } from "../../../components/Button/Button";

//TODO: presunout nekam vys, do konfigurace nebo do reduceru
const minWidth = 260;
const maxWidth = 800;
const minLength = 428;
const maxLength = 1702;

export default ({
  width,
  length,
  setWidth,
  setLength,
  dimensionsValidity,
  showTypes,
  storeRoofings,
  roofingVisibility,
  executeScroll,
}) => {
  return (
    <Container lg style={{ display: "flex" }}>
      <Container sm style={{ maxWidth: "52.1rem", paddingTop: "7rem" }}>
        <HeadingLg center>
          <Translate content="dimensions.TITLE" />
        </HeadingLg>
        <img
          src={`images/dimensions/${counterpart("dimensions.IMG")}.png`}
          style={{ marginTop: "8rem", marginBottom: "2rem" }}
        />

        <div
          style={{
            display: "flex",
            marginTop: "4rem",
            marginBottom: "5.6rem",
          }}
        >
          <div style={{ flex: "1" }}>
            <FormLabel>
              <Translate content="dimensions.WIDTH" />
            </FormLabel>
            <InputText
              type="number"
              value={width}
              min={minWidth}
              max={maxWidth}
              invalid={
                (width && width < minWidth) ||
                (width && width > maxWidth) ||
                width === 0
              }
              onChange={(e) => setWidth(e.target.value)}
            />
            <div
              style={{
                marginTop: "1rem",
                color: "#969696",
                fontSize: "1.4rem",
              }}
            >
              {minWidth} - {maxWidth} cm <br />{" "}
              <Translate content="dimensions.MAX_WIDTH" />:{" "}
              <span style={{ color: "#4e4e4e" }}>
                {width > minWidth && width < maxWidth ? width - 30 : 0}
              </span>{" "}
              cm
            </div>
          </div>

          <div style={{ flex: "1", paddingLeft: "2.5rem" }}>
            <FormLabel>
              <Translate content="dimensions.LENGTH" />
            </FormLabel>
            <InputText
              type="number"
              value={length}
              min={minLength}
              max={maxLength}
              invalid={
                (length && length < minLength) ||
                (length && length > maxLength) ||
                length === 0
              }
              onChange={(e) => setLength(e.target.value)}
            />
            <div
              style={{
                marginTop: "1rem",
                color: "#969696",
                fontSize: "1.4rem",
              }}
            >
              {minLength} - {maxLength} cm <br />{" "}
              <Translate content="dimensions.MAX_LENGTH" />:{" "}
              <span style={{ color: "#4e4e4e" }}>
                {length > minLength && length < maxLength ? length - 30 : 0}
              </span>{" "}
              cm
            </div>
          </div>
        </div>
        <Warning>
          <Translate content="dimensions.DISTANCE" />
        </Warning>
        <Warning>
          <Translate content="dimensions.EDGE_HEIGHT" />
        </Warning>
        <div style={{ textAlign: "center", marginTop: "6rem" }}>
          {dimensionsValidity && !roofingVisibility && (
            <Button
              onClick={async () => {
                const res = await fetch(
                  `${
                    window.location.hostname === "localhost"
                      ? "http://mountfield.test.pink-future.cz"
                      : ""
                  }/mc/api/roofings?locale=cs_CZ&width=${width * 10}&length=${
                    length * 10
                  }`
                );
                const data = await res.json();
                storeRoofings(data);

                dimensionsValidity && showTypes(true);
                executeScroll();
              }}
            >
              <Translate content="dimensions.SUBMIT" />
            </Button>
          )}
        </div>
      </Container>
    </Container>
  );
};
