import React, { Fragment } from "react";
import { connect } from "react-redux";
import counterpart from "counterpart";

import { RoofingActions } from "../../../actions/roofingActions";
import { DoorsActions } from "../../../actions/doorsActions";
import { CalculationResultsActions } from "../../../actions/calculationResultsAction";
import Roofing from "./Roofing";

const RoofingContainer = ({
  selectCard,
  state,
  storeResults,
  storeDoors,
  selectRoofingVariant,
  variant,
  myRef,
  executeScroll,
  roofing: { type, roofings },
}) => {
  const roofingTypeAssets = {
    ["roofing.type.vega_330"]: {
      typeName: counterpart("type.VEGA_330"),
      viewImg: "images/roof-types/roof-type--vega.jpg",
      galleryImgs: [{ src: "images/gallery/roofing/vega/vega.jpg" }],
    },
    ["roofing.type.vega_380"]: {
      typeName: counterpart("type.VEGA_380"),
      viewImg: "images/roof-types/roof-type--vega.jpg",
      galleryImgs: [{ src: "images/gallery/roofing/vega/vega.jpg" }],
    },
    ["roofing.type.vega_430"]: {
      typeName: counterpart("type.VEGA_430"),
      viewImg: "images/roof-types/roof-type--vega.jpg",
      galleryImgs: [{ src: "images/gallery/roofing/vega/vega.jpg" }],
    },
    ["roofing.type.vega_450"]: {
      typeName: counterpart("type.VEGA_450"),
      viewImg: "images/roof-types/roof-type--vega.jpg",
      galleryImgs: [{ src: "images/gallery/roofing/vega/vega.jpg" }],
    },

    ["roofing.type.compact_low"]: {
      typeName: counterpart("type.COMPACT_LOW"),
      viewImg: "images/roof-types/roof-type--compact-low.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/compact-low/compact-low-anth-4mm.jpg" },
        { src: "images/gallery/roofing/compact-low/compact-low-anth-10mm.jpg" },
        { src: "images/gallery/roofing/compact-low/compact-low-10mm-4mm.jpg" },
        {
          src: "images/gallery/roofing/compact-low/compact-lox-10mm-4mm-2.jpg",
        },
        { src: "images/gallery/roofing/compact-low/compact-low-bronz.jpg" },
        { src: "images/gallery/roofing/compact-low/compact-elox.jpg" },
        {
          src: "images/gallery/roofing/compact-low/compact-low-broz-smoked.jpg",
        },
      ],
    },
    ["roofing.type.compact_high"]: {
      typeName: counterpart("type.COMPACT_HIGH"),
      viewImg: "images/roof-types/roof-type--compact-high.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/compact-high/compact-high-1.jpg" },
      ],
    },

    ["roofing.type.apollo_straight"]: {
      typeName: counterpart("type.APOLLO_STRAIGHT"),
      viewImg: "images/roof-types/roof-type--apolo-straight.jpg",
      galleryImgs: [
        {
          src: "images/gallery/roofing/apollo-straight/apollo-straight-bronz-smoked.jpg",
        },
        {
          src: "images/gallery/roofing/apollo-straight/apollo-straight-bronz-smoked2.jpg",
        },
        {
          src: "images/gallery/roofing/apollo-straight/apollo-straight-elox.jpg",
        },
        {
          src: "images/gallery/roofing/apollo-straight/apollo-straight-smoked3.jpg",
        },
      ],
    },

    ["roofing.type.astra"]: {
      typeName: counterpart("type.ASTRA"),
      viewImg: "images/roof-types/roof-type--astra.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/astra/astra-anthracite.jpg" },
        { src: "images/gallery/roofing/astra/astra-anthracite-1.jpg" },
        { src: "images/gallery/roofing/astra/astra-anthracite-2.jpg" },
        { src: "images/gallery/roofing/astra/astra-elox.jpg" },
        { src: "images/gallery/roofing/astra/astra-elox-2.jpg" },
      ],
    },

    ["roofing.type.imperial_low"]: {
      typeName: counterpart("type.IMPERIAL_LOW"),
      viewImg: "images/roof-types/roof-type--imperial-low.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/imperial-low/imperial-low.jpg" },
        { src: "images/gallery/roofing/imperial-low/imperial-low-2.jpg" },
      ],
    },

    ["roofing.type.imperial_medium"]: {
      typeName: counterpart("type.IMPERIAL_MEDIUM"),
      viewImg: "images/roof-types/roof-type--imperial.jpg",
      galleryImgs: [
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-anth-4mm.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-anth-4mm-2.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-anth-4mm2.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-anth-4mm3.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-anthracit-4mm.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-anth-smoked.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-elox-clear-4mm.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-elox-clear-4mm2.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-elox-clear-4mm3.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-medium/imperial-medium-elox-polycarbonate-10-mm.jpg",
        },
      ],
    },

    ["roofing.type.imperial_high"]: {
      typeName: counterpart("type.IMPERIAL_HIGH"),
      viewImg: "images/roof-types/roof-type--imperial-high.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/imperial-high/imperial-high-bronz.jpg" },
        {
          src: "images/gallery/roofing/imperial-high/imperial-high-bronz2.jpg",
        },
        {
          src: "images/gallery/roofing/imperial-high/imperial-high-bronz3.jpg",
        },
        { src: "images/gallery/roofing/imperial-high/imperial-high-elox.jpg" },
      ],
    },

    ["roofing.type.apollo_oblique"]: {
      typeName: counterpart("type.APOLLO_OBLIQUE"),
      viewImg: "images/roof-types/roof-type--apolo-oblique.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/apollo-oblique/apollo-oblique.jpg" },
        { src: "images/gallery/roofing/apollo-oblique/apollo-oblique1-1.jpg" },
        { src: "images/gallery/roofing/apollo-oblique/apollo-oblique2.jpg" },
        { src: "images/gallery/roofing/apollo-oblique/apollo-oblique3.jpg" },
        { src: "images/gallery/roofing/apollo-oblique/apollo-oblique4.jpg" },
        { src: "images/gallery/roofing/apollo-oblique/apollo-oblique5.jpg" },
        { src: "images/gallery/roofing/apollo-oblique/apollo-oblique-2.jpg" },
        {
          src: "images/gallery/roofing/apollo-oblique/apollo-oblique-clear-4mm.jpg",
        },
      ],
    },

    ["roofing.type.trend_increased"]: {
      typeName: counterpart("type.TREND_INCREASED"),
      viewImg: "images/roof-types/roof-type--trend-increased.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/trend-increased/trend-increased.jpg" },
        { src: "images/gallery/roofing/trend-increased/trend-increased2.jpg" },
      ],
    },

    ["roofing.type.trend_maximum"]: {
      typeName: counterpart("type.TREND_MAXIMUM"),
      viewImg: "images/roof-types/roof-type--trend-high.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/trend-maximum/trend-maximum.jpg" },
        { src: "images/gallery/roofing/trend-maximum/trend-maximum2.jpg" },
        { src: "images/gallery/roofing/trend-maximum/trend-maximum3.jpg" },
      ],
    },

    ["roofing.type.trend_standard"]: {
      typeName: counterpart("type.TREND"),
      viewImg: "images/roof-types/roof-type--trend-standard.jpg",
      galleryImgs: [
        { src: "images/gallery/roofing/trend-standard/trend-3-elox.jpg" },
        { src: "images/gallery/roofing/trend-standard/trend-bronz2.jpg" },
        { src: "images/gallery/roofing/trend-standard/trend-standard.jpg" },
        {
          src: "images/gallery/roofing/trend-standard/trend-standard-bronz.jpg",
        },
        {
          src: "images/gallery/roofing/trend-standard/trend-standard-elox.jpg",
        },
      ],
    },
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");
  const locale = urlParams.get("locale");

  const onSelect = async (e) => {
    const cardName = e.currentTarget.dataset.cardName;
    selectCard(cardName);

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/configuration/update?mid=${mid}&locale=${locale}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dimension: { ...state.dimension },
          roofing: {
            ...state.roofing,
            type: cardName,
          },
        }),
      }
    );
    const data = await res.json();
    storeResults(data);

    const doorsRes = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/doors?locale=cz&roofingType=${cardName}`
    );
    const avaiableDoors = await doorsRes.json();
    storeDoors(avaiableDoors);

    const currentCard = roofings.find((el) => el.type === cardName);
    if (!currentCard.has_variants) {
      executeScroll();
    }
  };

  const roofingVariantChangeHandler = (event) => {
    selectRoofingVariant(event.target.value);
    executeScroll();
  };

  return (
    <Fragment>
      <Roofing
        onSelect={onSelect}
        selectedType={type}
        roofingTypeAssets={roofingTypeAssets}
        avaibleRoofings={roofings}
        radioChangeHandlerRoofingVariant={roofingVariantChangeHandler}
        radioValueRoofingVariant={variant}
        myRef={myRef}
      />
    </Fragment>
  );
};

const mapState = (state) => ({
  roofing: state.roofing,
  state: state,
  variant: state.roofing.variant,
});

const mapDispatch = (dispatch) => ({
  selectCard: (selectCardName) =>
    dispatch(RoofingActions.selectTypeCard(selectCardName)),
  storeResults: (results) =>
    dispatch(CalculationResultsActions.storeResults(results)),
  storeDoors: (avaiableDoors) =>
    dispatch(DoorsActions.storeDoors(avaiableDoors)),
  selectRoofingVariant: (radioValue) =>
    dispatch(RoofingActions.selectRoofingVariant(radioValue)),
});

export default connect(mapState, mapDispatch)(RoofingContainer);
