import React, { Fragment, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import Translate from "react-translate-component";

import { Container } from "../../../components/Container/Container";
import { HeadingMd, HeadingSm } from "../../../components/Heading/Heading";
import { SelectCard } from "../../../components/SelectCard/SelectCard";
import { InputText } from "../../../components/Form/InputText";
import { Radio } from "../../../components/Form/Radio";
import { Warning } from "../../../components/Warning/Warning";
import { Grid, GridItem } from "../../../components/Grid/Grid";
import { SelectSegments } from "../../../components/SelectSegments/SelectSegments";
import { StockModelsActions } from "../../../actions/stockModelsActions";
import { withRouter } from "react-router-dom";

const DoorsStockModel = ({
  onDoorsSelect,
  selectedCardStockDoors,

  storeStockModelResults,

  onSelectSideDoors,
  stockModel,
  stockModelDoorCardName,
  segmentData,
  segmentType2Data,
  segmentRadioChangeHandler,
  segmentRadioValue,
  segmentRadioReset,

  doorsAssets,
  storeStockModelDoors,
}) => {
  const state = useSelector((state) => state);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");
  const locale = urlParams.get("locale");

  const avaiableStockModelDoors = useSelector(
    (state) => state.stockModels.stockModelDoors
  );

  useEffect(() => {
    async function fetchStockModelDoorsData() {
      const res = await fetch(
        `${
          window.location.hostname === "localhost"
            ? "http://mountfield.test.pink-future.cz"
            : ""
        }/mc/api/stock-models/${stockModel.id}/door-variants?locale=cs_CZ`
      );
      const data = await res.json();
      storeStockModelDoors(data);
    }
    fetchStockModelDoorsData();

    async function fetchStockModel() {
      const res = await fetch(
        `${
          window.location.hostname === "localhost"
            ? "http://mountfield.test.pink-future.cz"
            : ""
        }/mc/api/stock-models/${
          stockModel.id
        }/configuration-update?locale=${locale}&mid=${mid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dimension: { ...state.dimension },
            roofing: {
              type:
                state.stockModels.stockModel !== null &&
                state.stockModels.stockModel.id,
            },
            filling: {
              type: "filling.type.classic",
            },
            surface: {
              type: "color.type.elox",
            },
          }),
        }
      );
      const data = await res.json();
      storeStockModelResults(data);
    }
    fetchStockModel();
  }, []);

  const segmentsNumber = useSelector(
    (state) =>
      state.stockModels.stockModelResults &&
      state.stockModels.stockModelResults.roofing.segment_quantity
  );

  return (
    <Container lg>
      <Grid columns3>
        {avaiableStockModelDoors &&
          avaiableStockModelDoors.map((doorVariant) => {
            doorVariant = doorVariant[0];
            if (doorVariant.id === "doors.variant.without_threshold") {
              return (
                <GridItem>
                  {selectedCardStockDoors ===
                  "doors.variant.without_threshold" ? (
                    <SelectCard
                      lg
                      image="images/doors/without-threshold.jpg"
                      onSelect={onDoorsSelect}
                      type="doors.variant.without_threshold"
                      lightboxId="big_doors.variant.without_threshold"
                      lightboxImages={doorsAssets.withoutThreshold.galleryImgs}
                      active
                    >
                      <Translate content="doors.big_brow.WITHOUT_THRESHOLD" />{" "}
                    </SelectCard>
                  ) : (
                    <SelectCard
                      lg
                      image="images/doors/without-threshold.jpg"
                      onSelect={onDoorsSelect}
                      type="doors.variant.without_threshold"
                      lightboxId="big_doors.variant.without_threshold"
                      lightboxImages={doorsAssets.withoutThreshold.galleryImgs}
                    >
                      <Translate content="doors.big_brow.WITHOUT_THRESHOLD" />
                    </SelectCard>
                  )}
                </GridItem>
              );
            } else if (doorVariant.id === "doors.variant.up_sliding") {
              return (
                <GridItem>
                  {stockModelDoorCardName === "doors.variant.up_sliding" ? (
                    <SelectCard
                      lg
                      image="images/doors/doors.variant.up_sliding.jpg"
                      onSelect={onDoorsSelect}
                      type="doors.variant.up_sliding"
                      active
                      lightboxId="doors.variant.up_sliding"
                      lightboxImages={doorsAssets.upSliding.galleryImgs}
                    >
                      <Translate content="doors.side_doors.SLIDING_DOOR" />
                    </SelectCard>
                  ) : (
                    <SelectCard
                      lg
                      image="images/doors/doors.variant.up_sliding.jpg"
                      onSelect={onDoorsSelect}
                      type="doors.variant.up_sliding"
                      lightboxId="doors.variant.up_sliding"
                      lightboxImages={doorsAssets.upSliding.galleryImgs}
                    >
                      <Translate content="doors.side_doors.SLIDING_DOOR" />
                    </SelectCard>
                  )}
                </GridItem>
              );
            }
          })}
      </Grid>
      {stockModelDoorCardName === "doors.variant.up_sliding" && (
        <Fragment>
          <HeadingMd style={{ textAlign: "center" }}>
            <Translate content="doors.side_doors.SHUNTING_DOOR_LABEL" />
          </HeadingMd>

          <SelectSegments
            segmentsData={segmentType2Data}
            segmentsNumber={segmentsNumber}
            radioChangeHandler={segmentRadioChangeHandler}
            radioValue={segmentRadioValue}
            segmentRadioReset={segmentRadioReset}
            stockModel={stockModel}
          />
        </Fragment>
      )}
    </Container>
  );
};

const mapDispatch = (dispatch) => ({
  storeStockModelResults: (results) =>
    dispatch(StockModelsActions.storeStockModelResults(results)),
  storeStockModelDoors: (stockModelDoors) =>
    dispatch(StockModelsActions.storeStockModelDoors(stockModelDoors)),
});

export default withRouter(connect(null, mapDispatch)(DoorsStockModel));
