import { SET_CALCULATION_STATE } from "../actions/types";

const initialState = {
  calculation_state: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CALCULATION_STATE:
      return {
        ...state,
        calculation_state: action.calculationState
      };
    default:
      return state;
  }
};
