import styled from "styled-components";

export const FormLayoutWidth100 = styled.div`
  width: 100%;
`;

export const FormLayoutWidth75 = styled.div`
  width: 75%;
`;

export const FormLayoutWidth50 = styled.div`
  width: 50%;
`;

export const FormLayoutWidth25 = styled.div`
  width: 25%;
`;

export const FormLayoutRow = styled.div`
  display: flex;
  margin: 2.9rem 0;

  & > :not(:first-child) {
    margin-left: 2rem;
  }

  & > :only-child:not(${FormLayoutWidth100}) {
    padding-right: 1rem;
  }
`;
