import React from "react";
import { useSelector } from "react-redux";
import Translate from "react-translate-component";

import { Container } from "../../../components/Container/Container";

const Summarization = ({ myRef, resultData, fillingPosition }) => {
  const state = useSelector((state) => state);

  const doorTypeFrontBig = resultData.doors.filter((door) => {
    return door.type === "doors.type.front_big";
  });

  const doorTypeFrontSmall = resultData.doors.filter((door) => {
    return door.type === "doors.type.front_small";
  });

  const doorTypeSide = resultData.doors.filter((door) => {
    return door.type === "doors.type.side";
  });

  return (
    <Container lg style={{ display: "flex" }} ref={myRef}>
      <Container md>
        <div className="configuration-wrapper">
          <div className="configuration-left">
            <div>
              <h2 className="configuration-heading">
                <Translate content="summarization.DIMENSIONS" />
              </h2>
              <ul className="configuration-list">
                <li className="configuration-li">
                  <div className="configuration-li__key">Typ</div>
                  <div className="configuration-li__value">
                    {resultData.roofing.translation || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.INNER_WIDTH" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.roofing.inside_width / 10 || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.INNER_LENGTH" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.roofing.inside_length / 10 || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.OUTER_LENGTH" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.roofing.outside_length / 10 || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.OUTER_WIDTH" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.roofing.outside_width / 10 || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.SEGMENTS" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.roofing.segment_quantity || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.MIN_INNER_HEIGHT" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.roofing.min_inside_height / 10 || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.MAX_OUTER_HEIGHT" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.roofing.max_outside_height / 10 || ""}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="configuration-heading">
                <Translate content="summarization.FILLING_SURFACE" />
              </h2>
              <ul className="configuration-list">
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.FILLING" />
                  </div>
                  <div className="configuration-li__value">
                    {`${resultData.filling.translation} - ${
                      fillingPosition || ""
                    }` || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.SURFACE" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.surface.translation || ""}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="configuration-right">
            <div>
              <h2 className="configuration-heading">
                <Translate content="summarization.DOORS_TYPE" />
              </h2>
              <ul className="configuration-list">
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.DOORS_BIG" />
                  </div>
                  <div className="configuration-li__value">
                    {doorTypeFrontBig.length > 0
                      ? doorTypeFrontBig[0].variant_translation +
                        `${
                          doorTypeFrontBig[0]?.tilt_size
                            ? " - " +
                              doorTypeFrontBig[0]?.tilt_size / 10 +
                              " cm"
                            : ""
                        }`
                      : ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.DOORS_BIG_SLIDING_THRESHOLD" />
                  </div>
                  <div className="configuration-li__value">
                    {doorTypeFrontBig.length > 0 ? (
                      doorTypeFrontBig[0].sliding_threshold ? (
                        <Translate content="track.HAS_COVERS" />
                      ) : (
                        <Translate content="track.NO_COVERS" />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.DOORS_SMALL" />
                  </div>
                  <div className="configuration-li__value">
                    {doorTypeFrontSmall.length > 0
                      ? doorTypeFrontSmall[0].variant_translation
                      : ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.DOORS_SMALL_SLIDING_THRESHOLD" />
                  </div>
                  <div className="configuration-li__value">
                    {doorTypeFrontSmall.length > 0 ? (
                      doorTypeFrontSmall[0].sliding_threshold ? (
                        <Translate content="track.HAS_COVERS" />
                      ) : (
                        <Translate content="track.NO_COVERS" />
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.DOORS_SIDE" />
                  </div>
                  <div className="configuration-li__value">
                    {doorTypeSide.length > 0
                      ? doorTypeSide[0].variant_translation
                      : ""}
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="configuration-heading">
                <Translate content="summarization.TRACK" />
              </h2>
              <ul className="configuration-list">
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.TRACK_TYPE" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.track.bi_directional ? (
                      <Translate content="track.TYPE_DOUBLE_SIDED" />
                    ) : (
                      <Translate content="track.TYPE_SINGLE_SIDED" />
                    )}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.TRACK_WIDTH" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.track.width / 10 || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.TRACK_LENGTH" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.track.length / 10 || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.LOCKS" />
                  </div>
                  <div className="configuration-li__value">
                    {state.track.locks_location === "locks.location.left" ? (
                      <Translate content="track.LOCKS_LEFT" />
                    ) : state.track.locks_location ===
                      "locks.location.right" ? (
                      <Translate content="track.LOCKS_RIGHT" />
                    ) : (
                      <Translate content="track.NO_LOCKS" />
                    )}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.TRACK_EXTENSION" />
                  </div>
                  <div className="configuration-li__value">
                    {resultData.track.extension / 10 || ""}
                  </div>
                </li>
                <li className="configuration-li">
                  <div className="configuration-li__key">
                    <Translate content="summarization.TRACK_COVERS" />
                  </div>

                  <div className="configuration-li__value">
                    {resultData.track.covers ? (
                      <Translate content="track.HAS_COVERS" />
                    ) : (
                      <Translate content="track.NO_COVERS" />
                    )}
                  </div>
                </li>
              </ul>
            </div>

            {resultData?.price ? (
              <div>
                <h2 className="configuration-heading">
                  <Translate content="summarization.PRICE" />
                </h2>
                <ul className="configuration-list">
                  <li className="configuration-li">
                    <div className="configuration-li__key">
                      <Translate content="summarization.CONFIG" />
                    </div>
                    <div className="configuration-li__value">
                      € {resultData?.price.toFixed(1) || ""}
                    </div>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default Summarization;
