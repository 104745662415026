import React from "react";
import styled from "styled-components";

const FooterPoweredBy = styled.div`
  font-size: 1.4rem;
  color: #a8a8a8;
  text-align: right;
`;

const FooterWrapper = styled.footer`
  position: relative;
  padding: 3.5rem 5.5rem;
`;

export function Footer({ configResults }) {
  function getCurrentYear() {
    const now = new Date();
    return now.getFullYear();
  }

  return (
    <FooterWrapper>
      {configResults?.show_copyright_info ? (
        <FooterPoweredBy>
          Powered by Mountfield {getCurrentYear()}
        </FooterPoweredBy>
      ) : null}
    </FooterWrapper>
  );
}
