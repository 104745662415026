export default {
  dimensions: "Abmessungen",
  type: "Typ",
  filling: "Deckmaterial",
  surface: "Farbe",
  track: "Schienen",
  doors: "Türspezifikation",
  summarization: "Zusammenfassung",
  form: "Senden einer Anfrage",
  login: "Login",
  mid_alert:
    "Konfigurator kann nicht gestartet werden, Partnereinstellungen fehlen",
  config_completed: {
    TITLE: "Vielen Dank!",
    DESC: "Die Einreichung war erfolgreich. Wir senden Ihnen eine Zusammenfassung der Bestellung mit weiteren Informationen per E-Mail. Danke und einen schönen Tag.",
    BTN: "Eine neue Konfiguration Starten",
  },
  dimensions: {
    TITLE: "Maße der Überdachung",
    DISTANCE:
      "Der Mindestabstand der Schienen vom Beckenrand beträgt 15cm auf jeder Seite des Beckens.",
    EDGE_HEIGHT:
      "Die Beckenrandhöhe über dem Niveau der Pflasterung beträgt maximal 10cm. ",
    IMG: "dimensions-img--de",
    LENGTH: "Länge der Überdachung",
    WIDTH: "Breite der Überdachung",
    MAX_WIDTH: "Max. Breite des Pools",
    MAX_LENGTH: "Max. Länge des Pools",
    SUBMIT: "Weiterführen",
  },
  type: {
    TITLE: "Wählen Sie die gewünschte Bauform",
    AVAIABLE_DIMENSIONS:
      "Die nächstmögliche Abmessung ist etwas höher als die eingegebenen Daten",
    LEFT_SIDE: "Linke Variante",
    RIGHT_SIDE: "Richtige Variante",
    TREND: "Trend",
    TREND_INCREASED: "Trend increased",
    TREND_MAXIMUM: "Trend maximum",
    COMPACT_LOW: "Compact low",
    COMPACT_HIGH: "Compact high",
    ASTRA: "Astra",
    IMPERIAL_LOW: "Imperial low",
    IMPERIAL_MEDIUM: "Imperial medium",
    IMPERIAL_HIGH: "Imperial high",
    APOLLO_OBLIQUE: "Apollo Oblique",
    APOLLO_STRAIGHT: "Apollo Straight",
    VEGA_330: "Vega 330",
    VEGA_380: "Vega 380",
    VEGA_430: "Vega 430",
    VEGA_450: "Vega 450",
  },
  filling: {
    TITLE: "Verglasung",
    POLYCARBONATE: "Hohlkammerplatten 10 mm Polycarbonat ",
    SMOKED_POLYCARBONATE: "Hohlkammerplatten 10 mm getöntes Polycarbonat ",
    CLEAR_POLYCARBONATE: "Klarglasoptik 4 mm klares Polycarbonat",
    SPECIFY: "Spezifizieren",
    SELECT: "Auswahl",
    WHOLE: "Die gesamte Verglasung",
    BROWS: "Nur Stirnwände",
    SMALL_BROW: "Kleine Stirnwand",
    BIG_BROW: "Große Stirnwand",
    SEGMENTS: "Nur Segmente",
  },
  surface: {
    TITLE: "Farbe",
    ELOX: "Elox",
    ANTHRACITE: "Anthracite (RAL 7016)",
    BRONZE: "Bronze",
  },
  track: {
    TITLE: "Schienen",
    TYPE: "roofing.track.TYPE",
    TYPE_DOUBLE_SIDED: "Doppelseitig",
    TYPE_SINGLE_SIDED: "Einseitig",
    EXTENSION_LABEL: "Schienenverlängerung",
    TOTAL_EXTENSTION: "Gesamterweiterung",
    EXTENSION_DESC:
      "Im Preis enthalten sind beidseitige Schienenverlängerungen mit einer Länge von 210cm. Wünschen Sie eine zusätzliche Verlängerung, geben Sie hier bitte die zusätzliche Länge ein.",
    COVERS_LABEL: "Abdeckungen",
    COVERS_IMG: "covers-img--de",
    WITHOUT_COVERS: "Ohne Abdeckungen",
    WITH_COVERS: "Mit Abdeckungen",
    HAS_COVERS: "Ja",
    NO_COVERS: "Nein",
    COVERS_DESC:
      "Abdeckungen dienen zum Abdecken von Schrauben und zum bequemen Überlaufen von Schienen.",
    LOCKS_LABEL: "Segmentschlösser",
    LOCKS_IMG: "locks-img--de",
    NO_LOCKS: "Nein",
    LOCKS_LEFT: "Ja, auf der linken Seite",
    LOCKS_RIGHT: "Ja, auf der rechten Seite",
    LOCKS_DESC:
      "Um zu verhindern, dass sich Segmente (z.B. durch Wind) verschieben, können diese separat abgesperrt werden. Wählen Sie bitte hier die entsprechende Seite auf der die optionalen Schlösser angebracht werden sollen.",
  },
  doors: {
    TITLE: "Türen oder Klappen",
    big_brow: {
      TITLE: "TÜREN ODER KLAPPEN IN DER GROSSEN STIRNWAND",
      NO_DOORS: "Keine Türen",
      FLAP: "Klappe",
      FLAP_LABEL: "Gewünschte Höhe eingeben",
      FLAP_DESC: "Mindestens 25 cm - Maximal 1/3 der Stirnhöhe",
      DOORS: "Türen",
      WITHOUT_THRESHOLD: "Ohne Türschwelle",
      WITH_THRESHOLD: "Mit Türschwelle",
      THRESHOLD_DESC:
        "Bewegliche Schwelle, die hochgezogen werden kann, um höhere Objekte beim Schließen zu überwinden. (eine Leiter zum Beispiel)",
    },
    small_brow: {
      TITLE: "TÜREN ODER KLAPPEN IN DER KLEINEN STIRNWAND",
      NO_DOORS: "Keine Türen",
      FLAP: "Klappe",
      FLAP_DESC:
        "Bewegliches Verschuss, das hochgezogen werden kann, um höhere Objekte beim Schließen zu überwinden. (eine Leiter zum Beispiel)",
      DOORS: "Türen",
      WITHOUT_THRESHOLD: "Ohne Türschwelle",
      WITH_THRESHOLD: "Mit Türschwelle",
      THRESHOLD_DESC:
        "Schwenkbares Element, das nach oben geklappt werden kann, um beim Schließen der Überdachung, höhere Objekte, wie z.B. eine Leiter, zu überwinden.",
    },
    side_doors: {
      TITLE: "Seiten Türen",
      NO_DOORS: "keine Türen",
      SHUNTING_DOOR: "Schiebetüren (zur Seite verschiebbar)",
      SHUNTING_DOOR_LABEL: "Spezifizieren Sie die Seite und Position",
      SHUNTING_DESC:
        "Türen können nur im größten Segment platziert werden. Wählen Sie die Seite der Tür.",
      SLIDING_DOOR: "Schiebetüren (nach oben verschiebbar)",
    },
  },
  CALCULATE: "Kalkulation",
  summarization: {
    TITLE: "Zusammenfassung",
    DIMENSIONS: "Abmessungen und Typ",
    INNER_WIDTH: "Kuppelbreite innen",
    INNER_LENGTH: "Kuppellänge innen",
    OUTER_LENGTH: "Kuppellänge außen",
    OUTER_WIDTH: "Kuppelbreite außen",
    SEGMENTS: "Anzahl der Segmente ",
    MIN_INNER_HEIGHT: "Minimale Innenhöhe",
    MAX_OUTER_HEIGHT: "Maximale Innenhöhe",
    FILLING_SURFACE: "Füllstoff und Farbe",
    FILLING: "Verglasung",
    SURFACE: "Farbe",
    DOORS_TYPE: "Türtypen",
    DOORS_BIG: "Türen in der großen Stirnwand",
    DOORS_BIG_SLIDING_THRESHOLD: "Große Stirnwand – schwenkbare Klappe",
    DOORS_SMALL: "Türen in der kleinen Stirnwand",
    DOORS_SMALL_SLIDING_THRESHOLD: "Kleine Stirnwand – schwenkbare Klappe",
    DOORS_SIDE: "Seitentüren",
    TRACK: "Schienen",
    TRACK_TYPE: "Spurtyp",
    TRACK_WIDTH: "Schienenbreite",
    TRACK_LENGTH: "Streckenlänge",
    LOCKS: "Segmentverriegelungen",
    TRACK_EXTENSION: "Verlängerung der Schienen",
    TRACK_COVERS: "Abdeckungen",
    PRICE: "Preis",
    CONFIG: "Konfiguration",
    STOCK_MODEL: "Lagermodell",
  },
  form: {
    TITLE: "Anfrage absenden",
    DESC: "Die Anfrage ist unverbindlich. Wir werden uns dann umgehend bei Ihnen melden.",
    EMAIL: "E-Mail",
    TEL: "Telefonnummer",
    FIRST_NAME: "Name",
    SURNAME: "Nachname",
    ADDRESS_DESC:
      "Um die Transportkosten zu erhalten, geben Sie bitte Ihre genaue Adresse ein. (Optional)",
    STREET: "Straße",
    HOUSE_NUMBER: "Hausnummer",
    CITY: "Stadt",
    POSTAL_CODE: "Postleitzahl",
    COUNTRY: "Land",
    SELECT_COUNTRY: "Land auswählen",
    TERMS: "Ich stimme den Bedingungen zu",
    SUBMIT: "Senden",
    REQUIRED: "Erforderlich",
    GDPR: "GDPR Schutz personenbezogener Daten",
  },
  stock: {
    STOCK_MODEL: "Lagermodell",
    YOUR_CUSTOM_MODEL: "Ihr maßgeschneidertes Modell",
    PRICE_FROM: "Von",
    STOCK_PROMO:
      "Dieses Bestandsmodell entspricht auch den von Ihnen eingegebenen Parametern. Es ist zu einem reduzierten Preis und die Lieferzeit wird durch den Produktionsprozess verkürzt.",
    STOCK_SHOW: "Anzeige",
    CONFIGURE_MSG:
      "Das Bestandsmodell enthält einige optionale Parameter. Bitte konfigurieren.",
    big_brow: {
      WITHOUT_THRESHOLD: "Ohne Türschwelle",
      DOORS: "Türen",
    },
    side_doors: {
      SLIDING_DOOR: "Schiebetüren (nach oben verschiebbar)",
    },
    FINISH: "Komplett",
    BACK_TO_CUSTOM_MODEL: "Zurück zum maßgeschneidertes Modell",
    ORDER_STOCK_MODEL: "Bestellen Sie ein Lagermodell",
  },
};
