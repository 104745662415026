import {
  TOGGLE_ROOFING_SECTION,
  SELECT_ROOFING_CARD,
  STORE_ROOFINGS,
  SELECT_ROOFING_VARIANT
} from "../actions/types";

const initialState = {
  visibility: false,
  type: "",
  roofings: [],
  variant: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ROOFING_SECTION:
      return {
        ...state,
        visibility: action.visibility
      };

    case STORE_ROOFINGS:
      return {
        ...state,
        roofings: action.roofings
      };

    case SELECT_ROOFING_CARD:
      return {
        ...state,
        type: action.cardName
      };

    case SELECT_ROOFING_VARIANT:
      return {
        ...state,
        variant: action.variant
      };

    default:
      return state;
  }
};
