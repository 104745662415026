// selektory maji k dispozici cely stav, proto state.nazevTohodleReduceru.neco
// mohou tady byt slozitejsi veci, jinak napsat state=>state.dimensions.width by slo i v mapState v Containeru
export const DimentionsSelectors = {
  getWidth: state => state.dimension.width && state.dimension.width / 10,
  getLength: state => state.dimension.length && state.dimension.length / 10,
  getArea: state => state.dimension.width * state.dimension.length,
  getDimensionsValidity: state =>
    state.dimension.width >= 2600 &&
    state.dimension.width <= 8000 &&
    state.dimension.length >= 4280 &&
    state.dimension.length <= 17020
};
