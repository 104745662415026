import {
  SELECT_FILL_CARD,
  SELECT_FILL_DROPDOWN_VALUE,
  STORE_FILL_POSITION_NAME,
} from "../actions/types";

const initialState = {
  type: "",
  position: "",
  positionName: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_FILL_CARD:
      return {
        ...state,
        type: action.selectName,
      };

    case SELECT_FILL_DROPDOWN_VALUE:
      return {
        ...state,
        position: action.selectValue,
      };

    case STORE_FILL_POSITION_NAME:
      return {
        ...state,
        positionName: action.selectValueName,
      };

    default:
      return state;
  }
};
