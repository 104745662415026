import {
  TOGGLE_ROOFING_SECTION,
  SELECT_ROOFING_CARD,
  STORE_ROOFINGS,
  SELECT_ROOFING_VARIANT
} from "./types";

export const RoofingActions = {
  showType: visibility => ({
    type: TOGGLE_ROOFING_SECTION,
    visibility
  }),
  storeRoofings: roofings => ({
    type: STORE_ROOFINGS,
    roofings
  }),
  selectTypeCard: cardName => ({
    type: SELECT_ROOFING_CARD,
    cardName
  }),
  selectRoofingVariant: variant => ({
    type: SELECT_ROOFING_VARIANT,
    variant
  })
};
