import React, { useRef } from "react";
import { useSelector, connect } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";

import { Container } from "../../../components/Container/Container";
import {
  HeadingLgWithSideLines,
  HeadingLg,
} from "../../../components/Heading/Heading";
import DoorsStockModelContainer from "../doors/DoorsStockModelContainer";
import FillStockModelContainer from "../fill/FillStockModelContainer";
import SurfaceStockModelContainer from "../surface/SurfaceStockModelContainer";
import TrackStockModelContainer from "../track/TrackStockModelContainer";
import { Button } from "../../../components/Button/Button";
import Summarization from "../summarization/Summarization";
import { StockModelsActions } from "../../../actions/stockModelsActions";
import Translate from "react-translate-component";
import UserForm from "../Form/Form";

// General scroll to element function
const scrollToRef = (ref) => {
  if (ref) {
    setTimeout(function () {
      window.scrollTo(0, ref.current.offsetTop);
    }, 10);
  }
};

const StockModelsConfig = ({
  stockModels,
  stockModel,
  storeStockModelResults,
  stockModelResults,
  calculationResults,
  setStockModelSummarizationVisible,
  setStockModelFormVisible,
}) => {
  const state = useSelector((state) => state);
  const history = useHistory();

  const fillRef = useRef(null);
  const scrollToFill = () => scrollToRef(fillRef);
  const surfaceRef = useRef(null);
  const scrollToSurface = () => scrollToRef(surfaceRef);
  const trackRef = useRef(null);
  const scrollToTrack = () => scrollToRef(trackRef);
  const summarizationRef = useRef(null);
  const scrollToSummarization = () => scrollToRef(summarizationRef);
  const formRef = useRef(null);
  const scrollToForm = () => scrollToRef(formRef);

  const stockModelFillVisibility = useSelector(
    (state) => state.stockModels.stockModelFillVisibility
  );
  const stockModelSurfaceVisibility = useSelector(
    (state) => state.stockModels.stockModelSurfaceVisibility
  );
  const stockModelTrackVisibility = useSelector(
    (state) => state.stockModels.stockModelTrackVisibility
  );
  const stockModelSummarizationVisibility = useSelector(
    (state) => state.stockModels.stockModelSummarizationVisibility
  );
  const stockModelFormVisibility = useSelector(
    (state) => state.stockModels.stockModelFormVisibility
  );

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");
  const locale = urlParams.get("locale");

  const configurationSubmitData = {
    dimension: { ...state.dimension },
    roofing: {
      type:
        state.stockModels.stockModel !== null &&
        state.stockModels.stockModel.id,
    },
    filling: { type: state.stockModels.stockModelFillType },
    surface: {
      type: state.stockModels.stockModelSurfaceType,
    },
    track: { ...state.stockModels.track },
    doors: [
      {
        type:
          state.stockModels.stockModelDoorsType ===
          "doors.variant.without_threshold"
            ? "doors.type.front_big"
            : "doors.type.side",
        variant:
          state.stockModels.stockModelDoorsType ===
          "doors.variant.without_threshold"
            ? "doors.variant.without_threshold"
            : "doors.variant.up_sliding",
        segments: state.stockModels.segments,
      },
    ],
  };

  if (state.stockModels.stockModel !== null) {
    return (
      <>
        <Container lg style={{ paddingTop: "7rem" }}>
          <HeadingLg center>
            {stockModel !== null ? stockModel.name : "Stock Model"}
          </HeadingLg>

          <p
            style={{
              maxWidth: "456px",
              color: "#4e4e4e",
              fontSize: "1.8rem",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Translate content="stock.CONFIGURE_MSG" />
          </p>
        </Container>

        <Container lg style={{ paddingTop: "7rem" }}>
          <HeadingLgWithSideLines>
            <HeadingLg>
              {" "}
              <Translate content="stock.big_brow.DOORS" />
            </HeadingLg>
          </HeadingLgWithSideLines>
          <DoorsStockModelContainer executeScroll={scrollToFill} />
          {stockModelFillVisibility && (
            <FillStockModelContainer
              myRef={fillRef}
              executeScroll={scrollToSurface}
            />
          )}
          {stockModelSurfaceVisibility && (
            <SurfaceStockModelContainer
              myRef={surfaceRef}
              executeScroll={scrollToTrack}
            />
          )}
          {stockModelTrackVisibility && (
            <>
              <TrackStockModelContainer myRef={trackRef} />
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={async () => {
                    const res = await fetch(
                      `${
                        window.location.hostname === "localhost"
                          ? "http://mountfield.test.pink-future.cz"
                          : ""
                      }/mc/api/stock-models/${
                        stockModel.id
                      }/configuration-update?locale=${locale}&mid=${mid}`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          dimension: { ...state.dimension },
                          roofing: {
                            type:
                              state.stockModels.stockModel !== null &&
                              state.stockModels.stockModel.id,
                          },
                          filling: {
                            type: state.stockModels.stockModelFillType,
                          },
                          surface: {
                            type: state.stockModels.stockModelSurfaceType,
                          },
                          track: { ...state.stockModels.track },
                          doors: [
                            {
                              type:
                                state.stockModels.stockModelDoorsType ===
                                "doors.variant.without_threshold"
                                  ? "doors.type.front_big"
                                  : "doors.type.side",
                              variant:
                                state.stockModels.stockModelDoorsType ===
                                "doors.variant.without_threshold"
                                  ? "doors.variant.without_threshold"
                                  : "doors.variant.up_sliding",
                              segments: state.stockModels.segments,
                            },
                          ],
                        }),
                      }
                    );
                    const data = await res.json();
                    storeStockModelResults(data);
                    setStockModelSummarizationVisible();
                    scrollToSummarization();
                  }}
                >
                  <Translate content="stock.FINISH" />
                </Button>
              </div>
            </>
          )}
          {stockModelSummarizationVisibility && (
            <div style={{ paddingTop: "10rem" }}>
              <HeadingLgWithSideLines>
                <HeadingLg>
                  <Translate content="summarization.TITLE" />
                </HeadingLg>
              </HeadingLgWithSideLines>
              <div style={{ textAlign: "center" }}>
                <img
                  src={stockModelResults.configuration_image || ""}
                  alt="roofing view"
                />
              </div>
              <div
                style={{ display: "flex", maxWidth: "90rem", margin: "auto" }}
              >
                <div className="disabled" style={{ flex: "1" }}>
                  <HeadingLg>
                    <Translate content="stock.YOUR_CUSTOM_MODEL" />
                  </HeadingLg>
                  <Summarization
                    resultData={calculationResults}
                    fillingPosition={state.filling.positionName}
                  />
                  <Button
                    style={{ marginTop: "8rem" }}
                    onClick={() => history.goBack()}
                  >
                    <Translate content="stock.BACK_TO_CUSTOM_MODEL" />
                  </Button>
                </div>
                <div style={{ flex: "1", marginLeft: "1rem" }}>
                  <HeadingLg>
                    <Translate content="stock.STOCK_MODEL" />
                  </HeadingLg>
                  <Summarization
                    resultData={stockModelResults}
                    myRef={summarizationRef}
                    fillingPosition={stockModels.stockModelDropdownPositionName}
                  />
                  <Button
                    style={{ marginTop: "8rem" }}
                    onClick={() => {
                      setStockModelFormVisible();
                      scrollToForm();
                    }}
                  >
                    <Translate content="stock.ORDER_STOCK_MODEL" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          {stockModelFormVisibility && (
            <UserForm
              myRef={formRef}
              configurationSubmitData={configurationSubmitData}
              isStockModel
            />
          )}
        </Container>
      </>
    );
  } else {
    return <Redirect to={`/?mid=${mid}&locale=${locale}`} />;
  }
};

const mapState = (state) => ({
  stockModels: state.stockModels,
  stockModel: state.stockModels.stockModel,
  stockModelResults: state.stockModels.stockModelResults,
  calculationResults: state.calculationResults.results,
});

const mapDispatch = (dispatch) => ({
  storeStockModelResults: (results) =>
    dispatch(StockModelsActions.storeStockModelResults(results)),
  setStockModelSummarizationVisible: () =>
    dispatch(StockModelsActions.setStockModelSummarizationVisible()),
  setStockModelFormVisible: () =>
    dispatch(StockModelsActions.setStockModelFormVisible()),
});

export default connect(mapState, mapDispatch)(StockModelsConfig);
