import styled, { css } from "styled-components";

export const InputText = styled.input`
  /*   max-width: 24rem; */
  width: 100%;
  height: 4rem;
  padding: 1rem 1.5rem;
  font-size: 14px;
  color: #4e4e4e;
  background-color: #fff;
  border: 1px solid #ababab;
  border-radius: 6px;

  ${props =>
    props.invalid &&
    css`
      color: #d0021b;
      border-color: #d0021b;
    `}

  &:hover {
    border-color: #4a90e2;
  }

  &:focus {
    border: 2px solid #4a90e2;
    outline: none;
  }
`;
