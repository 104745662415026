import React from "react";
import Translate from "react-translate-component";

import { Container } from "../../../components/Container/Container";
import {
  HeadingLg,
  HeadingLgWithSideLines,
} from "../../../components/Heading/Heading";
import { SelectCard } from "../../../components/SelectCard/SelectCard";
import { Grid, GridItem } from "../../../components/Grid/Grid";

const Surface = ({ onSelect, selectedCardName, surfaceAssets, myRef }) => (
  <Container lg style={{ display: "flex" }}>
    <Container md style={{ paddingTop: "10rem" }} ref={myRef}>
      <HeadingLgWithSideLines>
        <HeadingLg>
          <Translate content="surface.TITLE" />
        </HeadingLg>
      </HeadingLgWithSideLines>

      <Grid columns3>
        <GridItem>
          {selectedCardName === "color.type.elox" ? (
            <SelectCard
              lg
              active
              onSelect={onSelect}
              type="color.type.elox"
              image="images/roof-colors/roof-color--elox.jpg"
              lightboxId="color.type.elox"
              lightboxImages={surfaceAssets.elox.galleryImgs}
            >
              <Translate content="surface.ELOX" />
            </SelectCard>
          ) : (
            <SelectCard
              lg
              onSelect={onSelect}
              type="color.type.elox"
              image="images/roof-colors/roof-color--elox.jpg"
              lightboxId="color.type.elox"
              lightboxImages={surfaceAssets.elox.galleryImgs}
            >
              <Translate content="surface.ELOX" />
            </SelectCard>
          )}
        </GridItem>
        <GridItem>
          {selectedCardName === "color.type.anthracite" ? (
            <SelectCard
              lg
              active
              onSelect={onSelect}
              type="color.type.anthracite"
              image="images/roof-colors/roof-color--anthracite.jpg"
              lightboxId="color.type.anthracite"
              lightboxImages={surfaceAssets.anthracite.galleryImgs}
            >
              <Translate content="surface.ANTHRACITE" />
            </SelectCard>
          ) : (
            <SelectCard
              lg
              onSelect={onSelect}
              type="color.type.anthracite"
              image="images/roof-colors/roof-color--anthracite.jpg"
              lightboxId="color.type.anthracite"
              lightboxImages={surfaceAssets.anthracite.galleryImgs}
            >
              <Translate content="surface.ANTHRACITE" />
            </SelectCard>
          )}
        </GridItem>
        {/* <GridItem>
          {selectedCardName === "color.type.bronze" ? (
            <SelectCard
              lg
              active
              onSelect={onSelect}
              type="color.type.bronze"
              image="images/roof-colors/roof-color--bronze.jpg"
              lightboxId="color.type.bronze"
              lightboxImages={surfaceAssets.bronze.galleryImgs}
            >
              <Translate content="surface.BRONZE" />
            </SelectCard>
          ) : (
            <SelectCard
              lg
              onSelect={onSelect}
              type="color.type.bronze"
              image="images/roof-colors/roof-color--bronze.jpg"
              lightboxId="color.type.bronze"
              lightboxImages={surfaceAssets.bronze.galleryImgs}
            >
              <Translate content="surface.BRONZE" />
            </SelectCard>
          )}
        </GridItem> */}
      </Grid>
    </Container>
  </Container>
);

export default Surface;
