import React from "react";
import { connect } from "react-redux";

import { SelectActions } from "../../actions/selectActions";
import { Select } from "./Select";

const SelectContainer = props => {
  const openDropdown = e => {
    props.currentDropdown(e.currentTarget.dataset.id);
  };

  const closeDropdown = e => {
    props.currentDropdown("");
  };

  return (
    <Select
      openDropdown={openDropdown}
      closeDropdown={closeDropdown}
      {...props}
    />
  );
};

const mapState = state => ({
  fill: state.fill
});

const mapDispatch = dispatch => ({
  currentDropdown: dropdownId =>
    dispatch(SelectActions.openDropdown(dropdownId))
});

export default connect(mapState, mapDispatch)(SelectContainer);
