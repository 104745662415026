import styled from "styled-components";

export const IconBtn = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;

  svg {
    width: 1.9rem;
    height: 1.6rem;
    fill: rgba(0, 0, 0, 0.17);

    &:hover {
      fill: #4a90e2;
    }
  }
`;
