import React, { Fragment } from "react";
import { connect, useSelector } from "react-redux";

import { StockModelsActions } from "../../../actions/stockModelsActions";
import { CalculationResultsActions } from "../../../actions/calculationResultsAction";
import DoorsStockModel from "./DoorsStockModel";

const DoorsStockModelContainer = ({
  selectStockDoors,
  stockDoorVariant,
  selectSideDoorCard,
  stockModel,
  stockModelDoorCardName,
  setSegments,
  segments,
  setStockModelFillVisible,
  storeStockModelFills,
  executeScroll,
  state,
  storeResults,
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");

  const avaiableStockModelFills = useSelector(
    (state) => state.stockModels.stockModelFills
  );

  const onStockDoorsSelect = async (e) => {
    let selectedDoors = e.currentTarget.dataset.cardName;
    selectStockDoors(selectedDoors);

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/stock-models/${
        stockModel.id
      }/fillings/?doorVariant=${selectedDoors}&locale=en`
    );
    const data = await res.json();
    storeStockModelFills(data);

    if (selectedDoors !== "doors.variant.up_sliding") {
      setStockModelFillVisible();
      executeScroll();
    }
  };

  const doorsAssets = {
    withoutDoors: {
      galleryImgs: [
        {
          src: "images/gallery/doors/without-doors/without-doors.jpg",
        },
      ],
    },
    tiltingFront: {
      galleryImgs: [
        { src: "images/gallery/doors/doors-tilting-front/tilting.jpg" },
        { src: "images/gallery/doors/doors-tilting-front/tilting-trend.jpg" },
        { src: "images/gallery/doors/doors-tilting-front/tilting-trend2.jpg" },
        { src: "images/gallery/doors/doors-tilting-front/tilting-trend3.jpg" },
        { src: "images/gallery/doors/doors-tilting-front/tilting-trend4.jpg" },
      ],
    },
    withThreshold: {
      galleryImgs: [
        {
          src: "images/gallery/doors/doors-with-threshold/with-threshold1.jpg",
        },
        {
          src: "images/gallery/doors/doors-with-threshold/with-threshold2.jpg",
        },
        {
          src: "images/gallery/doors/doors-with-threshold/with-threshold3.jpg",
        },
      ],
    },
    withoutThreshold: {
      galleryImgs: [
        {
          src:
            "images/gallery/doors/doors-without-threshold/without-threshold1.jpg",
        },
        {
          src:
            "images/gallery/doors/doors-without-threshold/without-threshold2.jpg",
        },
        {
          src:
            "images/gallery/doors/doors-without-threshold/without-threshold3.jpg",
        },
        {
          src:
            "images/gallery/doors/doors-without-threshold/without-threshold4.jpg",
        },
        {
          src:
            "images/gallery/doors/doors-without-threshold/without-threshold5.jpg",
        },
      ],
    },
    sideShunting: {
      galleryImgs: [
        {
          src:
            "images/gallery/doors/doors-side-shunting/doors-side-shunting.jpg",
        },
      ],
    },
    upSliding: {
      galleryImgs: [
        {
          src: "images/gallery/doors/doors-up-sliding/doors-up-sliding.jpg",
        },
        {
          src: "images/gallery/doors/doors-up-sliding/doors-up-sliding2.jpg",
        },
      ],
    },

    smokePolycarbonate: {
      galleryImgs: [{ src: "images/gallery/doors/smoke-polycarbonate.jpg" }],
    },

    compactPolycarbonate: {
      galleryImgs: [{ src: "images/gallery/doors/compact-polycarbonate.jpg" }],
    },
  };

  const segmentRadioChangeHandler = (event) => {
    const key = event.target.closest("div[id]").id;
    const value = event.target.value;

    let obj = segments.find((o, i) => {
      if (o.number === key) {
        segments[i] = { number: key, position: value };
        setSegments([...segments]);
        return true; // stop searching
      }
    });

    if (!obj) {
      setSegments([
        ...segments,
        {
          number: key,
          position: value,
        },
      ]);
    }

    setStockModelFillVisible();
  };

  // reset segment radio (on reset icon click
  // delete value about current segment from array)
  const segmentRadioReset = (event) => {
    const key = event.target.closest("div[id]").id;
    const value = event.target.value;

    let obj = segments.find((o, i) => {
      if (o.number === key) {
        segments.splice(i, 1);
        // segments[i] = { number: key, position: value };
        setSegments([...segments]);
        return true; // stop searching
      }
    });

    if (!obj) {
      setSegments([
        ...segments,
        {
          number: key,
          position: value,
        },
      ]);
    }
  };

  return (
    <Fragment>
      <DoorsStockModel
        onDoorsSelect={onStockDoorsSelect}
        selectedCardStockDoors={stockDoorVariant}
        stockModel={stockModel}
        stockModelDoorCardName={stockModelDoorCardName}
        segmentRadioChangeHandler={segmentRadioChangeHandler}
        segmentRadioValue={segments}
        segmentRadioReset={segmentRadioReset}
        doorsAssets={doorsAssets}
      />
    </Fragment>
  );
};

const mapState = (state) => ({
  stockModel: state.stockModels.stockModel,
  stockDoorVariant: state.stockModels.stockModelDoorsType,
  stockModelDoorCardName: state.stockModels.stockModelDoorsType,
  segments: state.stockModels.segments,
  state: state,
});

const mapDispatch = (dispatch) => ({
  selectStockDoors: (selectCardName) =>
    dispatch(StockModelsActions.selectStockDoors(selectCardName)),
  setSegments: (segments) => dispatch(StockModelsActions.setSegments(segments)),
  setStockModelFillVisible: () =>
    dispatch(StockModelsActions.setStockModelFillVisible()),
  storeStockModelFills: (stockModelFills) =>
    dispatch(StockModelsActions.storeStockModelFills(stockModelFills)),
  storeResults: (results) =>
    dispatch(CalculationResultsActions.storeResults(results)),
});

export default connect(mapState, mapDispatch)(DoorsStockModelContainer);
