import styled from "styled-components";

export const HeadingLg = styled.h1`
  font-size: 2.8rem;
  font-weight: 600;
  text-align: ${props => props.center && "center"};
`;

export const HeadingMd = styled.h2`
  font-size: 1.8rem;
  font-weight: 400;
  color: #4e4e4e;
`;

export const HeadingSm = styled.h2`
  font-size: 1.6rem;
  font-weight: 700;
  color: #4e4e4e;
  text-transform: uppercase;
  letter-spacing: 0.91px;
`;

export const HeadingLgWithSideLines = styled.div`
  position: relative;
  text-align: center;

  &:before {
    position: absolute;
    top: 50%;
    right: 0%;
    left: 0;
    content: "";
    border-top: 1px solid #e3e3e3;
    transform: translateY(-50%);
  }

  ${HeadingLg} {
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0 8rem;
    background-color: #fff;
  }
`;
