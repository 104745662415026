import React, { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import Translate from "react-translate-component";

import DimensionsContainer from "./dimensions/DimensionsContainer";
import FillContainer from "./fill/FillContainer";
import RoofingContainer from "./roofing/RoofingContainer";
import SurfaceContainer from "./surface/SurfaceContainer";
import TrackContainer from "./track/TrackContainer";
// import Parts from "./parts/Parts";
import { Container } from "../../components/Container/Container";
import {
  HeadingLgWithSideLines,
  HeadingLg,
} from "../../components/Heading/Heading";
import { NavigationSidebar } from "../../components/NavigationSidebar/NavigationSidebar";
import Summarization from "./summarization/Summarization";
import UserForm from "./Form/Form";
import { Button } from "../../components/Button/Button";
import DoorsContainer from "./doors/DoorsContainer";
import { CalculationResultsActions } from "../../actions/calculationResultsAction";
import { CalculationActions } from "../../actions/calculationActions";
import StockModel from "../../components/StockModel/StockModel";
import { Link } from "react-router-dom";
import { StockModelsActions } from "../../actions/stockModelsActions";

const scrollToRef = (ref) => {
  if (ref) {
    setTimeout(function () {
      window.scrollTo(0, ref.current.offsetTop);
    }, 10);
  }
};
// General scroll to element function

const Calculator = ({
  storeResults,
  storeStockModel,
  stockModel,
  setCalculationState,
  calculationState,
}) => {
  const [partsVisible, setPartsVisible] = useState(false);

  const roofingRef = useRef(null);
  const scrollToRoofing = () => scrollToRef(roofingRef);
  const fillRef = useRef(null);
  const scrollToFill = () => scrollToRef(fillRef);
  const surfaceRef = useRef(null);
  const scrollToSurface = () => scrollToRef(surfaceRef);
  const trackRef = useRef(null);
  const scrollToTrack = () => scrollToRef(trackRef);
  const doorsRef = useRef(null);
  const scrollToDoors = () => scrollToRef(doorsRef);
  const summarizationRef = useRef(null);
  const scrollToSummarization = () => scrollToRef(summarizationRef);

  const state = useSelector((state) => state);
  const configResults = state.calculationResults.results;
  const typesVisible = useSelector((state) => state.roofing.visibility);
  const roofingType = useSelector((state) => state.roofing.type);
  const fillVisible = roofingType;
  const colorsVisible = useSelector((state) => state.filling.position);
  const trackVisible = useSelector((state) => state.surface.type);
  const highDoorCardName = useSelector(
    (state) => state.doors["doors.type.front_big"].type
  );
  const lowDoorCardName = useSelector(
    (state) => state.doors["doors.type.front_small"].type
  );
  const sideDoorCardName = useSelector(
    (state) => state.doors["doors.type.side"].type
  );
  const sumarization = highDoorCardName && lowDoorCardName && sideDoorCardName;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");
  const locale = urlParams.get("locale");

  const configurationSubmitData = {
    dimension: { ...state.dimension },
    roofing: { ...state.roofing },
    filling: { ...state.filling },
    surface: { ...state.surface },
    track: { ...state.track },
    doors: [
      { ...state.doors["doors.type.front_big"] },
      { ...state.doors["doors.type.front_small"] },
      { ...state.doors["doors.type.side"] },
    ],
  };

  return (
    <Container lg style={{ display: "flex" }}>
      <div style={{ width: "100%" }}>
        {urlParams.has("mid") ? (
          <DimensionsContainer executeScroll={scrollToRoofing} />
        ) : (
          <div style={{ margin: "10rem", textAlign: "center" }}>
            <strong style={{ fontSize: "4rem" }}>
              <Translate content="mid_alert" />
            </strong>
          </div>
        )}

        {typesVisible && (
          <RoofingContainer executeScroll={scrollToFill} myRef={roofingRef} />
        )}
        {fillVisible && (
          <FillContainer executeScroll={scrollToSurface} myRef={fillRef} />
        )}
        {colorsVisible && (
          <SurfaceContainer executeScroll={scrollToTrack} myRef={surfaceRef} />
        )}
        {trackVisible && (
          <Fragment>
            <TrackContainer myRef={trackRef} />
            {!partsVisible && (
              <div style={{ textAlign: "center", marginTop: "6rem" }}>
                <Button
                  onClick={async () => {
                    setPartsVisible(true);
                    scrollToDoors();
                  }}
                >
                  <Translate content="dimensions.SUBMIT" />
                </Button>
              </div>
            )}
          </Fragment>
        )}
        {trackVisible && partsVisible && (
          <Container md style={{ paddingTop: "10rem" }} ref={doorsRef}>
            <HeadingLgWithSideLines>
              <HeadingLg>
                <Translate content="doors.TITLE" />
              </HeadingLg>
            </HeadingLgWithSideLines>
          </Container>
        )}
        {trackVisible && partsVisible && (
          <Container md>
            <DoorsContainer />
            <div style={{ textAlign: "center", marginTop: "6rem" }}>
              <Button
                onClick={async () => {
                  const res = await fetch(
                    `${
                      window.location.hostname === "localhost"
                        ? "http://mountfield.test.pink-future.cz"
                        : ""
                    }/mc/api/configuration/update?mid=${mid}&locale=${locale}`,
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        dimension: { ...state.dimension },
                        roofing: { ...state.roofing },
                        filling: { ...state.filling },
                        surface: { ...state.surface },
                        track: { ...state.track },
                        doors: [
                          { ...state.doors["doors.type.front_big"] },
                          { ...state.doors["doors.type.front_small"] },
                          { ...state.doors["doors.type.side"] },
                        ],
                      }),
                    }
                  );
                  const data = await res.json();
                  storeResults(data);

                  const StockModelsRes = await fetch(
                    `${
                      window.location.hostname === "localhost"
                        ? "http://mountfield.test.pink-future.cz"
                        : ""
                    }/mc/api/stock-models/best-fit?mid=${mid}&width=${
                      state.dimension.width
                    }&length=${state.dimension.length}&roofingType=${
                      state.roofing.type
                    }&locale=en`
                  );
                  if (StockModelsRes.status !== 404) {
                    const StockModelsData = await StockModelsRes.json();
                    storeStockModel(StockModelsData);
                  }

                  setCalculationState(true);
                  scrollToSummarization();
                }}
              >
                <Translate content="CALCULATE" />
              </Button>
            </div>
          </Container>
        )}
        {calculationState && (
          <div style={{ paddingTop: "10rem" }}>
            <HeadingLgWithSideLines>
              <HeadingLg>
                <Translate content="summarization.TITLE" />
              </HeadingLg>
            </HeadingLgWithSideLines>
            <div style={{ textAlign: "center" }}>
              <img
                src={configResults.configuration_image || ""}
                alt="roofing view"
              />
            </div>
            <div className="col-2">
              <Summarization
                myRef={summarizationRef}
                resultData={configResults}
                fillingPosition={state.filling.positionName}
              />
            </div>
            {stockModel !== null && configResults?.show_stock_models && (
              <StockModel stockModel={stockModel} />
            )}
            <UserForm configurationSubmitData={configurationSubmitData} />
          </div>
        )}
      </div>
      {urlParams.has("mid") && (
        <NavigationSidebar
          dimensions={typesVisible && true}
          roofing={fillVisible && true}
          fill={colorsVisible && true}
          surface={trackVisible && true}
          track={trackVisible && partsVisible && colorsVisible && true}
          doors={
            trackVisible &&
            partsVisible &&
            colorsVisible &&
            calculationState &&
            true
          }
        />
      )}
    </Container>
  );
};

const mapState = (state) => ({
  calculationState: state.calculation.calculation_state,
  stockModel: state.stockModels.stockModel,
});

const mapDispatch = (dispatch) => ({
  storeStockModel: (stockModels) =>
    dispatch(StockModelsActions.storeStockModel(stockModels)),
  storeResults: (results) =>
    dispatch(CalculationResultsActions.storeResults(results)),
  setCalculationState: (state) =>
    dispatch(CalculationActions.calculationState(state)),
});

export default connect(mapState, mapDispatch)(Calculator);
