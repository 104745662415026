import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Radio } from "../Form/Radio";

const SelectSegmentsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 63rem;
  height: 27rem;
  padding-left: ${(props) => props.type2 && "3rem"};
  margin: 2rem auto;
  background: linear-gradient(#e1f5ff, #e1f5ff);
  background-repeat: no-repeat;
  background-position: 10px 30px;
  background-size: calc(100% - 20px) calc(100% - 60px);
`;

const SelectSegment = styled.div`
  flex: 1;
  border: 2px solid #979797;
  ${(props) => props.height === "height1" && "height: 228px"}
  ${(props) => props.height === "height2" && "height: 234px"}
  ${(props) => props.height === "height3" && "height: 240px"}
  ${(props) => props.height === "height4" && "height: 246px"}
  ${(props) => props.height === "height5" && "height: 252px"}
  ${(props) => props.height === "height6" && "height: 258px"}
  ${(props) => props.height === "height7" && "height: 264px"}
  ${(props) => props.height === "height8" && "height: 270px"}
`;

const SelectSegmentBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

const ResetBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.8rem;
  color: rgb(255, 0, 0);
  cursor: pointer;
  background: transparent;
  border: none;
`;

const SelectSegmentTop = styled.div`
  display: flex;
  height: 50%;
  border-bottom: dashed 1px #979797;
`;

const SelectSegmentTopLeft = styled(SelectSegmentBox)`
  border-right: dashed 1px #979797;
`;

const SelectSegmentTopRight = styled(SelectSegmentBox)``;

const SelectSegmentBottom = styled.div`
  display: flex;
  height: 50%;
`;

const SelectSegmentBottomLeft = styled(SelectSegmentBox)`
  border-right: dashed 1px #979797;
`;

const SelectSegmentBottomRight = styled(SelectSegmentBox)``;

export function SelectSegments({
  sideShunting,
  stockModel,
  radioChangeHandler,
  radioValue,
  segmentRadioReset,
  segmentsNumber,
}) {
  return (
    <SelectSegmentsWrapper>
      {[...Array(segmentsNumber)].map((val, index) => {
        let currentRadio = radioValue.find((o) => {
          if (+o.number === index) {
            return o; // stop searching
          }
        });

        let currentPosition = currentRadio ? currentRadio.position : "";

        {
          /* if shunting doors -> return radio btn only for last index else render empty segment (without radio btn) */
        }

        if (stockModel) {
          if (stockModel.id === "roofing.type.trend_olymp") {
            if (index + 1 === segmentsNumber) {
              return (
                <SelectSegment
                  key={index}
                  id={index}
                  height={"height" + (index + 1)}
                >
                  <SelectSegmentTop>
                    <SelectSegmentTopLeft>
                      <Radio
                        changed={radioChangeHandler}
                        id={`topLeft${index}`}
                        isSelected={
                          currentPosition === "doors.position.top_left"
                        }
                        value="doors.position.top_left"
                      />
                      {currentPosition === "doors.position.top_left" && (
                        <ResetBtn onClick={segmentRadioReset} title="unselect">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: "1.5rem" }}
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                          >
                            <title>unselect</title>
                            <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                          </svg>
                        </ResetBtn>
                      )}
                    </SelectSegmentTopLeft>

                    <SelectSegmentTopRight></SelectSegmentTopRight>
                  </SelectSegmentTop>

                  <SelectSegmentBottom>
                    <SelectSegmentBottomLeft></SelectSegmentBottomLeft>

                    <SelectSegmentBottomRight>
                      <Radio
                        changed={radioChangeHandler}
                        id={`bottomRight${index}`}
                        isSelected={
                          currentPosition === "doors.position.bottom_right"
                        }
                        value="doors.position.bottom_right"
                      />
                      {currentPosition === "doors.position.bottom_right" && (
                        <ResetBtn onClick={segmentRadioReset} title="unselect">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: "1.5rem" }}
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                          >
                            <title>unselect</title>
                            <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                          </svg>
                        </ResetBtn>
                      )}
                    </SelectSegmentBottomRight>
                  </SelectSegmentBottom>
                </SelectSegment>
              );
            } else {
              return (
                <SelectSegment
                  key={index}
                  id={index}
                  height={"height" + (index + 1)}
                >
                  <SelectSegmentTop>
                    <SelectSegmentTopLeft></SelectSegmentTopLeft>

                    <SelectSegmentTopRight></SelectSegmentTopRight>
                  </SelectSegmentTop>

                  <SelectSegmentBottom>
                    <SelectSegmentBottomLeft></SelectSegmentBottomLeft>

                    <SelectSegmentBottomRight></SelectSegmentBottomRight>
                  </SelectSegmentBottom>
                </SelectSegment>
              );
            }
          } else {
            if (index + 1 === segmentsNumber) {
              return (
                <SelectSegment
                  key={index}
                  id={index}
                  height={"height" + (index + 1)}
                >
                  <SelectSegmentTop>
                    <SelectSegmentTopLeft></SelectSegmentTopLeft>

                    <SelectSegmentTopRight>
                      <Radio
                        changed={radioChangeHandler}
                        id={`topRight${index}`}
                        isSelected={
                          currentPosition === "doors.position.top_right"
                        }
                        value="doors.position.top_right"
                      />
                      {currentPosition === "doors.position.top_right" && (
                        <ResetBtn onClick={segmentRadioReset} title="unselect">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: "1.5rem" }}
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                          >
                            <title>unselect</title>
                            <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                          </svg>
                        </ResetBtn>
                      )}
                    </SelectSegmentTopRight>
                  </SelectSegmentTop>

                  <SelectSegmentBottom>
                    <SelectSegmentBottomLeft></SelectSegmentBottomLeft>

                    <SelectSegmentBottomRight>
                      <Radio
                        changed={radioChangeHandler}
                        id={`bottomRight${index}`}
                        isSelected={
                          currentPosition === "doors.position.bottom_right"
                        }
                        value="doors.position.bottom_right"
                      />
                      {currentPosition === "doors.position.bottom_right" && (
                        <ResetBtn onClick={segmentRadioReset} title="unselect">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: "1.5rem" }}
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                          >
                            <title>unselect</title>
                            <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                          </svg>
                        </ResetBtn>
                      )}
                    </SelectSegmentBottomRight>
                  </SelectSegmentBottom>
                </SelectSegment>
              );
            } else {
              return (
                <SelectSegment
                  key={index}
                  id={index}
                  height={"height" + (index + 1)}
                >
                  <SelectSegmentTop>
                    <SelectSegmentTopLeft></SelectSegmentTopLeft>

                    <SelectSegmentTopRight></SelectSegmentTopRight>
                  </SelectSegmentTop>

                  <SelectSegmentBottom>
                    <SelectSegmentBottomLeft></SelectSegmentBottomLeft>

                    <SelectSegmentBottomRight></SelectSegmentBottomRight>
                  </SelectSegmentBottom>
                </SelectSegment>
              );
            }
          }
        } else if (sideShunting) {
          if (index + 1 === segmentsNumber) {
            return (
              <SelectSegment
                key={index}
                id={index}
                height={"height" + (index + 1)}
              >
                <SelectSegmentTop>
                  <SelectSegmentTopLeft>
                    <Radio
                      changed={radioChangeHandler}
                      id={`topLeft${index}`}
                      isSelected={currentPosition === "doors.position.top_left"}
                      value="doors.position.top_left"
                    />
                    {currentPosition === "doors.position.top_left" && (
                      <ResetBtn onClick={segmentRadioReset} title="unselect">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ width: "1.5rem" }}
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <title>unselect</title>
                          <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                        </svg>
                      </ResetBtn>
                    )}
                  </SelectSegmentTopLeft>

                  <SelectSegmentTopRight>
                    <Radio
                      changed={radioChangeHandler}
                      id={`topRight${index}`}
                      isSelected={
                        currentPosition === "doors.position.top_right"
                      }
                      value="doors.position.top_right"
                    />
                    {currentPosition === "doors.position.top_right" && (
                      <ResetBtn onClick={segmentRadioReset} title="unselect">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ width: "1.5rem" }}
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <title>unselect</title>
                          <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                        </svg>
                      </ResetBtn>
                    )}
                  </SelectSegmentTopRight>
                </SelectSegmentTop>

                <SelectSegmentBottom>
                  <SelectSegmentBottomLeft>
                    <Radio
                      changed={radioChangeHandler}
                      id={`bottomLeft${index}`}
                      isSelected={
                        currentPosition === "doors.position.bottom_left"
                      }
                      value="doors.position.bottom_left"
                    />
                    {currentPosition === "doors.position.bottom_left" && (
                      <ResetBtn onClick={segmentRadioReset} title="unselect">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ width: "1.5rem" }}
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <title>unselect</title>
                          <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                        </svg>
                      </ResetBtn>
                    )}
                  </SelectSegmentBottomLeft>

                  <SelectSegmentBottomRight>
                    <Radio
                      changed={radioChangeHandler}
                      id={`bottomRight${index}`}
                      isSelected={
                        currentPosition === "doors.position.bottom_right"
                      }
                      value="doors.position.bottom_right"
                    />
                    {currentPosition === "doors.position.bottom_right" && (
                      <ResetBtn onClick={segmentRadioReset} title="unselect">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ width: "1.5rem" }}
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                        >
                          <title>unselect</title>
                          <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                        </svg>
                      </ResetBtn>
                    )}
                  </SelectSegmentBottomRight>
                </SelectSegmentBottom>
              </SelectSegment>
            );
          } else {
            return (
              <SelectSegment
                key={index}
                id={index}
                height={"height" + (index + 1)}
              >
                <SelectSegmentTop>
                  <SelectSegmentTopLeft></SelectSegmentTopLeft>

                  <SelectSegmentTopRight></SelectSegmentTopRight>
                </SelectSegmentTop>

                <SelectSegmentBottom>
                  <SelectSegmentBottomLeft></SelectSegmentBottomLeft>

                  <SelectSegmentBottomRight></SelectSegmentBottomRight>
                </SelectSegmentBottom>
              </SelectSegment>
            );
          }
        }

        return (
          <SelectSegment key={index} id={index} height={"height" + (index + 1)}>
            <SelectSegmentTop>
              <SelectSegmentTopLeft>
                <Radio
                  changed={radioChangeHandler}
                  id={`topLeft${index}`}
                  isSelected={currentPosition === "doors.position.top_left"}
                  value="doors.position.top_left"
                />
                {currentPosition === "doors.position.top_left" && (
                  <ResetBtn onClick={segmentRadioReset} title="unselect">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "1.5rem" }}
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <title>unselect</title>
                      <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                    </svg>
                  </ResetBtn>
                )}
              </SelectSegmentTopLeft>

              <SelectSegmentTopRight>
                <Radio
                  changed={radioChangeHandler}
                  id={`topRight${index}`}
                  isSelected={currentPosition === "doors.position.top_right"}
                  value="doors.position.top_right"
                />
                {currentPosition === "doors.position.top_right" && (
                  <ResetBtn onClick={segmentRadioReset} title="unselect">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "1.5rem" }}
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <title>unselect</title>
                      <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                    </svg>
                  </ResetBtn>
                )}
              </SelectSegmentTopRight>
            </SelectSegmentTop>

            <SelectSegmentBottom>
              <SelectSegmentBottomLeft>
                <Radio
                  changed={radioChangeHandler}
                  id={`bottomLeft${index}`}
                  isSelected={currentPosition === "doors.position.bottom_left"}
                  value="doors.position.bottom_left"
                />
                {currentPosition === "doors.position.bottom_left" && (
                  <ResetBtn onClick={segmentRadioReset} title="unselect">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "1.5rem" }}
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <title>unselect</title>
                      <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                    </svg>
                  </ResetBtn>
                )}
              </SelectSegmentBottomLeft>

              <SelectSegmentBottomRight>
                <Radio
                  changed={radioChangeHandler}
                  id={`bottomRight${index}`}
                  isSelected={currentPosition === "doors.position.bottom_right"}
                  value="doors.position.bottom_right"
                />
                {currentPosition === "doors.position.bottom_right" && (
                  <ResetBtn onClick={segmentRadioReset} title="unselect">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "1.5rem" }}
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <title>unselect</title>
                      <path d="M32 3.52l-2.038-2.038-13.272 13.272-13.272-13.272-2.038 2.038 13.272 13.272-13.272 13.272 2.038 2.038 13.272-13.272 13.272 13.272 2.038-2.038-13.272-13.272 13.272-13.272z"></path>
                    </svg>
                  </ResetBtn>
                )}
              </SelectSegmentBottomRight>
            </SelectSegmentBottom>
          </SelectSegment>
        );
      })}
    </SelectSegmentsWrapper>
  );
}
