import React from "react";
import { Switch, Route } from "react-router-dom";

import NotFound from "./routes/NotFound";
import Calculator from "./routes/calculator/Calculator";
import SubmitSucces from "./routes/submitSucces/SubmitSucces";
import Terms from "./routes/terms/Terms";
import StockModelsConfig from "./routes/calculator/stockModelsConfig/StockModelsConfig";

export default () => (
  <Switch>
    <Route exact path="/" component={Calculator} />
    <Route exact path="/index.html" component={Calculator} />
    <Route exact path="/calculator" component={Calculator} />
    <Route exact path="/submitsucces" component={SubmitSucces} />
    <Route exact path="/stockModelsConfig" component={StockModelsConfig} />
    <Route exact path="/terms" component={Terms} />
    <Route component={NotFound} />
  </Switch>
);
