import React, { Fragment } from "react";
import { connect } from "react-redux";

import { DoorsActions } from "../../../actions/doorsActions";
import { CalculationResultsActions } from "../../../actions/calculationResultsAction";
import Doors from "./Doors";

const DoorsContainer = ({
  selectLowDoorCard,
  selectHighDoorCard,
  lowDoorVariant,
  highDoorVariant,
  setFrontBigTiltSize,
  setFrontSmallTiltSize,
  frontBigTiltSize,
  frontSmallTiltSize,
  selectSideDoorCard,
  sideDoorCardName,
  setSegments,
  segments,
  state,
  storeResults,
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mid = urlParams.get("mid");

  const isVega = state?.roofing?.type?.includes("vega");

  const onLowDoorCardSelect = async (e) => {
    selectLowDoorCard(e.currentTarget.dataset.cardName);

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/configuration/update?mid=${mid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dimension: { ...state.dimension },
          roofing: { ...state.roofing },
          filling: { ...state.filling },
          surface: { ...state.surface },
          track: { ...state.track },
          doors: [
            { ...state.doors["doors.type.front_big"] },
            { ...state.doors["doors.type.front_small"] },
            { ...state.doors["doors.type.side"] },
          ],
        }),
      }
    );
    const data = await res.json();
    storeResults(data);
  };

  const onHighDoorCardSelect = async (e) => {
    selectHighDoorCard(e.currentTarget.dataset.cardName);

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/configuration/update?mid=${mid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dimension: { ...state.dimension },
          roofing: { ...state.roofing },
          filling: { ...state.filling },
          surface: { ...state.surface },
          track: { ...state.track },
          doors: [
            { ...state.doors["doors.type.side"], type: "doors.type.side" },
            {
              ...state.doors["doors.type.front_small"],
              type: "doors.type.front_small",
            },
            {
              type: "doors.type.front_big",
              variant: e.currentTarget.dataset.cardName,
            },
          ],
        }),
      }
    );
    const data = await res.json();
    storeResults(data);

    if (isVega) {
      setFrontBigTiltSize(14);
    }
  };

  const highDoorThresholdChangeHandler = (event) => {
    selectHighDoorCard(event.target.value);
  };

  const lowDoorThresholdChangeHandler = (event) => {
    selectLowDoorCard(event.target.value);
  };

  const doorsAssets = {
    withoutDoors: {
      galleryImgs: [
        {
          src: "images/gallery/doors/without-doors/without-doors.jpg",
        },
      ],
    },
    tiltingFront: {
      galleryImgs: [
        { src: "images/gallery/doors/doors-tilting-front/tilting.jpg" },
        { src: "images/gallery/doors/doors-tilting-front/tilting-trend.jpg" },
        { src: "images/gallery/doors/doors-tilting-front/tilting-trend2.jpg" },
        { src: "images/gallery/doors/doors-tilting-front/tilting-trend3.jpg" },
        { src: "images/gallery/doors/doors-tilting-front/tilting-trend4.jpg" },
      ],
    },
    withThreshold: {
      galleryImgs: [
        {
          src: "images/gallery/doors/doors-with-threshold/with-threshold1.jpg",
        },
        {
          src: "images/gallery/doors/doors-with-threshold/with-threshold2.jpg",
        },
        {
          src: "images/gallery/doors/doors-with-threshold/with-threshold3.jpg",
        },
      ],
    },
    withoutThreshold: {
      galleryImgs: [
        {
          src: "images/gallery/doors/doors-without-threshold/without-threshold1.jpg",
        },
        {
          src: "images/gallery/doors/doors-without-threshold/without-threshold2.jpg",
        },
        {
          src: "images/gallery/doors/doors-without-threshold/without-threshold3.jpg",
        },
        {
          src: "images/gallery/doors/doors-without-threshold/without-threshold4.jpg",
        },
        {
          src: "images/gallery/doors/doors-without-threshold/without-threshold5.jpg",
        },
      ],
    },
    sideShunting: {
      galleryImgs: [
        {
          src: "images/gallery/doors/doors-side-shunting/doors-side-shunting.jpg",
        },
      ],
    },
    upSliding: {
      galleryImgs: [
        {
          src: "images/gallery/doors/doors-up-sliding/doors-up-sliding.jpg",
        },
        {
          src: "images/gallery/doors/doors-up-sliding/doors-up-sliding2.jpg",
        },
      ],
    },

    smokePolycarbonate: {
      galleryImgs: [{ src: "images/gallery/doors/smoke-polycarbonate.jpg" }],
    },

    compactPolycarbonate: {
      galleryImgs: [{ src: "images/gallery/doors/compact-polycarbonate.jpg" }],
    },
  };

  const onSideDoorCardSelect = async (e) => {
    selectSideDoorCard(e.currentTarget.dataset.cardName);

    const res = await fetch(
      `${
        window.location.hostname === "localhost"
          ? "http://mountfield.test.pink-future.cz"
          : ""
      }/mc/api/configuration/update?mid=${mid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dimension: { ...state.dimension },
          roofing: { ...state.roofing },
          filling: { ...state.filling },
          surface: { ...state.surface },
          track: { ...state.track },
          doors: [
            { ...state.doors["doors.type.front_big"] },
            { ...state.doors["doors.type.front_small"] },
            {
              type: "doors.type.side",
              variant: e.currentTarget.dataset.cardName,
            },
          ],
        }),
      }
    );
    const data = await res.json();
    storeResults(data);
  };

  const segmentRadioChangeHandler = (event) => {
    const key = event.target.closest("div[id]").id;
    const value = event.target.value;

    let obj = segments.find((o, i) => {
      if (o.number === key) {
        segments[i] = { number: key, position: value };
        setSegments([...segments]);
        return true; // stop searching
      }
    });

    if (!obj) {
      setSegments([
        ...segments,
        {
          number: key,
          position: value,
        },
      ]);
    }
  };

  // reset segment radio (on reset icon click
  // delete value about current segment from array)
  const segmentRadioReset = (event) => {
    const key = event.target.closest("div[id]").id;
    const value = event.target.value;

    let obj = segments.find((o, i) => {
      if (o.number === key) {
        segments.splice(i, 1);
        // segments[i] = { number: key, position: value };
        setSegments([...segments]);
        return true; // stop searching
      }
    });

    if (!obj) {
      setSegments([
        ...segments,
        {
          number: key,
          position: value,
        },
      ]);
    }
  };

  return (
    <Fragment>
      <Doors
        onSelectBigDoors={onHighDoorCardSelect}
        selectedCardBigDoors={highDoorVariant}
        setHeightBigDoors={setFrontBigTiltSize}
        frontBigTiltSize={frontBigTiltSize}
        radioChangeHandlerBigDoors={highDoorThresholdChangeHandler}
        radioValueBigDoors={highDoorVariant}
        onSelectLowDoors={onLowDoorCardSelect}
        setHeightLowDoors={setFrontSmallTiltSize}
        frontSmallTiltSize={frontSmallTiltSize}
        selectedCardLowDoors={lowDoorVariant}
        radioChangeHandlerLowDoors={lowDoorThresholdChangeHandler}
        radioValueLowDoors={lowDoorVariant}
        onSelectSideDoors={onSideDoorCardSelect}
        selectedCardSideDoors={sideDoorCardName}
        segmentRadioChangeHandler={segmentRadioChangeHandler}
        segmentRadioValue={segments}
        segmentRadioReset={segmentRadioReset}
        doorsAssets={doorsAssets}
        isVega={isVega}
        state={state}
      />
    </Fragment>
  );
};

const mapState = (state) => ({
  highDoorVariant: state.doors["doors.type.front_big"].variant,
  lowDoorVariant: state.doors["doors.type.front_small"].variant,
  frontBigTiltSize: state.doors["doors.type.front_big"].tilt_size,
  frontSmallTiltSize: state.doors["doors.type.front_small"].tilt_size,
  sideDoorCardName: state.doors["doors.type.side"].variant,
  segments: state.doors["doors.type.side"].segments,
  state: state,
});

const mapDispatch = (dispatch) => ({
  selectLowDoorCard: (selectCardName) =>
    dispatch(DoorsActions.selectLowDoorCard(selectCardName)),
  selectHighDoorCard: (selectCardName) =>
    dispatch(DoorsActions.selectHighDoorCard(selectCardName)),
  setFrontBigTiltSize: (height) =>
    dispatch(DoorsActions.setFrontBigTiltSize(height)),
  setFrontSmallTiltSize: (height) =>
    dispatch(DoorsActions.setFrontSmallTiltSize(height)),
  setthreshold: (threshold) => dispatch(DoorsActions.setthreshold(threshold)),
  selectSideDoorCard: (selectCardName) =>
    dispatch(DoorsActions.selectSideDoorCard(selectCardName)),
  setSegments: (segments) => dispatch(DoorsActions.setSegments(segments)),
  storeResults: (results) =>
    dispatch(CalculationResultsActions.storeResults(results)),
});

export default connect(mapState, mapDispatch)(DoorsContainer);
